import React from "react";
import { withRouter, useLocation } from "react-router-dom";
import { Col, Container, Row, Modal } from "qdm-component-library";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { getLayout } from "./func";
import { TopHeader, Queue, LoadingComp } from "../../components";
import { checkWithCasbin } from "../../utils/permissonHandling";
import Layout from "./layout";
import { withQueue, withAllContexts } from "../../HOCs";
import { Routes } from "../../router/routes";
import "./styles.css";
import { fetchQueueData, fetchUserInfo, putOnHold, UIColor } from "../../utils";
import WithSearch from "./search";
import AssignReassign from "./assignReassign";
export * from "./screens";
const widgets = [
  {
    width: 20,
    name: "widget 1",
    color: "red",
  },
  {
    width: 20,
    name: "widget 2",
    color: "Yellow",
  },
  {
    width: 20,
    name: "widget 3",
    color: "blue",
  },
  {
    width: 20,
    name: "widget 4",
    color: "green",
  },
  {
    width: 20,
    name: "widget 5",
    color: "lightblue",
  },
  {
    width: 100,
    name: "widget 6",
    color: "gray",
  },
  {
    width: 100,
    name: "widget 7",
    color: "orange",
  },
  {
    width: 50,
    name: "widget 8",
    color: "burlywood",
  },
  {
    width: 50,
    name: "widget 9",
    color: "aquamarine",
  },
];

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      layout: {
        right: widgets,
        left: [],
      },
      roleName: "",
      drawer: false,
      initialLayout: null,
      newCounter: 0,
      cols: { lg: 8, md: 10, sm: 6, xs: 4, xxs: 2 },
      componetsIndexList: {
        0: {
          widgetName: "stats",
        },
        // 1: {
        //   widgetName: "statsCard",
        // },
        2: {
          widgetName: "profileList",
        },
        3: {
          widgetName: "patientStatus",
        },
        // "3": {
        //   widgetName: "queue"
        // },
        // "4": {
        //   widgetName: "appoinmentList",
        // },
        // 5: {
        //   widgetName: "statsCard",
        // },
        // 6: {
        //   widgetName: "statsCard",
        // },
        7: {
          widgetName: "pendingTask",
        },

        8: {
          widgetName: "currentVsitStats",
        },
        9: {
          widgetName: "billingStatus",
        },
        10: {
          widgetName: "financialClass",
        },
        11: {
          widgetName: "averageWaitingTime",
        },
        12: {
          widgetName: "schedule",
        },
        13: {
          widgetName: "patientwaitingtime",
        },
      },
      permission: [],
      appointmentStart: null,
      appointmentEnd: null,
      appID: null,
      loader: true,
    };
    this.fetchQueueData = fetchQueueData.bind(this);
    this.putOnHold = putOnHold.bind(this);
    this.fetchUserInfo = fetchUserInfo.bind(this);
    // end
  }

  getRoleName() {
    let role_name = localStorage.getItem("role_name");
    this.setState({ ...this.state, roleName: role_name });
  }
  componentWillReceiveProps() {
    // if(this.props?.location?.state?.reload){
    // 	window.location.reload();
    // }
  }
  async componentDidMount() {
    // debugger;
    // if(this.props?.location?.state?.reload){
    // 	window.location.reload();
    // }else{
    const ASSIGN_PRACTITIONER_TYPE =
      await this.props.ASSIGN_PRACTITIONER_TYPE();
    const ASSIGN_PRACTITIONER_ROLE =
      await this.props.ASSIGN_PRACTITIONER_ROLE();

    // await this.getRoleName();
    // console.log("role_name -------------- : ", this.state.roleName);
    // if (this.state.roleName === "nurse") {
    //   this.setState({ ...this.state, initialLayout: nurse_lay });
    // } else if (this.state.roleName === "frontdesk") {
    //   this.setState({ ...this.state, initialLayout: frontdesk_lay });
    // } else if (this.state.roleName === "doctor") {
    //   this.setState({ ...this.state, initialLayout: doctor_lay });
    // }

    let dataList = await checkWithCasbin(["dashboard"]);
    let layouts = await getLayout(dataList);

    this.setState({
      permission: dataList,
      initialLayout: layouts,
    });
    await this.fetchQueueData();
    this.setState({
      ...this.state,
      // loader: false
    });
    // }
  }

  attendPatient = async (
    personId,
    patientId,
    appointmentId,
    encounterId,
    type,
    pId,
    encounter_id,
    app
  ) => {
    let status;
    let isCompleted;

    if (app[0]?.type === "nurse") {
      status = "inprogress_nurse";
      isCompleted = false;
    } else if (app[0]?.type === "doctor") {
      status = "doctor_inprogress";
      isCompleted = false;
    }
    await this.props.UPDATE_APPOINTMENT_STATUS({
      appointmentId,
      status,
      isCompleted,
    });

    this.props.history.push({
      pathname: Routes.patientDetail,
      state: {
        encounter_id,
        personId,
        patientId,
        appointmentId,
        encounterId,
        type,
        pId,
        app_id: app?.[0]?.info?._id,
        isProfileView: false,
      },
    });
  };

  registerPatient = (
    personId,
    patientId,
    appointmentId,
    isPatient,
    displayData
  ) => {
    this.props.visitData.setVisitDetails({
      encounter: {
        mode: "direct",
      },
      practitioner: {},
      device: {},
      helthcare: {},
      accompained: {},
    });
    if (isPatient) {
      this.props.history.push({
        pathname: Routes.patientVisitDetails,
        state: {
          from: 1,
          personId,
          patientId,
          appointmentId,
          Ispatient: true,
          patientData: displayData?.[0]?.mobileNo,
          idedit: true,
        },
      });
    } else {
      this.props.history.push({
        pathname: Routes.assemblePatioentRegister,
        state: {
          nextRoute: Routes.patientVisitDetails,
          from: 1,
          personId,
          patientId,
          appointmentId,
          patientData: displayData?.[0]?.mobileNo,
          Ispatient: false,
          idedit: false,
          fromQueue: true,
        },
      });
    }
  };

  onLayoutChange = (layout) => {
    console.log(layout);
    this.setState({ ...this.state, initialLayout: layout });
  };

  widgetsWidth = (e, data) => {
    this.state.layout.right.map((val) => {
      if (val.name === data.name) {
        val.width = e.target.value;
      }
      return true;
    });
    this.setState({
      ...this.state,
      layout: {
        right: this.state.layout.right,
      },
    });
  };
  assignPractitioner = async (type, data_) => {
    let d = await this.props.ASSIGN_LIST({
      start: data_?.info?.start ?? "",
      end: data_?.info?.end,
      encounter_key: data_?.info?.encounter?.[0]?._key ?? "",
      type: type,
    });
    debugger;
    this.setState({
      ...this.state,
      assignModal: true,
      assignModalType: type,
      encounter_id: data_?.info?.encounter?.[0]?._key,
      appointmentStart: data_?.info?.start,
      appointmentEnd: data_?.info?.end,
      appID: data_?.info?._key ?? "",
    });
  };
  onModalClose = async (value) => {
    this.setState({
      assignModal: value,
      assignModalType: null,
      encounter_id: null,
    });
  };
  render() {
    const { data, previousData, displayData, allData } =
      this.props.queueManagement;
    const { permission } = this.state;
    let queueRead = permission?.read?.indexOf("queue") > -1;
    let queueWrite = permission?.write?.indexOf("queue") > -1;
    let markHighPriority = permission?.write?.indexOf("markHighPriority") > -1;

    let assignReassignPractitioner =
      permission?.write?.indexOf("assignReassignPractitionerD") > -1;
    // alert(JSON.stringify(this.props?.loggedUserInfo?.role))
    return (
      <div
        id="dashboard_parent_div"
        style={{
          overflow: "hidden",
          height: "100vh",
        }}
      >
        <Container
          id="dashboard_parent_container"
          key={"0"}
          name="container"
          fluid={true}
          style={{
            backgroundColor: !this.props.hideBackground
              ? "#e8e8e8"
              : "transparent",
            minHeight: "100vh",
            padding: "0px",
          }}
        >
          <TopHeader
            parent_id={"dashboard"}
            noHome={true}
            middleComponent={<></>}
          />

          {permission?.write?.indexOf("searchPatient") > -1 && (
            <WithSearch
              noNewPatient={!(permission?.write?.indexOf("newPatient") > -1)}
              parent_id={"dashboard_search_pid"}
            />
          )}
          <Row
            id="dashboard_parent_row"
            style={{
              display: "flex",
              height: "auto",
              alignItems: "center",
              backgroundColor: UIColor.differentiationBackground.color,
            }}
          >
            {/* <LoadingComp loading={this.state.loader}> */}
            <Col
              id="dashboard_parent_layout_col"
              xl={queueRead || queueWrite ? 9 : 12}
              lg={queueRead || queueWrite ? 9 : 12}
              md={queueRead || queueWrite ? 9 : 12}
              sm={12}
              xs={12}
              inLineStyles={{ padding: 0 }}
            >
              {/* <LoadingComp loading={this.state.loader}> */}
              {
                <>
                  <Layout
                    parent_id={"dashboard"}
                    newCounter={this.state.newCounter}
                    initialLayout={this.state.initialLayout}
                    deleteWidget={this.deleteWidget}
                    onLayoutChange={this.onLayoutChange}
                    componetsIndexList={this.state.componetsIndexList}
                    loader={this.state.loader}
                  />
                </>
              }
              {/* </LoadingComp>	 */}
            </Col>
            {(queueRead || queueWrite) && (
              <Col
                id="dashboard_parent_col"
                xl={3}
                lg={3}
                md={3}
                sm={12}
                xs={12}
                inLineStyles={{
                  padding: 14,
                  backgroundColor: UIColor.differentiationBackground.color,
                  borderLeft: "1px solid #E0E0E0",
                  overflow: "auto",
                  height: "90vh",
                }}
              >
                <Queue
                  queueWrite={!queueWrite}
                  parent_id={"dashboard"}
                  markHighPriority={!markHighPriority}
                  assignReassignPractitioner={!assignReassignPractitioner}
                  previous={
                    previousData.length > 0
                      ? [
                          {
                            title: "Previous",
                            token: `${previousData[0]?.token || 0}`,
                            profile_pic: previousData[0]?.profile_pic,
                            name: previousData[0]?.name,
                            consultationMode: previousData[0]?.consultationMode,
                            role_: previousData[0]?.type,
                            role:
                              previousData?.[0]?.role +
                              (previousData?.[0].type === "nurse" ||
                              previousData[0]?.type === "doctor"
                                ? " • New Patient"
                                : ""),
                            start:
                              previousData?.[0].type === "nurse" ||
                              previousData[0]?.type === "doctor"
                                ? ""
                                : previousData[0].start,
                            assignButtonAction: () => {
                              this.assignPractitioner(
                                "reassign",
                                previousData?.[0]
                              );
                            },
                          },
                        ]
                      : []
                  }
                  attend={
                    displayData.length > 0
                      ? [
                          {
                            title: "To Attend",
                            token: `${displayData[0]?.token || 0}`,
                            profile_pic: displayData[0]?.profile_pic,
                            name: displayData[0]?.name,
                            role: displayData[0]?.role,
                            role_: displayData[0]?.type,
                            encounter: displayData[0].encounter,
                            consultationMode: displayData[0]?.consultationMode,
                            patientPriority: displayData[0].patientPriority,
                            start:
                              displayData[0]?.type === "nurse" ||
                              displayData[0]?.type === "doctor"
                                ? ""
                                : displayData[0].start,
                            primaryButtonName:
                              displayData[0]?.type === "nurse" ||
                              displayData[0]?.type === "doctor"
                                ? "Attend"
                                : !displayData[0]?.isPatient
                                ? "Register Patient"
                                : "Register Visit",
                            isRegister:
                              displayData[0]?.type === "nurse" ||
                              displayData[0]?.type === "doctor"
                                ? `EN-${displayData[0]?.encounter} • New Patient`
                                : displayData[0]?.isPatient
                                ? "Registered"
                                : "Unregistered",
                            primaryButtonAction: () => {
                              if (
                                displayData?.[0]?.info?.consultationMode ===
                                  "Video Consultation" &&
                                !this.props.AVCMeeting.open
                              ) {
                                this.props.history.push({
                                  pathname: Routes.videoCall,
                                  state: {
                                    appointmentId: displayData[0].info?._key,
                                    name: this.props?.loggedUserInfo?.data?.name
                                      ?.text,
                                    role: this.props?.loggedUserInfo?.data
                                      ?.role,
                                    data: displayData[0],
                                  },
                                });
                              } else {
                                if (
                                  displayData[0]?.type === "nurse" ||
                                  displayData[0]?.type === "doctor"
                                ) {
                                  this.attendPatient(
                                    displayData[0].personId,
                                    displayData[0].patientId,
                                    displayData[0].token,
                                    displayData[0].encounterId,
                                    displayData[0]?.type,
                                    displayData[0]?.pId,
                                    displayData[0]?.encounter_id,
                                    displayData
                                  );
                                } else {
                                  this.registerPatient(
                                    displayData[0].personId,
                                    displayData[0].patientId,
                                    displayData[0].token,
                                    displayData[0]?.isPatient,
                                    displayData
                                  );
                                }
                              }
                            },
                            secondaryButtonName: "Put on Hold",
                            secondaryButtonAction: () =>
                              this.putOnHold(
                                0,
                                undefined,
                                displayData[0]?.type === "nurse" ||
                                  displayData[0]?.type === "doctor"
                                  ? "onhold_nurse"
                                  : "onhold"
                              ),
                            secondaryButtonIcon:
                              "images/icons8-pause-button.svg",
                            isEmergency: false,
                            assignButtonAction: () => {
                              debugger;
                              let assigned =
                                displayData[0]?.info?.encounter?.[0]
                                  ?.participant?.length > 0
                                  ? true
                                  : false;
                              debugger;
                              this.assignPractitioner(
                                assigned ? "reassign" : "assign",
                                displayData[0]
                              );
                            },
                            assignButtonIcon:
                              displayData[0]?.info?.encounter?.[0]?.participant
                                ?.length > 0
                                ? "images/icons8-repeat.svg"
                                : "images/icons8-change-user.svg",
                            assigned:
                              displayData[0]?.info?.encounter?.[0]?.participant
                                ?.length > 0
                                ? true
                                : false,
                            //assignButtonName: displayData[0]?.info?.resourcetype === "Practitioner" ?  "Re Assign": "Assign",
                            // isEmergency: false,
                            totalData: displayData[0],
                          },
                        ]
                      : []
                  }
                  next={[...displayData].splice(1, 1).map((d) => ({
                    title: "Up Next",
                    token: `${d?.token || 0}`,
                    profile_pic: d?.profile_pic,
                    name: d?.name,
                    role:
                      d?.role + (d.type === "nurse" ? " • New Patient" : ""),
                    encounter: d?.encounter,
                    consultationMode: d?.consultationMode,
                    patientPriority: d?.patientPriority,
                    isEmergency: false,
                    start: d.type === "nurse" ? "" : d.start,
                    assignButtonAction: () => {
                      let assigned =
                        d?.info?.encounter?.[0]?.participant?.length > 0
                          ? true
                          : false;
                      this.assignPractitioner(
                        assigned ? "reassign" : "assign",
                        d
                      );
                    },
                    assignButtonIcon:
                      d?.info?.encounter?.[0]?.participant?.length > 0
                        ? "images/icons8-repeat.svg"
                        : "images/icons8-change-user.svg",
                    assigned:
                      d?.info?.encounter?.[0]?.participant?.length > 0
                        ? true
                        : false,
                    role_: d?.type,
                    totalData: d,
                  }))}
                />
              </Col>
            )}
            {/* </LoadingComp> */}
          </Row>
        </Container>
        <Modal
          id={`assign_modal_popup`}
          open={this?.state?.assignModal}
          onClose={() => this.onModalClose(false)}
          width={1000}
          inLineStyles={{
            borderRadius: 16,
          }}
        >
          <AssignReassign
            parent_id={"doctor_card_detail"}
            title={
              this?.state?.assignModalType === "assign"
                ? "Assign Practitioner"
                : this?.state?.assignModalType === "reassign"
                ? "Re-Assign Practitioner"
                : "Close Consultation"
            }
            type={this?.state?.assignModalType}
            encounter_id={this?.state?.encounter_id}
            assignData={this.props?.assignData?.data}
            practitionerType={this.props?.practitionerType?.data ?? []}
            practitionerRole={this.props?.practitionerRole?.data ?? []}
            onModalClose={() => this.onModalClose(false)}
            appointmentStart={this.state.appointmentStart}
            appointmentEnd={this.state.appointmentEnd}
            appID={this.state?.appID}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  appointmentInfo: state?.appointmentReducer?.readAppointment,
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
  assignData: state?.dashboardApiSlice?.assignList,
  practitionerType: state?.dashboardApiSlice?.assignPractitionerType,
  practitionerRole: state?.dashboardApiSlice?.assignPractitionerRole,
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(withQueue(withAllContexts(Dashboard))));
