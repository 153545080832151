import React from "react";
import { H6, Div, H5, Button, Text } from "qdm-component-library";
import ProfileCard from "./profileCard";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { checkError, fetchUserInfo, getUtcTime } from "../../../utils";
import moment from "moment";
import { Routes } from "../../../router/routes";
import { Grid } from "@material-ui/core";
import { withAllContexts } from "../../../HOCs";
class ProfileList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      patientCount: "",
      actualData: [],
      fetchUser: "",
      vitals: [],
      personId: "",
      patientId: "",
      appointmentId: "",
      encounterId: "",
      type: "",
      pId: "",
      encounter_id: "",
      app: null,
    };
    this.fetchUserInfo = fetchUserInfo.bind(this);
  }

  styles = {
    historySectionText: {
      fontSize: "12px",
      display: "flex",
      alignItems: "center",
    },
    lastSection: {
      display: "flex",
      marginTop: "4px",
    },
    forwardIcon: {
      fontSize: "14px",
      color: "#ff6e6c",
    },
  };

  json = [
    {
      title: "Blood Pressure",
      subTitle: "Hyper Tension",
      value: "108/68",
      unit: "mm/Hg",
    },
    {
      title: "Weight",
      subTitle: "Obesity",
      value: "96",
      unit: "kg",
    },
  ];

  getPatientData = async (roleId) => {
    const userInfo = await this.fetchUserInfo();
    const loadedData = await this.props.TOTAL_PATIENT_DOCTOR_COUNT({
      _role: roleId,
      practionerId: userInfo?.practionerId,
      org_id: userInfo?.org_id,
      startDate: moment().startOf("day").unix(),
      endDate: moment().endOf("day").unix(),
    });
    const patientData = await this.props.HIGH_PRIORITY_PATIENT({
      _role: roleId,
      practionerId: userInfo?.practionerId,
      org_id: userInfo?.org_id,
      startDate: getUtcTime(moment().startOf("day")),
      endDate: getUtcTime(moment().endOf("day")),
    });

    this.setState({
      ...this.state,
      patientCount: loadedData?.payload?.data?.result[0],
      actualData: (patientData?.payload?.data?.result ?? [])?.map((item, i) => {
        return {
          profile_pic: item?.patient_id[0]?.photo[0]?.url,
          name:
            item?.patient_id[0]?.name[0]?.text ||
            item?.patient_id[0]?.name[0]?.given,
          reasonValue: item?.priorityreason?.ReasonDesc,
          isEmergency: true,
          patientPriority: {
            priority: {
              value: item?.priority?.display,
              label: item?.priority?._id,
            },
            reason: {
              value: item?.priorityreason?.ReasonDesc,
              label: item?.priorityreason?._id,
            },
            // highPriority:false,
          },
          highPriority: true,
          key: item?._key,
        };
      }),
      fetchUser: roleId,
    });
  };

  async componentDidMount() {
    const userInfo = await this.fetchUserInfo();
    const list = this.props?.Permission?.write ?? [];
    let roleId =
      list.indexOf("HighPriorityPatientsD") > -1
        ? 1
        : list.indexOf("HighPriorityPatientsN") > -1
        ? 2
        : 3;
    if (roleId === 3) {
      this.setState({
        ...this.state,
        patientCount: "0",
        actualData: this.DATA,
        fetchUser: roleId,
      });
    } else {
      this.getPatientData(roleId);
    }
  }

  handlePopoverOpen = (event, encounter, name) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
      personId: encounter?.appointmentId[0]?.PersonID?.[0],
      patientId: encounter?.patient_id[0]?.id,
      appointmentId: encounter?.appointmentId[0]?.id,
      encounterId: encounter?.id,
      type: this.state.fetchUser === 2 ? "nurse" : "doctor",
      pId: encounter?.patient_id[0]?._id,
      encounter_id: encounter?._id,
      app: encounter?.appointmentId[0]?._id,
      vitals: encounter.vitals,
    });
  };

  handlePopoverClose = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
      personId: "",
      patientId: "",
      appointmentId: "",
      encounterId: "",
      type: "",
      pId: "",
      encounter_id: "",
      app: null,
      vitals: [],
    });
  };

  handlePriority = async (list, _key, app, dropdownValue) => {
    // const state = this.state;
    const upsertdata = await this.props.UPSERT_PRIORITIES({ list, _key });

    this.getPatientData(this.state.fetchUser);
    const masterData = [...this.props.queueManagement.data];
    const newData = [...this.props.queueManagement.displayData];
    const newOnHoldData = [...this.props.queueManagement.onHoldData];
    let masterDatavariable = masterData.find((d) => d.token === app[0].id);
    let newDataVariable = newData.find((d) => d.token === app[0].id);
    let newOnHoldDataVariable = newOnHoldData.find(
      (d) => d.token === app[0].id
    );
    if (masterDatavariable) {
      const patientPriority = {
        priority: dropdownValue?.priority,
        reason: dropdownValue?.reason,
      };
      masterDatavariable["patientPriority"] = patientPriority;
    }
    if (newDataVariable) {
      const patientPriority = {
        priority: dropdownValue?.priority,
        reason: dropdownValue?.reason,
      };
      newDataVariable["patientPriority"] = patientPriority;
    }
    if (newOnHoldDataVariable) {
      const patientPriority = {
        priority: dropdownValue?.priority,
        reason: dropdownValue?.reason,
      };
      newOnHoldDataVariable["patientPriority"] = patientPriority;
    }
    this.props.queueManagement.setData(masterData);
    this.props.queueManagement.setDisplayData(newData);
    this.props.queueManagement.setOnHoldData(newOnHoldData);
  };

  DATA = [
    // {
    //   profile_pic:
    //     "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
    //   name: "Richard Gordon",
    //   reasonValue: "Reason for visit",
    //   isEmergency: true,
    //   patientPriority: {
    //     priority: "",
    //     reason: "",
    //   },
    //   highPriority: false,
    // },
    // {
    //   profile_pic:
    //     "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
    //   name: "Rachel Alvarado",
    //   reasonValue: "Accident",
    //   isEmergency: true,
    //   patientPriority: {
    //     priority: "",
    //     reason: "",
    //     // highPriority:false,
    //   },
    //   highPriority: false,
    // },
    // {
    //   profile_pic:
    //     "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
    //   name: "George Weber",
    //   reasonValue: "Accident",
    //   patientPriority: {
    //     priority: "",
    //     reason: "",
    //     // highPriority:false,
    //   },
    //   highPriority: false,
    // },
    // {
    //   profile_pic:
    //     "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
    //   name: "Tom Holland",
    //   reasonValue: "Abnormal Vitals",
    //   patientPriority: {
    //     priority: "",
    //     reason: "",
    //     // highPriority:false,
    //   },
    //   highPriority: false,
    // },
  ];

  attendPatient = async () => {
    const {
      personId,
      patientId,
      appointmentId,
      encounterId,
      type,
      pId,
      encounter_id,
      app,
    } = this.state;

    let status;
    let isCompleted;

    if (type === "nurse") {
      status = "inprogress_nurse";
      isCompleted = false;
    } else if (type === "doctor") {
      status = "doctor_inprogress";
      isCompleted = false;
    }

    await this.props.UPDATE_APPOINTMENT_STATUS({
      appointmentId,
      status,
      isCompleted,
    });

    this.props.history.push({
      pathname: Routes.patientDetail,
      state: {
        encounter_id,
        personId,
        patientId,
        appointmentId,
        encounterId,
        type,
        pId,
        app_id: app,
        isProfileView: false,
      },
    });
    console.log(
      encounter_id,
      personId,
      patientId,
      appointmentId,
      encounterId,
      type,
      pId,
      app
    );
  };

  onHold = async () => {
    const list = this.props?.Permission?.write ?? [];
    let roleId =
      list.indexOf("HighPriorityPatientsD") > -1
        ? 1
        : list.indexOf("HighPriorityPatientsN") > -1
        ? 2
        : 3;
    const { appointmentId, type } = this.state;
    let status;
    if (type === "nurse") {
      status = "onhold_nurse";
    } else if (type === "doctor") {
      status = "onhold_doctor";
    }
    const masterData = [...this.props.queueManagement.data];
    let newData = [...this.props.queueManagement.displayData];
    const onHoldVal = newData.find((d) => d?.token === appointmentId);
    newData = newData.filter((d) => d?.token !== appointmentId);
    const data = await this.props.UPDATE_APPOINTMENT_STATUS({
      appointmentId,
      status,
    });

    const statusChnage = masterData.find((d) => d?.token === onHoldVal?.token);
    statusChnage.comment = status;
    const newOnHoldData = [
      ...this.props.queueManagement.onHoldData,
      ...[onHoldVal],
    ];

    if (newData[0]) {
      const changeData = masterData.find((d) => d?.token === newData[0].token);
      changeData.showBringtoTop = false;
      newData.splice(1);
      if (newData.length <= 1) {
        const newQueueData = masterData.find(
          (d) =>
            d.index > newData[0].index &&
            !(d?.comment === "onhold" || d.comment === "completed_assemble")
        );
        if (newQueueData) {
          newData.push(newQueueData);
        }
      }
    }

    this.props.queueManagement.setData(masterData);
    this.props.queueManagement.setDisplayData(newData);
    this.props.queueManagement.setOnHoldData(newOnHoldData);
    this.getPatientData(roleId);
    this.handlePopoverClose();

    const { isError } = checkError(data?.payload);
    if (isError) {
      return false;
    }
    return true;
  };

  render() {
    const { forwardIcon, lastSection, historySectionText } = this.styles;
    return (
      <div
        parent_id={this.props.parent_id + "_parent_div"}
        style={{ height: "100%", padding: 10 }}
      >
        <H6
          parent_id={this.props.parent_id + "_high_h6"}
          style={{ marginBottom: 10, fontSize: 16 }}
        >
          High Priority Patients ({this.props.highPriority.length})
        </H6>
        <Div
          parent_id={this.props.parent_id + "_profile_div"}
          style={{ display: "flex", width: "max-content" }} //overflowX: "auto",
        >
          {Array.isArray(this.props?.highPriority) &&
            this.props?.highPriority?.map((_, i) => {
              // if (
              //   _.appointmentId[0].comment === "onhold_nurse" ||
              //   _.appointmentId[0].comment === "onhold_doctor"
              // ) {
              //   return;
              // } else {
              return (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <div
                      parent_id={
                        this.props.parent_id + "_" + i + "_profile_sub_div"
                      }
                      style={{
                        minWidth: "305px",
                        padding: "10px",
                        paddingLeft: 0,
                      }}
                      aria-owns={
                        Boolean(this.state.anchorEl)
                          ? "mouse-over-popover"
                          : undefined
                      }
                      aria-haspopup="true"
                    >
                      <ProfileCard
                        onClick={(e) => this.handlePopoverOpen(e, _)}
                        onleave={this.handlePopoverClose}
                        parent_id={this.props.parent_id + "_" + i}
                        url={_?.profile_pic}
                        title={_?.name}
                        subTitle={_?.reasonValue}
                        // isEmergency={_?.isEmergency}
                        handlePriority={this.handlePriority}
                        userInfo={this.state.fetchUser}
                        index={i}
                        priorityTotalData={_}
                      />
                    </div>
                  </Grid>
                </Grid>
              );
              // }
            })}
        </Div>

        <div>
          <Popover
            id="mouse-over-popover"
            style={{ padding: "20px" }}
            open={Boolean(this.state.anchorEl)}
            anchorEl={this.state.anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={this.handlePopoverClose}
            disableRestoreFocus
          >
            <Grid style={{ width: "280px", padding: "8px" }}>
              {this.state.vitals?.map((val, i) => {
                return (
                  <div>
                    <div style={{ display: "flex" }}>
                      <Text
                        style={{
                          paddingRight: "35px",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        {val.title}
                      </Text>
                    </div>

                    <div style={lastSection}>
                      <ArrowUpwardIcon style={forwardIcon} />

                      <Text
                        inLineStyles={{
                          color: "#ff6e6c",
                          ...historySectionText,
                        }}
                      >
                        {val?.subTitle}
                      </Text>
                      <div style={{ flexGrow: 1 }}></div>
                      <Text
                        inLineStyles={{
                          color: "#ff6e6c",
                          fontWeight: "bold",
                          ...historySectionText,
                        }}
                      >
                        {val?.value}
                      </Text>
                      <Text
                        inLineStyles={{
                          color: "#323232",
                          fontWeight: 500,
                          ...historySectionText,
                        }}
                      >
                        {val?.unit}
                      </Text>
                    </div>
                    <hr />
                  </div>
                );
              })}

              <div
                onMouseLeave={this.handlePopoverClose}
                style={{ display: "flex" }}
              >
                <Button
                  className="pc_medium"
                  backgroundColor={"#0071F2"}
                  size={12}
                  style={{
                    padding: "7px 15px",
                    borderRadius: 50,
                    marginRight: "10px",
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={this.attendPatient}
                >
                  <CheckCircleIcon fontSize="small" />
                  {"Attend"}
                </Button>
                <Button
                  className="pc_medium"
                  backgroundColor={"#FF9F40"}
                  size={12}
                  style={{
                    padding: "7px 15px",
                    borderRadius: 50,
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => this.onHold()}
                >
                  <PauseCircleOutlineIcon
                    fontSize="small"
                    style={{
                      color: "#fff",
                      size: "4",
                      backgroundColor: "#FF9F40",
                    }}
                  />
                  {"On Hold"}
                </Button>
              </div>
            </Grid>
          </Popover>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  highPriority: state?.dashboardApiSlice?.highPriorityPatient?.data || [],
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(withAllContexts(ProfileList)));
