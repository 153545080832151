import React from "react";
import { Div, Button, H6 } from "qdm-component-library";
import { UIColor, getCalculatedAge } from "../../utils";
import { withAllContexts } from "../../HOCs";
import { AxiosCall } from "../../utils";

const Footer = (props) => {
  const styles = {
    flexCenter: {
      display: "flex",
      alignItems: "center",
    },
    wrapper: {
      width: "98%",
      position: "fixed",
      bottom: "0px",
      backgroundColor: "white",
      padding: "10px",
      margin: "15px",
      borderRadius: "10px",
      border: `1px solid ${UIColor?.lineBorderFill?.color}`,
    },
    backButton: {
      backgroundColor: "#F0F0F0",
      color: "#323232",
      fontFamily: "pc_medium",
      fontSize: 14,
      borderRadius: 8,
      padding: "7px 16px",
    },
    signOffButton: {
      fontSize: 14,
      borderRadius: 8,
      padding: "7px 16px",
      fontFamily: "pc_medium",
      backgroundColor: UIColor.primaryColor.color,
      borderColor: UIColor.primaryColor.color,
    },
    eSign: {
      color: "#101010",
      fontSize: 14,
      borderBottom: "2px solid #E0E0E0",
      padding: "0px 12px 7px 12px",
      margin: 0,
      marginRight: 20,
      letterSoacing: 0,
    },
  };
  const { parent_id } = props;
  console.log("propsecounterData", props.ecounterData);
  console.log("footerProp", props?.footerData);
  console.log("constructedObj", {
    Name: props?.ecounterData?.name,
    Age: getCalculatedAge(props?.ecounterData?.dob),
    Sex: props?.ecounterData?.gender,
    DialyzerType: props?.footerData?.treatmentChart?.[0]?.DialyzerType?.obsvalue,
    DurationHrs:
      props?.footerData?.treatmentChart?.[0]?.["Duration(hrs)"]?.obsvalue,
    NoOfReuse: props?.footerData?.treatmentChart?.[0]?.Noofreuse?.obsvalue,
    Hmaintenance: props?.footerData?.treatmentChart?.[0]?.Maintenance?.obsvalue,
    PostHdWeight:
      props?.footerData?.treatmentChart?.[0]?.PrevPostHDWeight?.obsvalue,
    DryWeight: props?.footerData?.treatmentChart?.[0]?.TargetDryWeight?.obsvalue,
    UfTarget: props?.footerData?.treatmentChart?.[0]?.TargetUF?.obsvalue,
    Dialysate: props?.footerData?.treatmentChart?.[0]?.["DialysateCa2+"]?.obsvalue,
    DurationShift:
      props?.footerData?.treatmentChart?.[0]?.["DialysateCa2+"]?.obsvalue,
    DurationFreq:
      props?.footerData?.treatmentChart?.[0]?.["Frequency(/week)"]?.obsvalue,
    LastVisitDate: props?.footerData?.treatmentChart?.[0]?.LastVisitDate?.obsvalue,
    DialysisDate: props?.footerData?.treatmentChart?.[0]?.DialysisDate?.obsvalue,
    SurfaceArea: props?.footerData?.treatmentChart?.[0]?.SurfaceArea?.obsvalue,
    HdMachineNo: props?.footerData?.treatmentChart?.[0]?.HDMachineNo?.obsvalue,
  });

  const constructMoniterAndIntradialysis = (moniter, intraDyData) => {
    for (let i = 0; i < moniter.length; i++) {
      let monVal;
        if (moniter[i]?.HourlyMonitoring === "Blood Pressure (BP)") {
          monVal = "BP";
        } else if (moniter[i]?.HourlyMonitoring === "Venous Pressure") {
          monVal = "VP";
        } else if (moniter[i]?.HourlyMonitoring === "Conductivity") {
          monVal = "CONDUCTIVITY";
        } else if (moniter[i]?.HourlyMonitoring === "TMP") {
          monVal = "TEMP";
        } else if (moniter[i]?.HourlyMonitoring === "Pulse") {
          monVal = "PULSE";
        } else if (moniter[i]?.HourlyMonitoring === "Heparin") {
          monVal = "Heparin";
        }else if (moniter[i]?.HourlyMonitoring === "Staff ID") {
          monVal = "StaffID";
        }

        console.log("monVal", monVal);
        for (let j = 0; j < intraDyData.length; j++) {
          if (monVal === "id") {
            if (intraDyData[j]?.Type?.obsvalue === "2nd Hour") {
              moniter[i].Second_test = intraDyData[j]?.[monVal]?.id;
            } else if (intraDyData[j]?.Type?.obsvalue === "Baseline") {
              moniter[i].Baseline = intraDyData[j]?.[monVal]?.id;
            } else if (intraDyData[j]?.Type?.obsvalue === "1st Hour") {
              moniter[i].First_test = intraDyData[j]?.[monVal]?.id;
            } else if (intraDyData[j]?.Type?.obsvalue === "3rd Hour") {
              moniter[i].Third_test = intraDyData[j]?.[monVal]?.id;
            }
          } else {
            console.log("intraDyData", intraDyData[j]?.[monVal]?.obsvalue);
            if (intraDyData[j]?.Type?.obsvalue === "2nd Hour") {
              moniter[i].Second_test = intraDyData[j]?.[monVal]?.obsvalue;
            } else if (intraDyData[j]?.Type?.obsvalue === "Baseline") {
              moniter[i].Baseline = intraDyData[j]?.[monVal]?.obsvalue;
            } else if (intraDyData[j]?.Type?.obsvalue === "1st Hour") {
              moniter[i].First_test = intraDyData[j]?.[monVal]?.obsvalue;
            } else if (intraDyData[j]?.Type?.obsvalue === "3rd Hour") {
              moniter[i].Third_test = intraDyData[j]?.[monVal]?.obsvalue;
            }
          }
      }
    }

    return moniter;
  };
  const constructComplication = (compl, intraDyData) => {
    for (let i = 0; i < compl.length; i++) {
      let monVal;
        if (compl[i]?.Complications === "Qd(ml/min)") {
          monVal = "QD";
        } else if (compl[i]?.Complications === "Venous Pressure") {
          monVal = "VP";
        } else if (compl[i]?.Complications === "Conductivity") {
          monVal = "CONDUCTIVITY";
        } else if (compl[i]?.Complications === "TMP") {
          monVal = "TEMP";
        } else if (compl[i]?.Complications === "Pulse") {
          monVal = "PULSE";
        }else if (compl[i]?.Complications === "Heparin") {
          monVal = "Heparin";
        }else if (compl[i]?.Complications === "Staff ID") {
          monVal = "StaffID";
        }

        console.log("val21", monVal);
        for (let j = 0; j < intraDyData.length; j++) {
          if (monVal === "id") {
            if (intraDyData[j]?.Type?.obsvalue === "2nd Hour") {
              compl[i].Second_test = intraDyData[j]?.[monVal]?.id;
            } else if (intraDyData[j]?.Type?.obsvalue === "Baseline") {
              compl[i].Baseline = intraDyData[j]?.[monVal]?.id;
            } else if (intraDyData[j]?.Type?.obsvalue === "1st Hour") {
              compl[i].First_test = intraDyData[j]?.[monVal]?.id;
            } else if (intraDyData[j]?.Type?.obsvalue === "3rd Hour") {
              compl[i].Third_test = intraDyData[j]?.[monVal]?.id;
            }
          } else {
            console.log("intraDyData", intraDyData[j]?.[monVal]?.obsvalue);
            if (intraDyData[j]?.Type?.obsvalue === "2nd Hour") {
              compl[i].Second_test = intraDyData[j]?.[monVal]?.obsvalue;
            } else if (intraDyData[j]?.Type?.obsvalue === "Baseline") {
              compl[i].Baseline = intraDyData[j]?.[monVal]?.obsvalue;
            } else if (intraDyData[j]?.Type?.obsvalue === "1st Hour") {
              compl[i].First_test = intraDyData[j]?.[monVal]?.obsvalue;
            } else if (intraDyData[j]?.Type?.obsvalue === "3rd Hour") {
              compl[i].Third_test = intraDyData[j]?.[monVal]?.obsvalue;
            }
          }
      }
    }

    return compl;
  };
  const constructNotes = (notes, intraDyData) => {
    for (let i = 0; i < notes.length; i++) {
      let monVal;
      if (notes[i]?.Notes !== "Heparin") {
        if (notes[i]?.Notes === "Nursing Notes") {
          monVal = "NN";
        }else if (notes[i]?.Notes === "Staff ID") {
          monVal = "StaffID";
        }

        console.log("val21", monVal);
        for (let j = 0; j < intraDyData.length; j++) {
          if (monVal === "id") {
            if (intraDyData[j]?.Type?.obsvalue === "2nd Hour") {
              notes[i].Second_test = intraDyData[j]?.[monVal]?.id;
            } else if (intraDyData[j]?.Type?.obsvalue === "Baseline") {
              notes[i].Baseline = intraDyData[j]?.[monVal]?.id;
            } else if (intraDyData[j]?.Type?.obsvalue === "1st Hour") {
              notes[i].First_test = intraDyData[j]?.[monVal]?.id;
            } else if (intraDyData[j]?.Type?.obsvalue === "3rd Hour") {
              notes[i].Third_test = intraDyData[j]?.[monVal]?.id;
            }
          } else {
            console.log("intraDyData", intraDyData[j]?.[monVal]?.obsvalue);
            if (intraDyData[j]?.Type?.obsvalue === "2nd Hour") {
              notes[i].Second_test = intraDyData[j]?.[monVal]?.obsvalue;
            } else if (intraDyData[j]?.Type?.obsvalue === "Baseline") {
              notes[i].Baseline = intraDyData[j]?.[monVal]?.obsvalue;
            } else if (intraDyData[j]?.Type?.obsvalue === "1st Hour") {
              notes[i].First_test = intraDyData[j]?.[monVal]?.obsvalue;
            } else if (intraDyData[j]?.Type?.obsvalue === "3rd Hour") {
              notes[i].Third_test = intraDyData[j]?.[monVal]?.obsvalue;
            }
          }
        }
      }
    }

    return notes;
  };
  console.log(props?.footerData?.postdialysis?.[0]?.TEMP?.obsvalue,"ktv");
  const printPDF = async () => {
    let intraDyData = props?.footerData?.intradialysis;

    let moniter = [
      {
        HourlyMonitoring: "Blood Pressure (BP)",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        HourlyMonitoring: "Pulse",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        HourlyMonitoring: "Heparin",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        HourlyMonitoring: "Venous Pressure",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        HourlyMonitoring: "TMP",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        HourlyMonitoring: "Conductivity",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        HourlyMonitoring: "Staff ID",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },
    ];
    let Intradialysis_hourly_table = [
      {
        HourlyMonitoring: "Blood Pressure (BP)",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        HourlyMonitoring: "Pulse",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        HourlyMonitoring: "Heparin",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        HourlyMonitoring: "Venous Pressure",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        HourlyMonitoring: "TMP",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        HourlyMonitoring: "Conductivity",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        HourlyMonitoring: "Staff ID",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },
    ];

    let complication = [
      {
        Complications: "Pulse",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        Complications: "Heparin",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        Complications: "Venous Pressure",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        Complications: "TMP",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        Complications: "Conductivity",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        Complications: "Qd(ml/min)",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        Complications: "Staff ID",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },
    ];

    let notes = [
    
      {
        Notes: "Nursing Notes",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },

      {
        Notes: "Staff ID",

        Baseline: "",

        First_test: "",

        Second_test: "",

        Third_test: "",
      },
    ];

    notes = constructNotes(notes, intraDyData);

    complication = constructComplication(complication, intraDyData);

    moniter = constructMoniterAndIntradialysis(moniter, intraDyData);
    Intradialysis_hourly_table = constructMoniterAndIntradialysis(
      Intradialysis_hourly_table,
      intraDyData
    );
    console.log("complication", complication, "notes", notes,moniter,"monitoer",Intradialysis_hourly_table,"Intradialysis_hourly_table");
// console.log( props?.footerData?.treatmentChart?.[0]?.id.id,"iddddd");
    //  moniter.map((m) => {
    //     intraDyData.map((li) =>{

    //     })
    //  })

    let payload = {
      reportid: "1bcd372d-3b25-4665-adf3-28233738e9c0",

      inputparams: {},

      result: [
        {
          Mandatory: {
            logo: "13767",
            Name: props?.ecounterData?.name,
            Age: getCalculatedAge(props?.ecounterData?.dob),
            Sex: props?.ecounterData?.gender,
            DialyzerType:
              props?.footerData?.treatmentChart?.[0]?.DialyzerType?.obsvalue,
            DurationHrs:
              props?.footerData?.treatmentChart?.[0]?.["Duration(hrs)"]?.obsvalue,
            NoOfReuse: props?.footerData?.treatmentChart?.[0]?.Noofreuse?.obsvalue,
            Hmaintenance:
              props?.footerData?.treatmentChart?.[0]?.Maintenance?.obsvalue,
            DryWeight:
              props?.footerData?.treatmentChart?.[0]?.TargetDryWeight?.obsvalue,
            UfTarget: props?.footerData?.treatmentChart?.[0]?.TargetUF?.obsvalue,
            Dialysate:
              props?.footerData?.treatmentChart?.[0]?.["DialysateCa2+"]?.obsvalue,
            DurationShift:
              props?.footerData?.treatmentChart?.[0]?.["DialysateCa2+"]?.obsvalue,
            DurationFreq:
              props?.footerData?.treatmentChart?.[0]?.["Frequency(/week)"]
                ?.obsvalue,
            LastVisitDate:
              props?.footerData?.treatmentChart?.[0]?.LastVisitDate?.obsvalue,
            DialysisDate:
              props?.footerData?.treatmentChart?.[0]?.DialysisDate?.obsvalue,
            SurfaceArea:
              props?.footerData?.treatmentChart?.[0]?.SurfaceArea?.obsvalue,
            HdMachineNo:
              props?.footerData?.treatmentChart?.[0]?.HDMachineNo?.obsvalue,
            Idwg: props?.footerData?.treatmentChart?.[0]?.["IDWG(PrelastHDi/kg)"]
              ?.obsvalue,
            PrePostHdWeight:
              props?.footerData?.treatmentChart?.[0]?.PrevPostHDWeight?.obsvalue,
            PreHdWeight:
              props?.footerData?.treatmentChart?.[0]?.PreHDWeight?.obsvalue,
            AvfNeedleSize:
              props?.footerData?.treatmentChart?.[0]?.AVFneedlesize?.obsvalue,
            Hloading: props?.footerData?.treatmentChart?.[0]?.Loading?.obsvalue,
            TotalWeightLoss:
              props?.footerData?.postdialysis?.[0]?.TWL?.obsvalue,
            PostHdWeight: props?.footerData?.postdialysis?.[0]?.PHDW?.obsvalue,
            Nursingnote:props?.footerData?.postdialysis?.[0]?.NN?.obsvalue,
            Startedby_sn: props?.footerData?.predialysis?.[0]?.SS?.obsvalue,
            TotalUf: props?.footerData?.postdialysis?.[0]?.TUF?.obsvalue,
            TypeofVascularAccess:
              props?.footerData?.predialysis?.[0]?.TVA?.obsvalue,
            PatientCondition:
              props?.footerData?.predialysis?.[0]?.PCS?.obsvalue,
            ConditionOfAccess:
              props?.footerData?.predialysis?.[0]?.CCA?.obsvalue,
            Temperature: props?.footerData?.postdialysis?.[0]?.TEMP?.obsvalue,
            Bp_standing: props?.footerData?.postdialysis?.[0]?.BPStnd?.obsvalue,
            Bp_sitting:props?.footerData?.postdialysis?.[0]?.BPSit?.obsvalue,
            Pulse_standing:
              props?.footerData?.postdialysis?.[0]?.PULSESTND?.obsvalue,
            Pulse_sitting:
            props?.footerData?.postdialysis?.[0]?.PULSESIT?.obsvalue,
            Htype: props?.footerData?.treatmentChart?.[0]?.Type?.obsvalue,
            FlowRateQb:
              props?.footerData?.treatmentChart?.[0]?.["Qb(ml/min)"]?.obsvalue,
            FlowRateQd:
              props?.footerData?.treatmentChart?.[0]?.["Qd(ml/min)"]?.obsvalue,
            "Kt/v":props?.footerData?.postdialysis?.[0]?.["Kt/V"]?.obsvalue,
            endTime:props?.footerData?.postdialysis?.[0]?.EndTime?.obsvalue,
            Endingby_sn:props?.footerData?.postdialysis?.[0]?.EndedBySN?.obsvalue,
            Timearrived:props?.footerData?.predialysis?.[0]?.["Time(Arrived)"]?.obsvalue,
            TimeStart:props?.footerData?.predialysis?.[0]?.["Time(StartHD)"]?.obsvalue,
            TreatmentStaffId:props?.footerData?.treatmentChart?.[0]?.StaffID?.obsvalue,
            MonitorParameter: moniter,
            Intra_Dialysis_Medication: [
              {
                Description: "IV iron",

                Dosage: "",

                "Given or Not": "",

                "Given By": "",
              },

              {
                Description: "Vaccination",

                Dosage: "",

                "Given or Not": "",

                "Given By": "",
              },

              {
                Description: "EPO",

                Dosage: "",

                "Given or Not": "",

                "Given By": "",
              },

              {
                Description: "Staff ID",

                Dosage: "",

                "Given or Not": "",

                "Given By": "",
              },
            ],

            Intradialysis_hourly_table: Intradialysis_hourly_table,
            Complication: complication,
            Notes: notes,
          },

          optional: {},
        },
      ],
    };

    let result = await AxiosCall(
      "post",
      process.env.REACT_APP_GENERATEREPORT,
      payload
    );

    console.log("finalresult", result?.downloadUrl);
    window.open(result?.downloadUrl);
  };

  return (
    <div id={`${parent_id}_footer_parent_div`} style={styles.wrapper}>
      <Div id={`${parent_id}_footer_parent_sub_div`} style={styles.flexCenter}>
        {props?.goBackButton && (
          <Div id={`${parent_id}_footer_button_div`} style={{ flex: 1 }}>
            <Button
              id={`${parent_id}_footer_goback_button`}
              onClick={() =>
                props?.goBackButtonAction && props?.goBackButtonAction()
              }
              variant={"text"}
              inLineStyles={styles.backButton}
            >
              {props?.goBackButton}
            </Button>
          </Div>
        )}

        <Div
          id={`${parent_id}_footer_confrim_button_div`}
          style={styles.flexCenter}
        >
          {props?.eSignature && (
            <H6
              id={`${parent_id}_footer_E-signature_h6`}
              className="pc_regular"
              style={styles.eSign}
            >
              E-signature
            </H6>
          )}
          {props.ecounterData && props?.footerData && (
            <Button
              id={`${parent_id}_footer_confirm_button`}
              onClick={printPDF}
              inLineStyles={styles.signOffButton}
              style={{ marginRight: "10px" }}
            >
              {"Print"}
            </Button>
          )}
          {props?.confirmButton && (
            <Button
              id={`${parent_id}_footer_confirm_button`}
              onClick={() =>
                props?.confirmButtonAction && props?.confirmButtonAction()
              }
              inLineStyles={styles.signOffButton}
            >
              {props?.confirmButton}
            </Button>
          )}
        </Div>
      </Div>
    </div>
  );
};

Footer.defaultProps = {
  goBackButton: "Go back",
  goBackButtonAction: null,
  confirmButton: "Confirm",
  confirmButtonAction: null,
  eSignature: true,
};

export default withAllContexts(Footer);

