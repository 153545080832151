import jwt from "jsonwebtoken";
import moment from "moment";
import { getUtcTime } from ".";
import Config from "../config";
import { Routes } from "../router/routes";
import { checkWithCasbin } from "./permissonHandling";
import { utcTOLocal } from "./validations";
import { isValidPhoneNumber } from "libphonenumber-js/mobile";
import { countryCode_ } from "./countryCode";
import { setHours, setMinutes } from "date-fns";
import { Schedule } from "@material-ui/icons";
import axios from "axios";

/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc Collection of all helper functions.
 */

/**
 * Object with role as key and value, which is used for
 * comparison of role in different place.
 */
export const UserRoles = {
  role: "role",
};

/**
 * Object which has the proper name of all the role
 * used in the application.
 */
export let UserRolesName = {
  role: "Role",
};

/**
 * Object which has the different themes used in
 * the application.
 */
export let Themes = {
  default: "default",
  dark: "dark",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert)
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
};

/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js)
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};

/**
 * Object has the key and value pair of all the keys which
 * are used to store some values in the local storage.
 */
export let LocalStorageKeys = {
  authToken: "auth_token",
  version: "version",
  deviceToken: "device_token",
};

/**
 * Object has the key and value pair of all the HTTP method
 * used for an network call.
 */
export let NetWorkCallMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  update: "UPDATE",
};

/**
 * The below function convert the normal array of object to
 * {label: "",value:1} pair which is suitable for React Select
 * component.
 */
export let ConvertToReactSelect = (data, valueKey, labelKey) => {
  if (!data || !data?.length) {
    return [];
  }

  return data.map((val) => {
    return {
      ...val,
      value: val[valueKey],
      label: val[labelKey],
    };
  });
};

/**
 * The below function convert the uploaded file to base64 file.
 */
export let ToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

/**
 * The below function capitalize the given string.
 */
export let CapitalizeString = (string) => {
  if (!string) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * The below function convert the HEX code to RGBA
 */
export let ConvertHexToRGBA = (hex, opacity) => {
  if (hex) {
    let tempHex = hex.replace("#", "");
    let r = parseInt(tempHex.substring(0, 2), 16);
    let g = parseInt(tempHex.substring(2, 4), 16);
    let b = parseInt(tempHex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity / 100})`;
  }
  return null;
};

/**
 * The below function will open an document node in a full screen.
 */
export let OpenFullScreen = (id) => {
  let elem = document.getElementById(id);
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Chrome, Safari & Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE/Edge */
    elem.msRequestFullscreen();
  }
};

/**
 * The below function will close the full screen of an document
 * node.
 */
export let CloseFullScreen = (id) => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
};

/**
 * The below function will scroll the page to the Top.
 */
export let ScrollToTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

/**
 * The below function will check for request'a response error.
 */
export let checkError = (data) => {
  const isError = data?.error || data?.data?.error;
  let errMsg = "Something went wrong! Try Again.";
  if (
    data?.data?.error ||
    data?.data?.errorMessage ||
    data?.data?.error_description
  ) {
    if (typeof data?.data?.error === "string") {
      errMsg =
        data?.data?.error ||
        data?.data?.errorMessage ||
        data?.data?.error_description;
    } else {
      errMsg =
        data?.data?.errorMessage ||
        data?.data?.error_description ||
        data?.data?.message ||
        errMsg;
    }
  }
  if (errMsg === "invalid_grant" || errMsg === "Invalid user credentials") {
    errMsg = "Invalid Password / User ID";
  }
  return {
    isError: !!isError,
    errMsg,
  };
};

export const convertTime = (time, inputFormat, outputFormat, type = "date") => {
  if (type === "date") {
    if (inputFormat !== "unix") {
      let test1 = moment(time, inputFormat).format(outputFormat);
      console.log(
        "test1",
        test1,
        "inputFo",
        inputFormat,
        "Time",
        time,
        "outpu",
        outputFormat
      );
      return moment(time, inputFormat).format(outputFormat);
    }

    const date = new Date(time * 1000);
    const formattedDate = moment(date).format(outputFormat);
    console.log(
      "test2",
      formattedDate,
      "date",
      date,
      "Time",
      time,
      "outpu",
      outputFormat
    );

    return formattedDate;
    // const date = new Date(time * 1000);
    // let test2 = moment(date).format(outputFormat);
    // console.log(
    //   "test2",
    //   test2,
    //   "date",
    //   date,
    //   "Time",
    //   time,
    //   "outpu",
    //   outputFormat
    // );
    // return moment(date).format(outputFormat);
  }
  if (type === "diff") {
    if (inputFormat === "unix") {
      let test3 = moment().diff(moment.unix(time), outputFormat);
      console.log("test2", test3);
      return moment().diff(moment.unix(time), outputFormat);
    }
    let test4 = moment().diff(moment(time, inputFormat), outputFormat);
    console.log("test2", test4);
    return moment().diff(moment(time, inputFormat), outputFormat);
  }
};

export const getMobileNumber = (input) => {
  return `+${input?.countryData?.callingCodes ?? "00"}${input.mobileNo}`;
};

export let getUserInfo = () => {
  if (localStorage.getItem("token")) {
    try {
      const info = jwt.decode(
        JSON.parse(atob(localStorage.getItem("token"))).access_token
      );
      return {
        name: info?.given_name,
        email: info?.email,
        mobile: info?.preferred_username,
        type: "token",
      };
    } catch (err) {
      localStorage.removeItem("token");
      window.location = Routes.signIn;
    }
  }
  if (localStorage.getItem("guestInfo")) {
    const info = JSON.parse(localStorage.getItem("guestInfo"));
    return {
      name: info.name,
      info: info,
      type: "guest",
    };
  }
  return {
    name: "",
    type: null,
  };
};

export const makeName = (nameObj = {}) => {
  !Boolean(nameObj) && (nameObj = {});
  const {
    prefix = null,
    given = "",
    text = "",
    suffix = null,
    family = "",
  } = nameObj;
  console.log(nameObj, "nameObj");
  let prefixVal =
    prefix?.[0]?.coding?.[0]?.display ||
    prefix?.coding?.[0]?.display ||
    prefix?.[0]?.display ||
    prefix?.display ||
    "";
  let suffixVal =
    suffix?.[0]?.coding?.[0]?.display ||
    suffix?.coding?.[0]?.display ||
    suffix?.[0]?.display ||
    suffix?.display ||
    "";
  return `${prefixVal && prefixVal + "."}${text && text + " "}${
    given && given + " "
  }${family && family + " "}${suffixVal && suffixVal}`;
};

const getDayString = (type, start, end) => {
  let string = "";

  // var a = moment();
  // var b = moment.unix(end);
  var b = moment(moment().format("DD MMM YYYY"));
  var a = moment(moment.unix(end).format("DD MMM YYYY"));

  var years = a.diff(b, "year");
  b.add(years, "years");

  var months = a.diff(b, "months");
  b.add(months, "months");

  if (type === "weeks") {
    var weeks = a.diff(b, "weeks");
    b.add(weeks, "weeks");
  }

  var days = a.diff(b, "days");

  if (type === "weeks") {
    string =
      Math.abs(weeks) +
      " Week " +
      (Math.abs(days) ? Math.abs(days) + " Day" : "");
  }
  if (type === "months") {
    string =
      Math.abs(months) +
      " Month " +
      (Math.abs(days) ? Math.abs(days) + " Day" : "");
  }

  return string;
};

export const TimeStampToMnthandDays = (val) => {
  let day_ = val?.followupdurval ?? 0;
  let type_ = val?.followupduruom?.display?.toLocaleLowerCase() ?? "days";
  let date = moment
    .unix(val.followupdate)
    .startOf("day")
    .add(day_, type_)
    .unix();
  const CuuDate = moment().startOf("day");
  const para = moment.unix(date).startOf("day");

  const daysDiff = Math.abs(CuuDate.diff(para, "days"));
  const weeksDiff = Math.abs(CuuDate.diff(para, "weeks"));
  const monthDiff = Math.abs(CuuDate.diff(para, "months"));

  // const daysDiff = para.diff(CuuDate, "days");
  // const weeksDiff = para.diff(CuuDate, "weeks");
  // const monthDiff = para.diff(CuuDate, "months");

  // if(daysDiff < 0 || weeksDiff < 0 || monthDiff < 0){
  //   return "Date Expired"
  // }

  if (daysDiff >= 7) {
    if (weeksDiff > 4) {
      return getDayString("months", CuuDate, date);
    }
    return getDayString("weeks", CuuDate, date);
  } else if (daysDiff === 0) {
    return "Today";
  } else {
    return daysDiff + " Days";
  }
};

export const Logout = () => {
  localStorage.clear();
  window.location.reload();
};
export const upsertPerson = async (getfn, upsertfn, data) => {
  try {
    if (getfn) {
      const persondata = await getfn({
        phone: data.phone ? data.phone : data.email,
      });
      const { isError, errMsg } = checkError(persondata?.payload);
      if (
        isError ||
        (persondata?.payload?.data?.id && persondata?.payload?.data?.id !== 0)
      ) {
        if (isError) {
          return {
            error: true,
            data: errMsg,
            getData: null,
          };
        }
        return {
          error: true,
          data: `User Already Exists for ${
            data.phone ? data.phone : data.email
          }`,
          getData: persondata,
        };
      }
    }
    const upsertData = await upsertfn(data);
    const { isError: isErr, errMsg: msg } = checkError(upsertData?.payload);
    if (isErr) {
      return {
        error: true,
        data: msg,
        getData: null,
      };
    }
    return {
      error: false,
      data: upsertData,
      getData: null,
    };
  } catch (err) {
    return {
      error: true,
      data: "Something went wrong",
      getData: null,
    };
  }
};
export const round = (date, duration, method) => {
  return moment(Math[method](+date / +duration) * +duration);
};
const weekOfMonth = (m) => {
  return m.week() - m.startOf("month").week() + 1;
};

export const diffeenceBtArray = (arr1, arr2) => {
  return arr1
    .filter((x) => !arr2.includes(x))
    .concat(arr2.filter((x) => !arr1.includes(x)));
};
export const getReccuranceDates = (
  startDate, // DD-MM-YYYY
  endType, // [by => Calendar, on => NoOfDays]
  endAfter, // // [by => Date(DD-MM-YYYY), on => number(5)]
  repeatType, // weeks, month, days
  repeatVal, // number
  preferredDays, // []
  preferredWeeks, // []
  dates = []
) => {
  let endDate;
  const preferrdDaysArr = sortNumbers(preferredDays.map((day) => day.value));
  const preferrdWeeksArr = sortNumbers(preferredWeeks.map((day) => day.value));
  const currentDay = moment(startDate, "DD-MM-YYYY").day();
  const currentWeek = weekOfMonth(moment(startDate, "DD-MM-YYYY"));
  const allDates = [...dates];
  let prevDate = "";
  let previousDay = -1;
  let previousWeek = -1;
  let foundMonthDay = false;
  const isTypeDay = repeatType?.toLowerCase() === "days";
  const isTypeWeek =
    repeatType?.toLowerCase() === "weeks" ||
    repeatType?.toLowerCase() === "week";
  const isTypeMonth =
    repeatType?.toLowerCase() === "months" ||
    repeatType?.toLowerCase() === "month";
  if (
    isTypeDay ||
    (isTypeWeek &&
      (preferrdDaysArr.includes(currentDay) || preferrdDaysArr.length === 0)) ||
    (isTypeMonth &&
      (preferrdWeeksArr.includes(currentWeek) || preferrdWeeksArr.length === 0))
  ) {
    if (isTypeMonth && preferrdDaysArr.length > 0) {
      if (preferrdDaysArr.includes(currentDay)) {
        foundMonthDay = true;
        previousDay = currentDay;
        previousWeek = currentWeek;
        prevDate = startDate;
        dates.push(
          Math.floor(moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000)
        );
        allDates.push(
          Math.floor(moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000)
        );
      } else {
        for (let i = 0; i < preferrdDaysArr.length; i++) {
          const day = preferrdDaysArr[i];
          if (day > currentDay) {
            foundMonthDay = true;
            previousDay = currentDay;
            previousWeek = currentWeek;
            prevDate = moment(startDate, "DD-MM-YYYY")
              .day(day)
              .format("DD-MM-YYYY");
            dates.push(
              Math.floor(
                moment(startDate, "DD-MM-YYYY").day(day).toDate().getTime() /
                  1000
              )
            );
            allDates.push(
              Math.floor(
                moment(startDate, "DD-MM-YYYY").day(day).toDate().getTime() /
                  1000
              )
            );
          }
        }
      }
    } else {
      foundMonthDay = true;
      previousDay = currentDay;
      previousWeek = currentWeek;
      prevDate = startDate;
      dates.push(
        Math.floor(moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000)
      );
      allDates.push(
        Math.floor(moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000)
      );
    }
  } else {
    if (isTypeWeek && !preferrdDaysArr.includes(currentDay)) {
      let isfound = false;
      let tempDay = -1;
      let tempDate;
      for (let i = 0; i < preferrdDaysArr.length; i++) {
        const nextDay = preferrdDaysArr[i];
        if (nextDay > currentDay) {
          isfound = true;
          const newFirstDate = moment(startDate, "DD-MM-YYYY").day(nextDay);
          previousDay = nextDay;
          prevDate = newFirstDate.format("DD-MM-YYYY");
          dates.push(Math.floor(newFirstDate.toDate().getTime() / 1000));
          allDates.push(Math.floor(newFirstDate.toDate().getTime() / 1000));
        } else {
          if (tempDay === -1 || currentDay - tempDay < currentDay - nextDay) {
            const newFirstDate = moment(startDate, "DD-MM-YYYY").day(nextDay);
            tempDay = nextDay;
            tempDate = newFirstDate;
          }
        }
      }
      if (!isfound) {
        if (tempDate) {
          previousDay = tempDay;
          prevDate = tempDate.format("DD-MM-YYYY");
        }
      }
    } else if (isTypeMonth && !preferrdWeeksArr.includes(currentWeek)) {
      let isfound = false;
      let tempWeek = -1;
      let tempDay2 = -1;
      let previousDay2 = -1;
      let tempDate2;
      foundMonthDay = true;
      if (preferrdDaysArr.length > 0) {
        previousDay = preferrdDaysArr[0];
      }
      let tempDate;
      for (let i = 0; i < preferrdWeeksArr.length; i++) {
        const nextWeek = preferrdWeeksArr[i];
        for (let i = 0; i < preferrdDaysArr.length; i++) {
          const nextDay = preferrdDaysArr[i];
          if (nextDay > currentDay) {
            isfound = true;
            const newFirstDate = moment(startDate, "DD-MM-YYYY").day(nextDay);
            previousDay2 = nextDay;
            prevDate = newFirstDate.format("DD-MM-YYYY");
            let st_date = moment(startDate, "DD-MM-YYYY").day();
            let cur_month = moment(startDate, "DD-MM-YYYY")
              .startOf("month")
              .week(
                moment(startDate, "DD-MM-YYYY").startOf("month").week() +
                  (nextWeek - 1)
              )
              .day(nextDay);
            const secondFirstDate = moment(startDate, "DD-MM-YYYY").add(
              Number(repeatVal),
              repeatType
            );
            let next_month = secondFirstDate
              .startOf("month")
              .week(secondFirstDate.startOf("month").week() + (nextWeek - 1))
              .day(nextDay);
            let cur_month_ts = Math.floor(cur_month.toDate().getTime() / 1000);
            let next_month_ts = Math.floor(
              next_month.toDate().getTime() / 1000
            );
            let newFirstDate_ts = Math.floor(
              newFirstDate.toDate().getTime() / 1000
            );
            if (
              (cur_month_ts == newFirstDate_ts && next_month >= st_date) ||
              (next_month_ts == newFirstDate_ts && next_month_ts >= st_date)
            ) {
              dates.push(newFirstDate_ts);
              allDates.push(newFirstDate_ts);
            }

            //dates.push(Math.floor(newFirstDate.toDate().getTime() / 1000));
            //allDates.push(Math.floor(newFirstDate.toDate().getTime() / 1000));
          } else {
            if (
              tempDay2 === -1 ||
              currentDay - tempDay2 < currentDay - nextDay
            ) {
              const newFirstDate2 = moment(startDate, "DD-MM-YYYY").day(
                nextDay
              );
              tempDay2 = nextDay;
              tempDate2 = newFirstDate2;
            }
          }
        }
        if (nextWeek > currentWeek) {
          foundMonthDay = true;
          isfound = true;
          let newFirstDate = moment(startDate, "DD-MM-YYYY")
            .startOf("week")
            .week(
              moment(startDate, "DD-MM-YYYY").startOf("week").week() +
                (nextWeek - currentWeek)
            );
          if (preferrdDaysArr.length > 0) {
            newFirstDate = newFirstDate.startOf("week").day(previousDay);
          }
          previousWeek = nextWeek;
          prevDate = newFirstDate.format("DD-MM-YYYY");
          dates.push(Math.floor(newFirstDate.toDate().getTime() / 1000));
          allDates.push(Math.floor(newFirstDate.toDate().getTime() / 1000));
          // break;
        } else {
          if (
            tempWeek === -1 ||
            currentWeek - tempWeek < currentWeek - nextWeek
          ) {
            let newFirstDate = moment(startDate, "DD-MM-YYYY")
              .startOf("month")
              .add(1, "month")
              .startOf("month")
              .week(
                moment(startDate, "DD-MM-YYYY")
                  .startOf("month")
                  .add(1, "month")
                  .startOf("month")
                  .week() +
                  (nextWeek - 1)
              );
            if (preferrdDaysArr.length > 0) {
              newFirstDate = newFirstDate.startOf("week").day(previousDay);
            }
            tempWeek = nextWeek;
            tempDate = newFirstDate;
          }
        }
      }
      if (!isfound) {
        if (tempDate) {
          foundMonthDay = true;
          previousWeek = tempWeek;
          prevDate = tempDate.format("DD-MM-YYYY");
          dates.push(Math.floor(tempDate.toDate().getTime() / 1000));
          allDates.push(Math.floor(tempDate.toDate().getTime() / 1000));
        }
      }
    }
  }
  if (isTypeMonth && !foundMonthDay) {
    previousDay = preferrdDaysArr[0];
    let newFirstDate = moment(startDate, "DD-MM-YYYY")
      .startOf("month")
      .add(1, "month")
      .startOf("month")
      .week(
        moment(startDate, "DD-MM-YYYY")
          .startOf("month")
          .add(1, "month")
          .startOf("month")
          .week() +
          (currentWeek - 1)
      );
    if (preferrdDaysArr.length > 0) {
      newFirstDate = newFirstDate.startOf("week").day(previousDay);
    }
    previousWeek = currentWeek;
    prevDate = newFirstDate.format("DD-MM-YYYY");
    dates.push(Math.floor(newFirstDate.toDate().getTime() / 1000));
    allDates.push(Math.floor(newFirstDate.toDate().getTime() / 1000));
  }
  const isEndTypeDate = endType === "Calendar";
  if (isTypeWeek || isTypeMonth) {
    if (Number(repeatVal) > 1 && dates.length === 0) {
      const date = moment(prevDate, "DD-MM-YYYY").add(Number(1), repeatType);
      if (isTypeWeek) {
        if (preferrdDaysArr.length > 0) {
          preferrdDaysArr.forEach((day) => {
            const weekDay = moment(date).day(day);
            const timeStamp = Math.floor(weekDay.toDate().getTime() / 1000);
            // if (day === previousDay) {
            dates.push(timeStamp);
            // }
            allDates.push(timeStamp);
          });
        } else {
          const timeStamp = Math.floor(date.toDate().getTime() / 1000);
          dates.push(timeStamp);
          allDates.push(timeStamp);
        }
        prevDate = date.format("DD-MM-YYYY");
      } else {
      }
    }
  }
  if (isEndTypeDate) {
    endDate = moment(endAfter, "DD-MM-YYYY");
    let tilldate = 0;
    let date;
    let isexit = 0;
    let isFirst = 0;
    while (true) {
      if (dates.length > 1 || tilldate == 1 || repeatType == "months") {
        date = moment(prevDate, "DD-MM-YYYY").add(
          Number(repeatVal),
          repeatType
        );
      }
      if (
        ((dates.length == 1 || dates.length == 0) &&
          tilldate == 0 &&
          repeatType != "months") ||
        (repeatType == "months" && isFirst == 0)
      ) {
        isFirst = 1;
        date = moment(startDate, "DD-MM-YYYY");
      }
      if (endDate < date && isexit == 0) {
        date = endDate;
        isexit = 1;
      }
      if (endDate.diff(date, "days") < 0) {
        isexit = 0;
        // let newdates=[]
        // dates.forEach(newdatevalue => {
        //   console.log(newdatevalue)
        //   if(newdatevalue>= Math.floor(moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000)){
        //     newdates.push(newdatevalue)
        //   }
        // });
        // dates=newdates
        const startdate_timeStamp = Math.floor(
          moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000
        );
        if (endDate !== undefined) {
          let enddate_timeStamp = Math.floor(endDate.toDate().getTime() / 1000);
          dates = dates.filter(
            (dt) => startdate_timeStamp <= dt && enddate_timeStamp >= dt
          );
        }
        return [dates, allDates];
      }
      if (isTypeDay) {
        const timeStamp = Math.floor(date.toDate().getTime() / 1000);
        prevDate = date.format("DD-MM-YYYY");
        allDates.push(timeStamp);
        dates.push(timeStamp);
      } else if (isTypeWeek) {
        if (preferrdDaysArr.length > 0) {
          preferrdDaysArr.forEach((day) => {
            console.log(day, "day");
            const weekDay = moment(date).day(day);
            const timeStamp = Math.floor(weekDay.toDate().getTime() / 1000);
            const startdate_timeStamp = Math.floor(
              moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000
            );
            endDate = moment(endAfter, "DD-MM-YYYY");
            const enddate_timeStamp = Math.floor(
              endDate.toDate().getTime() / 1000
            );
            if (
              startdate_timeStamp <= timeStamp &&
              enddate_timeStamp >= timeStamp
            ) {
              dates.push(timeStamp);
            }
            if (
              dates.length == 1 ||
              preferrdDaysArr.filter((x) => x > date.day()).length == 0
            ) {
              tilldate = 1;
            }
            allDates.push(timeStamp);
          });
        } else {
          const timeStamp = Math.floor(date.toDate().getTime() / 1000);
          dates.push(timeStamp);
          allDates.push(timeStamp);
        }
        prevDate = date.format("DD-MM-YYYY");
      } else {
        // if (preferrdWeeksArr.length > 0) {
        //   if (preferrdDaysArr.length > 0) {
        //     const newFirstDate = moment(date, "DD-MM-YYYY")
        //       .startOf("month")
        //       .week(
        //         moment(date, "DD-MM-YYYY").startOf("month").week() +
        //           (previousWeek - 1)
        //       )
        //       .day(previousDay);
        //     const timeStamp = Math.floor(
        //       newFirstDate.toDate().getTime() / 1000
        //     );
        //     dates.push(timeStamp);
        //     allDates.push(timeStamp);
        //   }
        //   else {
        //     let newFirstDate = moment(date, "DD-MM-YYYY")
        //       .startOf("month")
        //       .week(
        //         moment(date, "DD-MM-YYYY").startOf("month").week() +
        //           (previousWeek - 1)
        //       );
        //     const timeStamp = Math.floor(
        //       newFirstDate.toDate().getTime() / 1000
        //     );
        //     dates.push(timeStamp);
        //     allDates.push(timeStamp);
        //   }
        // }
        if (preferrdWeeksArr.length > 0) {
          preferrdWeeksArr.forEach((w) => {
            console.log(w);
            if (preferrdDaysArr.length > 0) {
              preferrdDaysArr.forEach((d) => {
                console.log(d);
                const newFirstDate = moment(date, "DD-MM-YYYY")
                  .startOf("month")
                  .week(
                    moment(date, "DD-MM-YYYY").startOf("month").week() + (w - 1)
                  )
                  .day(d);
                const timeStamp = Math.floor(
                  newFirstDate.toDate().getTime() / 1000
                );
                const startdate_timeStamp = Math.floor(
                  moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000
                );
                endDate = moment(endAfter, "DD-MM-YYYY");
                const enddate_timeStamp = Math.floor(
                  endDate.toDate().getTime() / 1000
                );
                if (
                  startdate_timeStamp <= timeStamp &&
                  enddate_timeStamp >= timeStamp
                ) {
                  dates.push(timeStamp);
                }
                allDates.push(timeStamp);
              });
            } else {
              let newFirstDate = moment(date, "DD-MM-YYYY")
                .startOf("month")
                .week(
                  moment(date, "DD-MM-YYYY").startOf("month").week() + (w - 1)
                );
              const timeStamp = Math.floor(
                newFirstDate.toDate().getTime() / 1000
              );
              const startdate_timeStamp = Math.floor(
                moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000
              );
              endDate = moment(startDate, "DD-MM-YYYY").add(
                Number(endAfter),
                repeatType
              );
              const enddate_timeStamp = Math.floor(
                endDate.toDate().getTime() / 1000
              );
              if (
                startdate_timeStamp <= timeStamp &&
                enddate_timeStamp >= timeStamp
              ) {
                dates.push(timeStamp);
              }
              allDates.push(timeStamp);
            }
          });
        } else {
          const timeStamp = Math.floor(date.toDate().getTime() / 1000);
          dates.push(timeStamp);
          allDates.push(timeStamp);
        }
        prevDate = date.format("DD-MM-YYYY");
      }
    }
  }
  let tilldate = 0;

  for (let i = 0; i <= Number(endAfter); i++) {
    let date;
    if (dates.length > 1 || tilldate == 1 || repeatType == "months") {
      date = moment(prevDate, "DD-MM-YYYY").add(Number(repeatVal), repeatType);
    }
    if (
      ((dates.length == 1 || dates.length == 0) &&
        tilldate == 0 &&
        repeatType != "months") ||
      (repeatType == "months" && i == 0)
    ) {
      date = moment(startDate, "DD-MM-YYYY");
    }
    if (isTypeDay) {
      const timeStamp = Math.floor(date.toDate().getTime() / 1000);
      prevDate = date.format("DD-MM-YYYY");
      allDates.push(timeStamp);
      dates.push(timeStamp);
    } else if (isTypeWeek) {
      if (preferrdDaysArr.length > 0) {
        preferrdDaysArr.forEach((day) => {
          console.log(day);
          const weekDay = moment(date).day(day);
          const timeStamp = Math.floor(weekDay.toDate().getTime() / 1000);
          const startdate_timeStamp = Math.floor(
            moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000
          );
          endDate = moment(
            moment(startDate, "DD-MM-YYYY").add(Number(endAfter), repeatType),
            "DD-MM-YYYY"
          );
          const enddate_timeStamp = Math.floor(
            endDate.toDate().getTime() / 1000
          );
          if (
            startdate_timeStamp <= timeStamp &&
            enddate_timeStamp >= timeStamp &&
            !(
              enddate_timeStamp == timeStamp &&
              dates.filter((x) => x == startdate_timeStamp).length > 0
            )
          ) {
            dates.push(timeStamp);
          }
          if (
            dates.length == 1 ||
            preferrdDaysArr.filter((x) => x > date.day()).length == 0
          ) {
            tilldate = 1;
          }
          allDates.push(timeStamp);
        });
      } else {
        const timeStamp = Math.floor(date.toDate().getTime() / 1000);
        endDate = moment(startDate, "DD-MM-YYYY").add(
          Number(endAfter),
          repeatType
        );
        const startdate_timeStamp = Math.floor(
          moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000
        );
        const enddate_timeStamp = Math.floor(endDate.toDate().getTime() / 1000);
        if (
          startdate_timeStamp <= timeStamp &&
          enddate_timeStamp >= timeStamp &&
          !(
            enddate_timeStamp == timeStamp &&
            dates.filter((x) => x == startdate_timeStamp).length > 0
          )
        ) {
          dates.push(timeStamp);
        }

        allDates.push(timeStamp);
      }
      prevDate = date.format("DD-MM-YYYY");
    } else {
      if (preferrdWeeksArr.length > 0) {
        preferrdWeeksArr.forEach((w) => {
          console.log(w);
          if (preferrdDaysArr.length > 0) {
            preferrdDaysArr.forEach((d) => {
              console.log(d);
              const newFirstDate = moment(date, "DD-MM-YYYY")
                .startOf("month")
                .week(
                  moment(date, "DD-MM-YYYY").startOf("month").week() + (w - 1)
                )
                .day(d);
              const timeStamp = Math.floor(
                newFirstDate.toDate().getTime() / 1000
              );
              const startdate_timeStamp = Math.floor(
                moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000
              );
              endDate = moment(startDate, "DD-MM-YYYY").add(
                Number(endAfter),
                repeatType
              );
              const enddate_timeStamp = Math.floor(
                endDate.toDate().getTime() / 1000
              );
              if (
                startdate_timeStamp <= timeStamp &&
                enddate_timeStamp >= timeStamp &&
                !(
                  enddate_timeStamp == timeStamp &&
                  dates.filter((x) => x == startdate_timeStamp).length > 0
                )
              ) {
                dates.push(timeStamp);
              }
              allDates.push(timeStamp);
            });

            // const newFirstDate = moment(date, "DD-MM-YYYY")
            //   .startOf("month")
            //   .week(
            //     moment(date, "DD-MM-YYYY").startOf("month").week() +
            //       (previousWeek - 1)
            //   )
            //   .day(previousDay);
            // const timeStamp = Math.floor(newFirstDate.toDate().getTime() / 1000);
            // const startdate_timeStamp = Math.floor((moment(startDate, "DD-MM-YYYY")).toDate().getTime() / 1000);
            // endDate= moment(startDate, "DD-MM-YYYY").add(
            //   Number(endAfter),
            //   repeatType
            // );
            // const enddate_timeStamp	=Math.floor(endDate.toDate().getTime() / 1000);
            // if(startdate_timeStamp<= timeStamp && enddate_timeStamp>=timeStamp
            //   && !(enddate_timeStamp==timeStamp && dates.filter(x => x == startdate_timeStamp).length>0)){
            //   dates.push(timeStamp);
            // }
            // allDates.push(timeStamp);
          } else {
            let newFirstDate = moment(date, "DD-MM-YYYY")
              .startOf("month")
              .week(
                moment(date, "DD-MM-YYYY").startOf("month").week() + (w - 1)
              );
            const timeStamp = Math.floor(
              newFirstDate.toDate().getTime() / 1000
            );
            const startdate_timeStamp = Math.floor(
              moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000
            );
            endDate = moment(startDate, "DD-MM-YYYY").add(
              Number(endAfter),
              repeatType
            );
            const enddate_timeStamp = Math.floor(
              endDate.toDate().getTime() / 1000
            );
            if (
              startdate_timeStamp <= timeStamp &&
              enddate_timeStamp >= timeStamp &&
              !(
                enddate_timeStamp == timeStamp &&
                dates.filter((x) => x == startdate_timeStamp).length > 0
              )
            ) {
              dates.push(timeStamp);
            }
            allDates.push(timeStamp);
          }
        });
      } else {
        const timeStamp = Math.floor(date.toDate().getTime() / 1000);
        endDate = moment(startDate, "DD-MM-YYYY").add(
          Number(endAfter),
          repeatType
        );
        const startdate_timeStamp = Math.floor(
          moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000
        );
        const enddate_timeStamp = Math.floor(endDate.toDate().getTime() / 1000);
        if (
          startdate_timeStamp <= timeStamp &&
          enddate_timeStamp >= timeStamp &&
          !(
            enddate_timeStamp == timeStamp &&
            dates.filter((x) => x == startdate_timeStamp).length > 0
          )
        ) {
          dates.push(timeStamp);
        }
        //dates.push(timeStamp);
        allDates.push(timeStamp);
      }
      prevDate = date.format("DD-MM-YYYY");
    }
  }
  const startdate_timeStamp = Math.floor(
    moment(startDate, "DD-MM-YYYY").toDate().getTime() / 1000
  );
  if (endDate !== undefined) {
    let enddate_timeStamp = Math.floor(endDate.toDate().getTime() / 1000);
    dates = dates.filter(
      (dt) => startdate_timeStamp <= dt && enddate_timeStamp >= dt
    );
  }
  const a = dates?.sort((a, b) => b - a);
  return [a, allDates];
};

export const sortNumbers = (arr, overwrite = true) => {
  let returnArr = arr;
  if (!overwrite) {
    returnArr = [...arr];
  }
  return returnArr.sort((a, b) => Number(a) - Number(b));
};
export const permission = {
  doctor: [
    "surgeon",
    "physician",
    "consultant",
    "assistant",
    "paediatrician",
    "nephrologist",
    "neurologist",
    "cardiologist",
    "midwife",
    "matron",
    "radiologist",
    "dermatologist",
    "anaesthetist",
    "orthopaedician",
    "urologist",
    "doctor",
  ],
  nurse: ["nursingofficer", "nurse", "studentnurse"],
  admin: ["patientEntrybtn", "admin"],
  frontdesk: ["frontdesk", "receptionist"],
  technician: ["labtechnician"],
};
export function getRole(db_role) {
  const infos = {
    frontdesk: "frontdesk",
    receptionist: "frontdesk",
    physician: "doctor",
    surgeon: "doctor",
    consultant: "doctor",
    assistant: "doctor",
    paediatrician: "doctor",
    nephrologist: "doctor",
    neurologist: "doctor",
    cardiologist: "doctor",
    medicaladministrator: "admin",
    admin: "admin",
    midwife: "doctor",
    matron: "doctor",
    radiologist: "doctor",
    nursingofficer: "nurse",
    nurse: "nurse",
    studentnurse: "nurse",
    intern: "doctor",
    dermatologist: "doctor",
    anaesthetist: "doctor",
    labtechnician: "technician",
    orthopaedician: "doctor",
    urologist: "doctor",
    doctor: "doctor",
  };
  return infos[db_role?.toLowerCase()?.split(" ")?.join("")] || "doctor";
}

export async function fetchUserInfo(email) {
  try {
    if (!this?.props?.loggedUserInfo?.data?.orgId) {
      const token = localStorage.getItem("token");
      const userInfo = await this.props.GET_ORG_USER_INFO({
        token,
        callRefresh: false,
      });
      const { isError, errMsg } = checkError(userInfo.payload);

      if (isError) {
        if (errMsg === "invalid_token") {
          localStorage.removeItem("role_data");
          localStorage.removeItem("role_name");
          localStorage.removeItem("token");
          window.location = Routes.signIn;
        }
        return;
      }
      localStorage.setItem("token", userInfo.payload?.data?.newToken || token);
      if (!email) {
        email = userInfo.payload?.data?.email;
      }
      const userData = await this.props.SEARCH_PRACTIONER({
        email,
      });
      const permissionA = await checkWithCasbin(["queueSideNav"]);
      const permissionData = permissionA.write.find(
        (d) =>
          d === "appointmentQueueDataN" ||
          d === "appointmentQueueDataD" ||
          d === "appointmentQueueDataF"
      );
      let role = "frontdesk";
      if (permissionData === "appointmentQueueDataN") {
        role = "nurse";
      }
      if (permissionData === "appointmentQueueDataD") {
        role = "doctor";
      }
      const info = {
        orgId: userData?.payload?.data?.[0]?.OrgID?.[0],
        org_ID: userData?.payload?.data?.[0]?.orgDtls?._id ?? "",
        org_name: userData?.payload?.data?.[0]?.orgDtls?.name ?? "",
        name: {
          given: userData?.payload?.data?.[0]?.name?.[0]?.given,
          family: userData?.payload?.data?.[0]?.name?.[0]?.family,
          text: userData?.payload?.data?.[0]?.name?.[0]?.text,
        },
        photo:
          getImgUrl(
            userData?.payload?.data?.[0]?.practitioner?.[0]?.photo?.[0]?.fileid
          ) ||
          getImgUrl(userData?.payload?.data?.[0]?.photo?.[0]?.fileid) ||
          "",
        completeName: makeName(userData?.payload?.data?.[0]?.name?.[0] || {}),
        personId: userData?.payload?.data?.[0]?.practitioner?.[0]?.id,
        practionerId: userData?.payload?.data?.[0]?.practitioner?.[0]?.id,
        practioner_id: userData?.payload?.data?.[0]?.practitioner?.[0]?._id,
        n_practitioner_id:
          userData?.payload?.data?.[0]?.practitioner?.[0]?.practitioner_id,
        role: userData?.payload?.data?.[0]?.practitioner?.[0]?.PractitionerRole[0]?.code[0]?.coding?.[0]?.display?.toLowerCase(),
        internalRole: role,
        org_id: userData?.payload?.data?.[0]?.org_id,
        practitionerRoleLabel:
          userData?.payload?.data?.[0]?.practitioner?.[0]?.PractitionerRole?.[0]
            ?.code[0]?.coding?.[0]?.display ?? "",
        practitionerRoleValue:
          userData?.payload?.data?.[0]?.practitioner?.[0]?.PractitionerRole?.[0]
            ?._id ?? "",
        practitionerRoleId:
          userData?.payload?.data?.[0]?.practitioner?.[0]?.PractitionerRole?.[0]
            ?.id ?? 0,
        orderByLabel:
          userData?.payload?.data?.[0]?.practitioner?.[0]?.name?.[0]?.text +
          " " +
          userData?.payload?.data?.[0]?.practitioner?.[0]?.name?.[0]?.given +
          " " +
          userData?.payload?.data?.[0]?.practitioner?.[0]?.name?.[0]?.family,
      };
      await this.props.LOGGED_USER_INFO(info);
      return info;
    }
    return this?.props?.loggedUserInfo?.data;
  } catch (err) {
    console.error(err?.message);
  }
}

export function getOrderName(val = {}, loggedName) {
  const { accessionNum, userName } = val;
  if (accessionNum) {
    if (accessionNum === loggedName) {
      return accessionNum + " (You)";
    }
    return accessionNum;
  }

  if (userName) {
    if (userName === loggedName) {
      return userName + " (You)";
    }
    return userName;
  }
  return loggedName + " (You)";
}

export async function fetchQueueData() {
  const permission = await checkWithCasbin(["queueSideNav"]);
  const permission_ = await checkWithCasbin(["dashboard"]);
  const permissionData = permission.write.find(
    (d) =>
      d === "appointmentQueueDataN" ||
      d === "appointmentQueueDataD" ||
      d === "appointmentQueueDataF"
  );
  const info = getUserInfo();
  const userData = await fetchUserInfo.bind(this)(info.email);
  const specialtyId = await this.props?.GET_SPECIALITY_ID_BY_PRCTITIONER_ID({
    practitionerId: userData?.practionerId,
  });
  const isDoctor = (await permission_?.write?.indexOf("doctorQueue")) > -1;
  const isNurse = (await permission_?.write?.indexOf("nurseQueue")) > -1;
  const isFrontDesk =
    (await permission_?.write?.indexOf("frontDeskQueue")) > -1;

  const data = await this.props.QUEUE_MANAGEMENT({
    start: getUtcTime(moment().startOf("day")),
    end: getUtcTime(moment().endOf("day")),
    OrgID: userData?.orgId,
    login: userData?.practionerId,
    specialtyId: specialtyId?.payload?.data?.result ?? 0,
    isDoctor: isDoctor,
    isNurse: isNurse,
    isFrontDesk: isFrontDesk,
    nameOfOrg: localStorage.getItem("nameOfOrg"),
  });
  const { isError } = checkError(data.payload);
  if (isError) {
    this.props.alert.setSnack({
      open: true,
      severity: AlertProps.severity.error,
      msg: "Unable to fetch the Queue's.",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.right,
    });
  } else {
    if (Array.isArray(data?.payload?.data)) {
      const appInfo = JSON.parse(JSON.stringify(data.payload.data));
      const [returnData, displayData, onHoldData, completedData, everyData] =
        makeQueueData(permissionData, appInfo, userData);

      this.props.queueManagement.setAllData(everyData);
      this.props.queueManagement.setData(returnData);
      this.props.queueManagement.setDisplayData(displayData);
      this.props.queueManagement.setOnHoldData(onHoldData);
      this.props.queueManagement.setPreviousData(completedData);
      console.log(
        "displayData in fetc",
        displayData,
        displayData.map((v) => v.name),
        "every",
        everyData,
        everyData.map((v) => v.name)
      );
    }
  }
  return;
}

const getStartEndTime = () => {
  // const time = moment("02-07-2021 02:00 PM", "DD-MM-YYYY hh:mm a");
  const time = moment();
  const startTime = round(time, moment.duration(30, "minutes"), "round").unix();
  const endTime = round(time, moment.duration(30, "minutes"), "round")
    .add(90, "m")
    .unix();
  return {
    startTime,
    endTime,
  };
};

export async function createFile(url, type, name) {
  let response = await fetch(url);
  let data = await response.blob();
  let metadata = {
    type: type,
  };
  let file = new File([data], `${name}.${type.split("/")[1]}`, metadata);
  let Url = ToBase64(file);
  return Url;
}

export const makeQueueData = (role, data, isInitial = true) => {
  debugger;
  const nurseTriagerequired = localStorage.getItem("nurseTriagerequired");
  const { startTime, endTime } = getStartEndTime();
  const displayData = [];
  const onHoldData = [];
  const completedData = [];
  let count = 0;
  const allData = [];
  debugger;
  let returnData = data.map((app, idx) => {
    debugger;
    if (app.status || app.comment === "completed") {
      if (role === "appointmentQueueDataF") {
        if (
          nurseTriagerequired === "true"
            ? app.comment !== "completed" &&
              app.comment !== "completed_nurse" &&
              app.comment !== "onhold_nurse"
            : app.comment !== "completed_assemble" &&
              app.comment !== "onhold_nurse"
        ) {
          if (isInitial) {
            const {
              id,
              start,
              Person,
              encounter,
              comment,
              appointmentType,
              consultationMode,
            } = app;
            const info = {
              appointmentType,
              consultationMode,
              token: id,
              comment,
              name: makeName(Person?.[0]?.HumanName?.[0]),
              isPatient: (Person?.[0]?.Patient || []).length > 0,
              personId: Person?.[0]?.Id || 0,
              patient_id: Person?.[0]?._id || "",
              patientId: Person?.[0]?.Patient?.[0]?.id || 0,
              pId: Person?.[0]?.Patient?.[0]?._id || "",
              mrn: Person?.[0]?.Patient?.[0]?.alias || "",
              role: "Consultation",
              encounter: encounter?.[0]?._key || "",
              encounter_id: encounter?.[0]?._id || "",
              encounter_status: encounter?.[0]?.status?.display || "",
              encounterId: encounter?.[0]?.id || "",
              showBringtoTop:
                comment === "onhold" || comment === "completed_assemble"
                  ? false
                  : true,
              profile_pic:
                getImgUrl(Person?.[0]?.photo?.[0]?.fileid) ||
                getImgUrl(Person?.[0]?.Patient?.[0]?.photo?.[0]?.fileid) ||
                "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
              isEmergency: false,
              start: utcTOLocal(start, "hh:mm a"),
              startTime: start,
              info: app,
              mobileNo:
                Person?.[0]?.telecom?.find(
                  (tel) => tel.system === "Phone" && tel.use === "Mobile"
                )?.value || "",
              index: idx,
              status: true,
              type: "frontdesk",
            };
            if (comment === "onhold") {
              onHoldData.push(info);
            } else if (
              nurseTriagerequired === "true"
                ? comment === "completed_assemble"
                : comment === "completed_nurse" || comment === "completed"
            ) {
              completedData.push(info);
              allData.push(info);
              return undefined;
            } else if (startTime <= start) {
              if (
                nurseTriagerequired === "true"
                  ? displayData.length < 2 &&
                    comment !== "onhold" &&
                    comment !== "completed_assemble"
                  : displayData.length < 2 &&
                    comment !== "onhold" &&
                    comment !== "completed_nurse"
                //&&
                //comment !== "completed"
              ) {
                displayData.push(info);
              }
            }
            if (displayData.length === 1) {
              info.showBringtoTop = false;
            }
            allData.push(info);
            return info;
          } else {
            const { startTime: start, comment } = app;
            if (comment === "onhold") {
              onHoldData.push(app);
            } else if (
              nurseTriagerequired === "true"
                ? comment === "completed_assemble"
                : comment === "completed_nurse" || comment === "completed"
            ) {
              completedData.push(app);
              return undefined;
            } else if (startTime <= start) {
              if (
                nurseTriagerequired === "true"
                  ? displayData.length < 2 &&
                    comment !== "onhold" &&
                    comment !== "completed_assemble"
                  : displayData.length < 2 &&
                    comment !== "onhold" &&
                    comment !== "completed_nurse"
                //&&
                //comment !== "completed"
              ) {
                displayData.push(app);
              }
            }
            if (displayData.length === 1) {
              app.showBringtoTop = false;
            } else if (
              nurseTriagerequired === "true"
                ? !(comment === "onhold" || comment === "completed_assemble")
                : !(
                    comment === "onhold" ||
                    comment === "completed_nurse" ||
                    comment === "completed"
                  )
            ) {
              app.showBringtoTop = true;
            }
            return app;
          }
        }
        return undefined;
      } else if (role === "appointmentQueueDataN") {
        if (
          app.comment === "completed_assemble" ||
          app.comment === "onhold_nurse" ||
          app.comment === "completed_nurse" ||
          app.comment === "completed"
        ) {
          if (isInitial) {
            const {
              id,
              start,
              Person,
              comment,
              encounter,
              appointmentType,
              consultationMode,
            } = app;
            const info = {
              appointmentType,
              consultationMode,
              token: id,
              comment,
              name: makeName(Person?.[0]?.HumanName?.[0]),
              isPatient: (Person?.[0]?.Patient || []).length > 0,
              personId: Person?.[0]?.Id || 0,
              patient_id: Person?.[0]?._id || "",
              patientId: Person?.[0]?.Patient?.[0]?.id || 0,
              pId: Person?.[0]?.Patient?.[0]?._id || "",
              role: Person?.[0]?.Patient?.[0]?.alias || "",
              mrn: Person?.[0]?.Patient?.[0]?.alias || "",
              encounter: encounter?.[0]?._key || "",
              encounter_id: encounter?.[0]?._id || "",
              encounter_status: encounter?.[0]?.status?.display || "",
              encounterId: encounter?.[0]?.id || "",
              showBringtoTop:
                comment === "onhold_nurse" ||
                comment === "completed_nurse" ||
                comment === "completed" ||
                count <= 1
                  ? false
                  : true,
              profile_pic:
                getImgUrl(Person?.[0]?.photo?.[0]?.fileid) ||
                getImgUrl(Person?.[0]?.Patient?.[0]?.photo?.[0]?.fileid) ||
                "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
              isEmergency: false,
              start: utcTOLocal(start, "hh:mm a"),
              startTime: start,
              info: app,
              mobileNo:
                Person?.[0]?.telecom?.find(
                  (tel) => tel.system === "Phone" && tel.use === "Mobile"
                )?.value || "",
              index: idx,
              status: true,
              type: "nurse",
              patientPriority: {
                priority: {
                  value: encounter?.[0]?.priority?.display,
                  label: encounter?.[0]?.priority?._id,
                },
                reason: {
                  value: encounter?.[0]?.priorityreason?.ReasonDesc,
                  label: encounter?.[0]?.priorityreason?._id,
                },
              },
              highPriority: false,
            };
            if (comment === "onhold_nurse") {
              onHoldData.push(info);
            } else if (
              comment === "completed_nurse" ||
              comment === "completed"
            ) {
              completedData.push(info);
              allData.push(info);
              return undefined;
            }
            if (displayData.length < 2 && comment === "completed_assemble") {
              displayData.push(info);
            }
            if (displayData.length === 1) {
              info.showBringtoTop = false;
            } else if (
              !(
                comment === "onhold_nurse" ||
                comment === "completed_nurse" ||
                comment === "completed"
              )
            ) {
              info.showBringtoTop = true;
            }
            allData.push(info);
            return info;
          } else {
            const { comment } = app;
            if (comment === "onhold_nurse") {
              onHoldData.push(app);
            } else if (
              comment === "completed_nurse" ||
              comment === "completed"
            ) {
              completedData.push(app);
              return undefined;
            }
            if (displayData.length < 2 && comment === "completed_assemble") {
              displayData.push(app);
            }
            if (displayData.length === 1) {
              app.showBringtoTop = false;
            } else if (
              !(
                comment === "onhold_nurse" ||
                comment === "completed_nurse" ||
                comment === "completed"
              )
            ) {
              app.showBringtoTop = true;
            }
            return app;
          }
        }
        return undefined;
      } else if (role === "appointmentQueueDataD") {
        if (
          app.comment === "completed_nurse" ||
          app.comment === "onhold_doctor" ||
          app.comment === "completed"
        ) {
          if (isInitial) {
            const {
              id,
              start,
              Person,
              comment,
              encounter,
              appointmentType,
              consultationMode,
            } = app;
            const info = {
              appointmentType,
              consultationMode,
              token: id,
              comment,
              name: makeName(Person?.[0]?.HumanName?.[0]),
              isPatient: (Person?.[0]?.Patient || []).length > 0,
              personId: Person?.[0]?.Id || 0,
              patient_id: Person?.[0]?._id || "",
              patientId: Person?.[0]?.Patient?.[0]?.id || 0,
              pId: Person?.[0]?.Patient?.[0]?._id || "",
              role: Person?.[0]?.Patient?.[0]?.alias || "",
              mrn: Person?.[0]?.Patient?.[0]?.alias || "",
              encounter: encounter?.[0]?._key || "",
              encounterId: encounter?.[0]?.id || "",
              encounter_status: encounter?.[0]?.status?.display || "",
              encounter_id: encounter?.[0]?._id || "",
              showBringtoTop:
                comment === "onhold_doctor" ||
                comment === "completed" ||
                count <= 1
                  ? false
                  : true,
              profile_pic:
                getImgUrl(Person?.[0]?.photo?.[0]?.fileid) ||
                getImgUrl(Person?.[0]?.Patient?.[0]?.photo?.[0]?.fileid) ||
                "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
              isEmergency: false,
              start: utcTOLocal(start, "hh:mm a"),
              startTime: start,
              info: app,
              mobileNo:
                Person?.[0]?.telecom?.find(
                  (tel) => tel.system === "Phone" && tel.use === "Mobile"
                )?.value || "",
              index: idx,
              status: true,
              type: "doctor",
            };
            if (comment === "onhold_doctor") {
              onHoldData.push(info);
            } else if (comment === "completed") {
              completedData.push(info);
              allData.push(info);
              return undefined;
            }
            if (displayData.length < 2 && comment === "completed_nurse") {
              displayData.push(info);
            }
            if (displayData.length === 1) {
              info.showBringtoTop = false;
            } else if (
              !(comment === "onhold_doctor" || comment === "completed")
            ) {
              info.showBringtoTop = true;
            }
            allData.push(info);
            return info;
          } else {
            const { comment } = app;
            if (comment === "onhold_doctor") {
              onHoldData.push(app);
            } else if (comment === "completed") {
              completedData.push(app);
              return undefined;
            }
            if (displayData.length < 2 && comment === "completed_nurse") {
              displayData.push(app);
            }
            if (displayData.length === 1) {
              app.showBringtoTop = false;
            } else if (
              !(comment === "onhold_doctor" || comment === "completed")
            ) {
              app.showBringtoTop = true;
            }
            return app;
          }
        }
        return undefined;
      }
    }
    return undefined;
  });
  returnData = returnData.filter((d) => !!d);
  return [returnData, displayData, onHoldData, completedData, allData];
};

export async function putOnHold(index = 0, token, status = "onhold") {
  if (isNaN(index)) {
    await updateStatus(status, token, this.props.UPDATE_APPOINTMENT_STATUS);
  } else {
    const masterData = [...this.props.queueManagement.data];
    const newData = [...this.props.queueManagement.displayData];
    const onHoldVal = newData.splice(index, 1);
    await updateStatus(
      status,
      onHoldVal?.[0]?.token,
      this.props.UPDATE_APPOINTMENT_STATUS
    );
    const statusChnage = masterData.find((d) => d.token === onHoldVal[0].token);
    statusChnage.comment = status;
    const newOnHoldData = [
      ...this.props.queueManagement.onHoldData,
      ...onHoldVal,
    ];
    if (newData[0]) {
      const changeData = masterData.find((d) => d.token === newData[0].token);
      changeData.showBringtoTop = false;
      newData.splice(1);
      if (newData.length <= 1) {
        const newQueueData = masterData.find(
          (d) =>
            d.index > newData[0].index &&
            !(d.comment === "onhold" || d.comment === "completed_assemble")
        );
        if (newQueueData) {
          newData.push(newQueueData);
        }
      }
    }
    this.props.queueManagement.setData(masterData);
    this.props.queueManagement.setDisplayData(newData);
    this.props.queueManagement.setOnHoldData(newOnHoldData);
  }
}

export async function removeFromHold(
  index = 0,
  status = "string",
  role,
  roleID = 2,
  loggedUserInfo
) {
  const newOnHoldData = [...this.props.queueManagement.onHoldData];
  const onHoldVal = newOnHoldData.splice(index, 1);
  await updateStatus(
    status,
    onHoldVal[0].token,
    this.props.UPDATE_APPOINTMENT_STATUS
  );
  const data = [...this.props.queueManagement.data];
  data.find((d) => d.token === onHoldVal[0].token).comment = status;
  if (this.props.queueManagement.displayData.length > 0) {
    const mainData = data.find(
      (d) => d.token === this.props.queueManagement.displayData[0]?.token
    );
    if (mainData) {
      mainData.showBringtoTop = true;
    }
  }
  const newDisplayData = [...this.props.queueManagement.displayData];
  newDisplayData.splice(1);
  const newData = [...onHoldVal, ...newDisplayData];
  newData.slice(2);
  this.props.queueManagement.setData(data);
  this.props.queueManagement.setDisplayData(newData);
  this.props.queueManagement.setOnHoldData(newOnHoldData);

  if (role === "nurse" || role === "doctor") {
    await this.props.HIGH_PRIORITY_PATIENT({
      _role: roleID,
      practionerId: loggedUserInfo.data.practionerId,
      org_id: loggedUserInfo.data.org_id,
      startDate: getUtcTime(moment().startOf("day")),
      endDate: getUtcTime(moment().endOf("day")),
    });
  }
}

export const getTimeSlot = (date, type = "date", isUpdate = false) => {
  const currentTime = new Date();
  let comparedate = date;
  if (type === "unix") {
    comparedate = convertTime(date, type, "DD-MM-YYYY");
  }
  let time = 0;
  if (!date) {
    comparedate = this.state.date;
  }
  if (moment().format("DD-MM-YYYY") === comparedate || isUpdate) {
    let currentHour = currentTime.getHours();
    if (isUpdate) {
      currentHour = new Date(date * 1000).getHours();
    }
    if (currentHour <= 12) {
      time = 0;
    } else if (currentHour > 12 && currentHour <= 15) {
      time = 1;
    } else if (currentHour > 15 && currentHour <= 18) {
      time = 2;
    } else {
      time = 3;
    }
  }
  return time;
};

export const getTimeString = (hour, minutes) => {
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (hour === 0) {
    return `12:${minutes} AM`;
  }
  if (hour === 12) {
    return `12:${minutes} PM`;
  }
  if (hour <= 11) {
    if (hour < 10) {
      hour = `0${hour}`;
    }
    return `${hour}:${minutes} AM`;
  }
  return `${hour - 12 < 10 ? "0" : ""}${hour - 12}:${minutes} PM`;
};

export const findAge = (dates) => {
  dates = moment(dates, "DD-MM-YYYY").format("MM-DD-YYYY");
  const days = moment().diff(dates, "days");
  const months = moment().diff(dates, "months");
  const years = moment().diff(dates, "years");
  if (days <= 90) {
    return days + ` ${days <= 1 ? "Day" : "Days"}`;
  } else if (days > 90 && days < 365) {
    return months + " months";
  } else if (days >= 365) {
    return years + ` ${years <= 1 ? "Yr" : "Yrs"}`;
  }
};

export async function updateStatus(
  status,
  appointmentId,
  updateFn,
  isCompleted
) {
  let data;
  if (updateFn) {
    data = await updateFn({
      appointmentId,
      status,
      isCompleted,
    });
  } else {
    data = await this.props.UPDATE_APPOINTMENT_STATUS({
      appointmentId,
      status,
      isCompleted,
    });
  }
  const { isError } = checkError(data?.payload);
  if (isError) {
    return false;
  }
  return true;
}

export function pushToTop(listToken) {
  if (listToken) {
    const data = [...this.props.queueManagement.data];
    let index = -1;
    data.map((val, i) => {
      if (val.token === listToken) {
        index = i;
      }
    });
    if (index !== -1) {
      data[index].showBringtoTop = false;
      const mainData = data.find(
        (d) => d.token === this.props.queueManagement.displayData[0]?.token
      );
      if (mainData) {
        mainData.showBringtoTop = true;
      }
      const newDisplayData = [[...data][index]];
      let displayData = [...this.props.queueManagement.displayData];
      displayData = displayData.filter(
        (d) => d.token !== newDisplayData[0].token
      );
      displayData.splice(1);
      const newData = [...newDisplayData, ...displayData];
      newData.slice(2).forEach((d) => {
        const findData = data.find((da) => da.token === d.token);
        findData.showBringtoTop = false;
      });
      this.props.queueManagement.setData(data);
      this.props.queueManagement.setDisplayData(newData);
    }
  }
}

export const checkName = (name) => /^[a-z .'-]+$/i.test(name);

export const getImgUrl = (fileId) =>
  fileId ? `${Config.downloadUrl}${fileId}` : "";

//up arrow and down arrow to select
export const keySelection = (divWrap, childName, selectionName, classlist) => {
  if (divWrap?.getElementsByClassName(selectionName)?.length === 0) {
    divWrap.getElementsByClassName(classlist)[0].classList.add(selectionName);
  } else {
    let child =
      childName === "next"
        ? divWrap?.getElementsByClassName(selectionName)[0]?.nextElementSibling
        : divWrap.getElementsByClassName(selectionName)[0]
            .previousElementSibling;
    if (child) {
      child.classList.add(selectionName);
      child.scrollIntoViewIfNeeded(false);
      divWrap
        .getElementsByClassName(selectionName)
        [childName === "next" ? 0 : 1].classList.remove(selectionName);
    } else {
      divWrap.scrollTo({
        top: childName === "next" ? divWrap.scrollHeight : 0,
      });
    }
  }
};

// Check the versions
export let semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA ? versionA.split(/\./g) : ["0", "0", "0"];
  const versionsB = versionB ? versionB.split(/\./g) : ["0", "0", "0"];

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

// Refresh the cache by clearing the cache and reload
export const refreshCacheAndReload = async () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    const names = await caches.keys();
    const promArr = [];
    for (const name of names) {
      promArr.push(caches.delete(name));
    }
    await Promise.all(promArr);
  }
  // delete browser cache and hard reload
  window.location.reload(true);
};

// AVC Meeting props
export const DrawerMeetingProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};

// Vitala view json
export const processData = (data = {}) => {
  const component = data?.component || [];
  let obj = {};
  component.forEach((c) => {
    const newObj = {
      [c.obscode]: `${c.obsvalue}`,
      [`${c.obscode}error`]: "true",
      [`${c.obscode}uom`]: c.abnormalmsg ? JSON.parse(c.abnormalmsg) : "",
      [`${c.obscode}normalorabnormal`]: c.isabnormal ? "true" : "false",
    };
    obj = { ...newObj, ...obj };
  });
  return obj;
};

export const trimText = (text = "", length = 20) => {
  if (typeof text !== "string") {
    return "";
  }
  return text.length > length ? text.slice(0, length - 3) + "..." : text;
};

export const profileFirstName = (profileName) => {
  const myArray = profileName.split(".");
  return myArray[1];
};
export const getCalculatedAge = (date) => {
  const duration = moment().diff(moment(date, "DD/MM/YYYY"), "years", true);
  const years = Math.floor(duration);
  return years === 1 ? `${years}Yr` : `${years}Yrs`;
};

export const isIntegerValue = (value) => {
  const integerPattern = /^[0-9]*$/;
  return integerPattern.test(value);
};

// for generating a file on uploading a url in bas64
export const dataUrlToFile = async (dataUrl, fileName, mimeType) => {
  const res = await fetch(dataUrl);
  const blob = res.data;
  return new File([blob], fileName, { type: mimeType });
};

export const mobileNumberValidation = (mobileNumber, countryCode) => {
  let c_code = countryCode;
  if (countryCode) {
    if (countryCode?.includes("+")) {
      c_code = countryCode;
    } else {
      c_code = "+".concat("", countryCode);
    }
  } else {
    c_code = "+00";
  }
  const code = countryCode_?.filter((cc) => cc.dial_code === c_code.toString());
  if (code.length > 0) {
    return isValidPhoneNumber(mobileNumber?.toString(), code?.[0]?.code);
  } else {
    return "Invalid Country code";
  }
};

export const getCurrentMinTime = (date) => {
  if (date === null || date?.getDate() === new Date().getDate()) {
    return setHours(
      setMinutes(new Date(), new Date().getMinutes()),
      new Date().getHours()
    );
  } else {
    return setHours(setMinutes(new Date(), 0), 0);
  }
};

export const getStartEndMinTime = (date, type, baseStartDate, baseEndDate) => {
  if (type === "startDate") {
    if (date.substring(0, 10) === baseStartDate.substring(0, 10)) {
      const newDate = moment(new Date()).format("YYYY-MM-DD,hh:mm A");
      const setDates = newDate.replace(",", " ");
      const hours = new Date(setDates).getHours();
      const minutes = new Date(setDates).getMinutes();
      return setHours(setMinutes(new Date(), minutes), hours);
    } else {
      return setHours(setMinutes(new Date(), 0), 0);
    }
  } else {
    if (
      baseEndDate.substring(0, 10) ===
      (date.length > 0 ? date.substring(0, 10) : baseEndDate.substring(0, 10))
    ) {
      const newDate = moment(new Date())
        .format("YYYY-MM-DD,hh:mm A")
        .replace(",", " ");
      const newDateUnix = moment(newDate, "YYYY-MM-DD hh:mm A").unix();

      const minEndTime = baseEndDate.replace(",", " ");
      const minEndTimeUnix = moment(minEndTime, "YYYY-MM-DD hh:mm A").unix();
      const hours = new Date(
        newDateUnix > minEndTimeUnix ? newDate : minEndTime
      ).getHours();
      const minutes = new Date(
        newDateUnix > minEndTimeUnix ? newDate : minEndTime
      ).getMinutes();
      return setHours(setMinutes(new Date(minEndTime), minutes), hours);
    } else {
      return setHours(setMinutes(new Date(), 0), 0);
    }
  }
};

export const getDateAndTime = (date, noDate, monthType) => {
  if (date && !noDate && !monthType) {
    return moment.unix(date).format("DD/MM/YYYY HH:mm");
  } else if (date && noDate && !monthType) {
    return moment.unix(date).format("DD/MM/YYYY");
  } else if (date && noDate && monthType) {
    return moment.unix(date).format("MMM/YYYY");
  } else {
    return "";
  }
};

export const getUnixCodeDate = (date) => {
  if (date) {
    return moment(`${date}`, "DD/MM/YYYY hh:mm").unix();
  } else {
    return "";
  }
};
export const AxiosCall = async (method, url, params, header) => {
  try {
    let config = {
      method: method,
      url: `${url}`,
      headers: {
        "Content-Type": "application/json",
        ...(header && { ...header }),
      },
      data: params,
    };
    let response = await axios(config);
    return response.data;
  } catch (error) {
    return error;
  }
};
