import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Routes } from "../../router/routes";
import { withStyles } from "@material-ui/core";
import { Div, Text, Row, Button, Col, Divider } from "qdm-component-library";
import {
  Allergies,
  PatientCareTypeCard,
  PatientStatusCard,
  TopHeader,
} from "../../components";
import { PatientInfoCard } from "../../components";
import { ActionItems } from "./actionItems";
import { MenuList } from "./menuList";
import PatientChart from "./patientChart";
import WidgetsList from "./widgets";
import { AllergyForm } from "./allergyFlows";
import "./styles.css";
import { DiagnonsisForm } from "./diagnosisFlow";
import { CheifComplaintsForm } from "./cheifComplaintsFlow";
import { MedicationForm } from "./medicationFlows";
import { LabOrderForm } from "./labOrderFlow";
import SignOffCard from "./signOffComp/index";
import { withAllContexts } from "../../HOCs";
import {
  getUserInfo,
  CC_Json_Generator,
  _calculateAge,
  DIAGNOSIS_Json_Generator,
  Treatment_Json_Generator,
  Rad_Json_Generator,
  updateStatus,
  AlertProps,
  LabOrder_Json_Generator,
  fetchUserInfo,
  getReccuranceDates,
  utcTOLocal,
  getUtcTime,
  UIColor,
  getCalculatedAge,
} from "../../utils";
import { AlertContext } from "../../contexts";
import { checkWithCasbin } from "../../utils/permissonHandling";

import moment from "moment";
import { RadiologyOrderForm } from "./radiologyFlow";
import { TreatmentProcedureForm } from "./treatmentProcedureFlow";
import Footer from "./footer";
import FollowupFotter from "./followupfooter";
import DoccForm from "./documentation/form";
import Miniwidget from "./miniwidget";
import Axios from "axios";

const materialStyles = (theme) => ({});
const info = getUserInfo();

class PatientDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      followup: false,
      followup_number: null,
      followup_type: { value: "", label: "" },
      selectOption: {},
      followup_reason: { label: "", value: "" },
      fin_data: [],
      reasonData: [],
      // patientChartList: "patient_chart",
      patientChartList: "action_items",
      // formsList: ['docc_ui'],
      formsList: null,
      encounterData: {},
      permissions: {},
      formComponet: {
        open: false,
        id: null,
        type: null,
        // type: 'docc_form',
      },
      signOff: false,
      fetchUser: {},
      followup: [],
      dialysisData: {},
      footerDialsisData: {},
    };
    this.sign_OffFun = this.sign_OffFun.bind(this);
    this.saveCheifComp = this.saveCheifComp.bind(this);
    this.saveDiagnosis = this.saveDiagnosis.bind(this);
    this.saveTreatment = this.saveTreatment.bind(this);
    this.saveLabOrders = this.saveLabOrders.bind(this);
    this.saveRadiology = this.saveRadiology.bind(this);
    this.fetchUserInfo = fetchUserInfo.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.updateStatus = updateStatus.bind(this);
    this.readDialysisWithEncouterid =
      this.readDialysisWithEncouterid.bind(this);
  }

  styles = {
    columnStyle: {
      padding: 10,
      backgroundColor: UIColor.greyBackground.color,
      //borderRadius: 5,
      height: "75vh",
    },
    divider: {
      margin: "5px 0px",
      border: "0.5px dashed #EEEEEE",
      borderRadius: 10,
    },
    infoHeader: {
      position: "sticky",
      top: 0,
      // background: "#f8f8f8",
      zIndex: 100,
      padding: "14px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      background: "#fff",
      // marginBottom: "20px"
    },
    background: {
      background: UIColor.greyBackground.color,
      paddingTop: "20px",
    },
    rightSideCol: {
      height: "78vh",
      overflow: "auto",
      backgroundColor: "#F9F9F9",
      borderLeft: "1px solid #E0E0E0",
      padding: 0,
    },
  };

  async componentDidMount() {
    const { patientId, encounterId } = this.props?.location?.state;
    let dataList = await checkWithCasbin(["patientChart"]);
    this.setState({
      permissions: dataList,
    });
    const followup = await this.props.FOLLOW_UP_READ({
      encounter_id:
        this.props.encounterData?.data?.eId ||
        this.props?.location?.state.encounter_id,
      // encounter_id: 'Encounter/10225'
    });
    //CALL REASON MASTER
    await this.props.FOLLOWUP_REASON();
    await this.props.FOLLOWUP_UOM();
    if (followup?.payload?.data?.[0]?.activestatus) {
      this.setState({
        ...this.state,
        followup_reason: this.props.reason_?.data?.find?.(
          (d) => d?.value === followup?.payload?.data?.[0]?.followupreason
        ) || { label: "", value: "" },
        followup_number: followup?.payload?.data?.[0]?.followupdurval
          ? JSON.stringify(followup?.payload?.data?.[0]?.followupdurval)
          : "",
        followup_type: followup?.payload?.data?.[0]?.followupduruom
          ? {
            value: followup?.payload?.data?.[0]?.followupduruom,
            label: followup?.payload?.data?.[0]?.followupduruom,
          }
          : {},
        followup: followup,
      });
    }
    if (this.props.GET_FINANCIAL) {
      const fin_data = await this.props.GET_FINANCIAL({ patientId });
      if (fin_data?.payload?.data) {
        const fin_DATA = fin_data?.payload?.data?.map((v) => {
          const setdata = v?.payersprioritycoverage?.[0];
          return {
            pic: "images/Group 90507.svg",
            name: null,
            care_type: setdata?.guarantorid,
            title_name: setdata?.receivertype + ", " + setdata?.policyid,
          };
        });
        this.setState({
          selectOption: fin_DATA[0],
          fin_data: fin_DATA,
        });
      }
    }

    const fetchUser = await this.fetchUserInfo();
    this.setState({
      ...this.state,
      fetchUser: fetchUser,
    });

    let encounterData = await this.props.GET_ENCOUNTER({ id: encounterId });
    this.setState({ encounterData: encounterData?.payload?.data ?? {} });
    if (encounterData?.payload?.data?.pID) {
      let d = await this.props.PATIENT_BANNER({
        id: encounterData?.payload?.data?.pID,
      });
    }
    if (encounterData?.payload?.data?.patientid) {
      let d_ = await this.props.ENCOUNTER_BANNER({
        patientId: encounterData?.payload?.data?.patientid,
        facilityId: "Organization/10002",
      });
    }

    await this.getAllMasters();

    await this.props.DIAGNOSIS_READ({
      encounter_id: this.props.encounterData?.data?.eId,
    });

    await this.props.ENCOUNTER_BASED_CC({
      encounter_id: this.props.encounterData?.data?.eId,
    });

    await this.props.ENCOUNTER_BASED_A({
      encounter_id: this.props.encounterData?.data?.eId,
    });

    let datatreatment = await this.props.TRAEMENT_READ({
      encounter_id: this.props.encounterData?.data?.eId,
    });
    let treatment = this.props.treatment;
    await treatment.setTreatment({
      ...treatment,
      list: JSON.parse(JSON.stringify(datatreatment?.payload?.data)),
    });
    let labordersData = await this.props.LABORDER_READ({
      encounter_id: this.props.encounterData?.data?.eId,
    });

    let labOrder = this.props.LabOrder;
    await labOrder.setLabOrder({
      ...labOrder,
      list: JSON.parse(JSON.stringify(labordersData?.payload?.data)),
    });

    let data = await this.props.DOCC_READ({
      // encounter_id: this.props.encounterData?.data?.eId,
      patient_id: this.props?.location?.state.pId,
    });

    let { Doccument, setDoccument } = this.props.Doccument;
    setDoccument({
      ...Doccument,
      list: JSON.parse(JSON.stringify(data?.payload?.data)),
    });

    await this.props.TREATMENT_ORDER_TYPE();
    await this.props.TREATMENT_ORDER_CATALOG();
    await this.props.TREATMENT_PROCEDURE_PRIORITY();
    await this.props.TREATMENT_PROCEDURE_NATURE();
    await this.props.TREATMENT_PROCEDURE_MODE();
    await this.props.TREATMENT_DIAGNOSIS({
      id: this.props.encounterData?.data?.eId,
    });
    await this.props.TREATMENT_INDICATE();
    await this.props.TREATMENT_INSTRUCTION();
    await this.props.TREATMENT_ORGANIZATION({
      id: this.state.fetchUser?.orgId,
    });
    await this.props.TREATMENT_DEP_INSTRUCTION();
    await this.props.TREATMENT_PRE_INSTRUCTION();

    // Radiology fetch
    let datatradiology = await this.props.RAD_READ({
      encounter_id: this.props.encounterData?.data?.eId,
    });
    let radiology = this.props.radiology;
    await radiology.setRadiology({
      ...radiology,
      list: JSON.parse(JSON.stringify(datatradiology?.payload?.data)),
    });

    await this.props.RAD_ORDER_TYPE();
    await this.props.RAD_ORDER_CATALOG();
    await this.props.RAD_PRIORITY();
    await this.props.RAD_NATURE();
    await this.props.RAD_MODE();
    await this.props.RAD_DIAGNOSIS({
      encounter_id: this.props.encounterData?.data?.eId,
    });
    // console.log(this.state.fetchUser?.orgId + " this.state.fetchUser?.orgId");
    await this.props.RAD_INDICATE();
    await this.props.RAD_INSTRUCTION();
    await this.props.RAD_ORGANIZATION({
      id: this.state.fetchUser?.orgId,
    });
    await this.props.RAD_PRE_INSTRUCTION();
    await this.props.RAD_DEP_INSTRUCTION();
    await this.readDialysisWithEncouterid();
  }

  async getAllMasters() {
    await this.props.USE_MASTERS();
    await this.props.VERIFICATIONS_MASTERS_DIAGNOSIS();
    const { pId } = this.props?.location?.state;
    await this.props.WORKFLOW_STATUS_MASTER();
    await Promise.all([
      this.props.GET_RECORD_STATUS(),
      this.props.GET_SERVICE_WF_STATUS(),
    ]);

    await this.props.GET_MEDICATIONS({ id: pId });
    // if (data.payload.data?.length > 0) {
    // this.setState({
    //   patientChartList: "patient_chart",
    //   formsList: ["medication"],
    // });
    // this.props.backDrop.setBackDrop({
    //   ...this.props.backDrop,
    //   open: true,
    //   message: "Fetching....",
    // });
    // }
  }

  changeState = (key, val) => {
    this.setState({
      ...this.state,
      [key]: val,
    });
  };
  readDialysisWithEncouterid = () => {
    let data = {
      db_name: process.env.REACT_APP_DB,
      entity: "CA_DialysisVitalsSigns",
      filter: `CA_DialysisVitalsSigns.encounterid == '${this.state.encounterData?._id}'`,
      return_fields: "CA_DialysisVitalsSigns",
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_ARRANGO_URL}/api/read_documents`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };
    Axios(config)
      .then((res) => {
        this.setState({ dialysisData: res?.data?.result?.[0] });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  goBackInsignOff = (key, val) => {

    if(this.props?.location?.state?.isProfileView)
    {
      let data = this.state.encounterData
      this.props.history.push({
				pathname: Routes.PatientDashBoard,
				state: {
					 userId: data?.Patient[0]?.personID,
					 mobileNumber:this.props?.location?.state?.mobileNo,
					patientId:data?.patientid,
					// appointmentId
				},
			});
    }
    else{
      if (
        this.state?.formsList?.includes("follow_up") &&
        !this.state?.followup_number &&
        !this.state?.followup_type?.label
      ) {
        this.state["formsList"].splice(
          this.state["formsList"].indexOf("follow_up"),
          1
        );
      }
      this.setState({
        ...this.state,
        [key]: val,
      });
    }
   
  };

  onEditChart = (id, type) => {
    this.setState({
      ...this.state,
      formComponet: {
        open: !this.state.formComponet.open,
        id: id,
        type: type,
      },
    });
  };

  onEditWidget = (id, type, index) => {
    this.setState({
      ...this.state,
      editIndex: index,
      formComponet: {
        open: true,
        id: id,
        type: type,
      },
    });
  };

  handleClose = (id) => {
    let selectedFormId = [...this.state.formsList];
    let filterId = selectedFormId.filter((val) => val !== id);
    this.changeChartLayer(filterId);
  };

  getCharts = (type) => {
    switch (type) {
      case "action_items":
        return (
          <>
            {/* Action Items contains */}
            <ActionItems />
          </>
        );
      case "patient_chart":
        return (
          <PatientChart
            parent_id={"patientdetails"}
            formsList={this.state.formsList}
            handleClose={this.handleClose}
            onEditChart={this.onEditChart}
            saveCheifComp={this.saveCheifComp}
            saveDiagnosis={this.saveDiagnosis}
            saveAllergy={this.saveAllergy}
            saveTreatment={this.saveTreatment}
            saveLabOrders={this.saveLabOrders}
            saveRadiology={this.saveRadiology}
            saveDoccumentation={this.saveDoccumentation}
            handleOrderSetClose={() => {
              if (this.state.formsList) {
                let formList = [...this.state.formsList];
                const index = formList.indexOf("orders_sets");
                if (index > -1) {
                  formList.splice(index, 1);
                  this.changeChartLayer(formList);
                }
              }
            }}
            {...this.props}
            patientgetData={this.state.encounterData}
            dialysisData={this.state.dialysisData}
            getDialysisData={this.readDialysisWithEncouterid}
            orgdetails={this.state.fetchUser}
          />
        );
      default:
        break;
    }
  };
  handleInputChange = (e, name, limit) => {
    if (name === "followup_number") {
      if (e === "0") {
        this.setState({
          ...this.state,
          followup_number: "",
        });
        return;
      }
    }
    if (limit) {
      if (limit < e.length) {
        return;
      }
    }
    this.setState({
      ...this.state,
      [name]: e,
    });
  };
  tabHandle = (val, name) => {
    this.setState({
      ...this.state,
      [name]: val,
    });
  };
  handleselectChange = (name, val) => {
    this.setState({
      ...this.state,
      [name]: val,
    });
  };

  changeChartLayer = (forms) => {
    this.setState({
      ...this.state,
      patientChartList: forms?.length > 0 ? "patient_chart" : "action_items",
      formsList: JSON.parse(JSON.stringify(forms)),
      formComponet: {
        open: false,
        id: null,
        type: null,
      },
    });
  };

  followupHnadle = () => {
    let arr = this.state.formsList;

    let index = arr.indexOf("follow_up");
    if (index > -1) {
      arr.splice(index, 1);
    }
    this.setState({
      ...this.state,
      formsList: arr,
      followup_number: null,
      followup_type: { value: "", label: "" },
      followup_reason: { value: "", label: "" },
    });
    if (this.state?.followup?.payload?.data?.length > 0) {
      this.props.SET_FOLLOWUP_DELETE({
        id: this.state.followup.payload.data[0]._key,
      });
    }
  };

  goBack = () => {
    this.props.history.push(Routes.dashboard);
  };

  onCancel = () => {
    this.setState({
      ...this.state,
      formComponet: {
        open: false,
        id: null,
        type: null,
      },
    });
  };

  getFormTypeRender = (type) => {
    switch (type) {
      case "allergy_form":
        return (
          <AllergyForm
            parent_id={"patient_details"}
            onCancel={this.onCancel}
            saveAllergy={this.saveAllergy}
          />
        );
      case "diagnonsis_form":
        return (
          <DiagnonsisForm
            parent_id={"patient_details"}
            saveDiagnosis={this.saveDiagnosis}
            onCancel={this.onCancel}
          />
        );
      case "cheif_complaints_form":
        return (
          <CheifComplaintsForm
            parent_id={"patient_details"}
            editIndex={this.state.editIndex}
            saveCheifComp={this.saveCheifComp}
            onCancel={this.onCancel}
            formComponet={this.state.formComponet}
          />
        );
      case "lab_order_form":
        return (
          <LabOrderForm
            parent_id={"patient_details"}
            onCancel={this.onCancel}
            saveLabOrders={this.saveLabOrders}
            patientgetData={this.state.encounterData}
            info={info}
            fetchUser={this.state.fetchUser}
            {...this.props}
          />
        );
      case "medication_form":
        return (
          <MedicationForm
            parent_id={"patient_details"}
            onCancel={this.onCancel}
            fetchUser={this.state.fetchUser}
          />
        );
      case "rad_order_form":
        return (
          <RadiologyOrderForm
            parent_id={"patient_details"}
            onCancel={this.onCancel}
            saveRadiology={this.saveRadiology}
            patientgetData={this.state.encounterData}
            fetchUser={this.state.fetchUser}
            {...this.props}
          />
        );
      case "treatment_procedure_form":
        return (
          <TreatmentProcedureForm
            parent_id={"patient_details"}
            {...this.props}
            onCancel={this.onCancel}
            saveTreatment={this.saveTreatment}
            patientgetData={this.state.encounterData}
            info={info}
            treatment={this.props.treatment}
            fetchUser={this.state.fetchUser}
          />
        );
      case "docc_form":
        return (
          <DoccForm
            parent_id={"patient_details"}
            {...this.props}
            onCancel={this.onCancel}
            saveDoccumentation={this.saveDoccumentation}
            patientgetData={this.state.encounterData}
            patientID={this.props?.location?.state.patientId}
            encounterID={this.props?.location?.state.encounterId}
            info={info}
            pID={this.props?.location?.state?.pId}
            fetchUser={this.state.fetchUser}
            editData={this.state.formComponet}
            personDetails={this.props?.searchPractioner?.data?.[0]}
          />
        );

      default:
        break;
    }
  };

  saveCheifComp = async (list, editIndex) => {
    return new Promise(async (resolve, reject) => {
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: true,
        message: !editIndex ? "Adding New Chief Complaints" : "Updating",
      });
      let personDetails = this?.props?.searchPractioner?.data?.[0];
      let searchPractioner = personDetails?.practitioner?.[0];
      let data = CC_Json_Generator(
        this?.props,
        list,
        searchPractioner,
        personDetails,
        this.props.encounterData
      );
      if (data?.length > 0) {
        await this.props.SAVE_CHEIF_COMPAILANTS({ list: data });
        let cheifComplaints = this.props.cheifComplaints;
        if (!editIndex && editIndex !== 0) {
          let data = await this.props.ENCOUNTER_BASED_CC({
            encounter_id: this.props.encounterData?.data?.eId,
          });
          let dataList = JSON.parse(
            JSON.stringify({
              ...{
                isNew: true,
                createdName: list.createdName,
                createdAt: list.createdAt,
              },
              ...data?.payload?.data?.[data.payload?.data?.length - 1 ?? 0],
            })
          );
          cheifComplaints.list.push(dataList);
        } else {
          cheifComplaints.list[editIndex] = {
            ...list[0],
            isNew: false,
            isModified: true,
          };
        }
        cheifComplaints.setCheifComplaints({
          ...cheifComplaints,
        });
      }
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: false,
        message: "",
      });
      resolve(true);
    });
  };

  saveTreatment = async (
    list,
    editIndex,
    date,
    isupdate,
    childIds,
    deleteArr
  ) => {
    let status = this.props.recordStatus?.data.find(
      (val) => val.label === "Draft"
    );

    let treatment = this.props.treatment;

    return new Promise(async (resolve, reject) => {
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: true,
        message:
          editIndex === null
            ? "Adding New Treatment & Procedures"
            : "Updating Treatment & Procedures",
      });

      let personDetails = this?.props?.searchPractioner?.data?.[0];
      let searchPractioner = personDetails?.practitioner?.[0];
      let data = Treatment_Json_Generator(
        list,
        this.props.encounterData,
        personDetails,
        searchPractioner
      );

      if (!editIndex) {
        data.forEach((d) => (d.status = status?.value || ""));
      }

      if (data?.length > 0) {
        let dateArr = [];
        if (list?.[0]?.recurrentList?.length > 0) {
          let listDataArr = list[0].recurrentList.map((val) =>
            getUtcTime(val.date)
          );
          dateArr = [listDataArr];
        } else {
          if (
            list?.[0]?.orderCatalog.label &&
            list?.[0]?.dateTime &&
            list?.[0]?.nature.label &&
            list?.[0]?.priority.label &&
            list?.[0]?.recurrentData?.every?.value &&
            list?.[0]?.recurrentData?.every?.periodicity?.value &&
            (list?.[0]?.recurrentData?.endsAfter?.endsAfter ||
              list?.[0]?.recurrentData?.endsAfter?.endsBy) &&
            list?.[0]?.recurrentData?.startsOn.dateTime
          ) {
            dateArr = getReccuranceDates(
              utcTOLocal(
                list?.[0]?.recurrentData?.startsOn?.dateTime,
                "DD-MM-YYYY"
              ), // DD-MM-YYYY
              list?.[0]?.recurrentData?.endsAfter?.endsAfter !== ""
                ? "NoOfDays"
                : "Calendar", // [by => Calendar, on => NoOfDays]
              list?.[0]?.recurrentData?.endsAfter?.endsAfter !== ""
                ? JSON.parse(list?.[0]?.recurrentData?.endsAfter?.endsAfter)
                : utcTOLocal(
                  list?.[0]?.recurrentData?.endsAfter?.endsBy,
                  "DD-MM-YYYY"
                ), // // [by => Date(DD-MM-YYYY), on => number(5)]
              list?.[0]?.recurrentData?.every?.periodicity?.label?.toLowerCase(), // weeks, month, days
              JSON.parse(list?.[0]?.recurrentData?.every?.value || "{}"), // number
              [], // []
              [] // []
            );
          } else {
            dateArr = [];
          }
        }

        var upsertdata;

        if (treatment?.child?.parentOrderId || date) {
          const vals = await this.props.READ_TREATMENT_CHILD({
            list: data,
            Isparent: date
              ? list?.[0]?.editData
              : treatment?.child?.parentOrderId,
            dates: date ? getUtcTime(date) : null,
          });
        } else {
          upsertdata = await this.props.TREATMENT_PROCEDURE_SAVE({
            list: data,
            dateArr: dateArr?.[0],
            isupdate: isupdate ? true : false,
            childIds: childIds,
            deleteArr,
          });
        }

        if (upsertdata?.payload?.data?.validation_error?.[0]?.Errormsg) {
          this.context.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: upsertdata?.payload.data.error,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          this.props.backDrop.setBackDrop({
            ...this.props.backDrop,
            open: false,
            message: "",
          });
          return;
        }
        let Treatmentdata = await this.props.TRAEMENT_READ({
          encounter_id: this.props.encounterData?.data?.eId,
          order_id: upsertdata?.payload?.data?.Result?.[0]?.properties?.doc._id,
        });

        if (!editIndex && editIndex !== 0) {
          // return
          let dataList = JSON.parse(
            JSON.stringify({
              ...{
                isNew: true,
              },
              infoAlertText: list[0]?.infoAlertText ?? "",
              ...Treatmentdata?.payload?.data?.[
              Treatmentdata?.payload?.data?.length - 1 ?? 0
              ],
            })
          );
          treatment.list.push(dataList);
        } else {
          let dataList = JSON.parse(
            JSON.stringify({
              ...{
                isNew: true,
              },
              infoAlertText: list[0]?.infoAlertText ?? "",
              ...Treatmentdata?.payload?.data?.[
              Treatmentdata?.payload?.data?.length - 1 ?? 0
              ],
            })
          );
          var dataedit;
          if (!treatment?.child?.index >= 0) {
            dataedit = editIndex >= 0 ? list[treatment.editIndex] : 0;
            treatment.list[treatment.editIndex] = dataList;
          } else {
            dataedit =
              treatment.editIndex >= 0
                ? treatment.child.index >= 0
                  ? treatment.list[treatment.editIndex].childRecurrent[
                  treatment.child.index
                  ]
                  : 0
                : 0;
            treatment.list[treatment.editIndex].childRecurrent[
              treatment.child.index
            ] = dataList;
          }
        }
        treatment.child = {};
        await treatment.setTreatment({
          ...treatment,
        });
      }

      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: false,
        message: "",
      });
      resolve(true);
    });
  };

  saveLabOrders = async (
    list,
    editIndex,
    date,
    isupdate,
    childIds,
    deleteArr
  ) => {
    let status = this.props.recordStatus?.data.find(
      (val) => val.label === "Draft"
    );

    let treatment = this.props.LabOrder;

    return new Promise(async (resolve, reject) => {
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: true,
        message:
          editIndex === null ? "Adding New LabOrder" : "Updating LabOrder",
      });
      let personDetails = this?.props?.searchPractioner?.data?.[0];
      let searchPractioner = personDetails?.practitioner?.[0];
      let data = LabOrder_Json_Generator(
        list,
        this.props.encounterData,
        personDetails,
        searchPractioner
      );
      if (!editIndex) {
        data.forEach((d) => (d.status = status?.value || ""));
      }
      if (data?.length > 0) {
        let dateArr = [];
        if (list?.[0]?.recurrentList?.length > 0) {
          let listDataArr = list[0].recurrentList.map((val) =>
            getUtcTime(val.date)
          );
          dateArr = [listDataArr];
        } else {
          if (
            list?.[0]?.orderCatalog.label &&
            list?.[0]?.dateTime &&
            list?.[0]?.nature.label &&
            list?.[0]?.priority.label &&
            list?.[0]?.recurrentData?.every?.value &&
            list?.[0]?.recurrentData?.every?.periodicity?.value &&
            (list?.[0]?.recurrentData?.endsAfter?.endsAfter ||
              list?.[0]?.recurrentData?.endsAfter?.endsBy) &&
            list?.[0]?.recurrentData?.startsOn.dateTime
          ) {
            dateArr = getReccuranceDates(
              utcTOLocal(
                list?.[0]?.recurrentData?.startsOn?.dateTime,
                "DD-MM-YYYY"
              ), // DD-MM-YYYY
              list?.[0]?.recurrentData?.endsAfter?.endsAfter !== ""
                ? "NoOfDays"
                : "Calendar", // [by => Calendar, on => NoOfDays]
              list?.[0]?.recurrentData?.endsAfter?.endsAfter !== ""
                ? JSON.parse(list?.[0]?.recurrentData?.endsAfter?.endsAfter)
                : utcTOLocal(
                  list?.[0]?.recurrentData?.endsAfter?.endsBy,
                  "DD-MM-YYYY"
                ), // // [by => Date(DD-MM-YYYY), on => number(5)]
              list?.[0]?.recurrentData?.every?.periodicity?.label?.toLowerCase(), // weeks, month, days
              JSON.parse(list?.[0]?.recurrentData?.every?.value || "{}"), // number
              [], // []
              [] // []
            );
          } else {
            dateArr = [];
          }

          // dateArr?.[0]?.sort((a, b) => (b) - (a))
        }
        // if (treatment?.child?.parentOrderId === treatment?.child?.editData) {
        //   await this.props.ORDER_DELETE({
        //     id: list?.[0].editData
        //   })
        // }
        var upsertdata;
        if (
          // (treatment?.child?.parentOrderId) && (treatment?.child?.IsChild)
          treatment?.child?.parentOrderId ||
          date
        ) {
          const vals = await this.props.READ_LABORDER_CHILD({
            list: data,
            Isparent: date
              ? list?.[0]?.editData
              : treatment?.child?.parentOrderId,
            dates: date ? getUtcTime(date) : null,
          });
        } else {
          upsertdata = await this.props.SET_LAB_ORDERS_SERVICE({
            list: data,
            dateArr: dateArr?.[0],
            isupdate: isupdate ? true : false,
            childIds: childIds,
            deleteArr,
          });
        }

        if (upsertdata?.payload?.data?.validation_error?.[0]?.Errormsg) {
          this.context.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: upsertdata?.payload.data.error,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          this.props.backDrop.setBackDrop({
            ...this.props.backDrop,
            open: false,
            message: "",
          });
          return;
        }
        let Treatmentdata = await this.props.LABORDER_READ({
          encounter_id: this.props.encounterData?.data?.eId,
          order_id: upsertdata?.payload?.data?.Result?.[0]?.properties?.doc._id,
        });
        if (!editIndex && editIndex !== 0) {
          // return
          let dataList = JSON.parse(
            JSON.stringify({
              ...{
                isNew: true,
              },
              infoAlertText: list[0]?.infoAlertText ?? "",
              ...Treatmentdata?.payload?.data?.[
              Treatmentdata?.payload?.data?.length - 1 ?? 0
              ],
            })
          );
          treatment.list.push(dataList);
        } else {
          let dataList = JSON.parse(
            JSON.stringify({
              ...{
                isNew: true,
              },
              infoAlertText: list[0]?.infoAlertText ?? "",
              ...Treatmentdata?.payload?.data?.[
              Treatmentdata?.payload?.data?.length - 1 ?? 0
              ],
            })
          );
          var dataedit;
          if (!treatment?.child?.index >= 0) {
            dataedit = editIndex >= 0 ? list[treatment.editIndex] : 0;
            treatment.list[treatment.editIndex] = dataList;
          } else {
            dataedit =
              treatment.editIndex >= 0
                ? treatment.child.index >= 0
                  ? treatment.list[treatment.editIndex].childRecurrent[
                  treatment.child.index
                  ]
                  : 0
                : 0;
            treatment.list[treatment.editIndex].childRecurrent[
              treatment.child.index
            ] = dataList;
          }
        }
        treatment.child = {};
        await treatment.setLabOrder({
          ...treatment,
        });
      }

      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: false,
        message: "",
      });
      resolve(true);
    });
  };

  saveRadiology = async (
    list,
    editIndex,
    date,
    isupdate,
    childIds,
    deleteArr
  ) => {
    let status = this.props.recordStatus?.data.find(
      (val) => val.label === "Draft"
    );
    let radiology = this.props.radiology;
    return new Promise(async (resolve, reject) => {
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: true,
        message:
          editIndex === null ? "Adding New Radiology" : "Updating Radiology",
      });
      let personDetails = this?.props?.searchPractioner?.data?.[0];
      let searchPractioner = personDetails?.practitioner?.[0];
      let data = Rad_Json_Generator(
        list,
        this.props.encounterData,
        personDetails,
        searchPractioner
      );
      if (!editIndex) {
        data.forEach((d) => (d.status = status?.value || ""));
      }
      if (data?.length > 0) {
        let dateArr = [];
        if (list?.[0]?.recurrentList?.length > 0) {
          let listDataArr = list[0].recurrentList.map((val) =>
            getUtcTime(val.date)
          );
          dateArr = [listDataArr];
        } else {
          if (
            list?.[0]?.orderCatalog.label &&
            list?.[0]?.dateTime &&
            list?.[0]?.nature.label &&
            list?.[0]?.priority.label &&
            list?.[0]?.recurrentData?.every?.value &&
            list?.[0]?.recurrentData?.every?.periodicity?.value &&
            (list?.[0]?.recurrentData?.endsAfter?.endsAfter ||
              list?.[0]?.recurrentData?.endsAfter?.endsBy) &&
            list?.[0]?.recurrentData?.startsOn.dateTime
          ) {
            dateArr = getReccuranceDates(
              utcTOLocal(
                list?.[0]?.recurrentData?.startsOn?.dateTime,
                "DD-MM-YYYY"
              ), // DD-MM-YYYY
              list?.[0]?.recurrentData?.endsAfter?.endsAfter !== ""
                ? "NoOfDays"
                : "Calendar", // [by => Calendar, on => NoOfDays]
              list?.[0]?.recurrentData?.endsAfter?.endsAfter !== ""
                ? JSON.parse(list?.[0]?.recurrentData?.endsAfter?.endsAfter)
                : utcTOLocal(
                  list?.[0]?.recurrentData?.endsAfter?.endsBy,
                  "DD-MM-YYYY"
                ), // // [by => Date(DD-MM-YYYY), on => number(5)]
              list?.[0]?.recurrentData?.every?.periodicity?.label?.toLowerCase(), // weeks, month, days
              JSON.parse(list?.[0]?.recurrentData?.every?.value || "{}"), // number
              [], // []
              [] // []
            );
          } else {
            dateArr = [];
          }
        }

        var upsertdata;
        if (radiology?.child?.parentOrderId || date) {
          const vals = await this.props.READ_RAD_CHILD({
            list: data,
            Isparent: date
              ? list?.[0]?.editData
              : radiology?.child?.parentOrderId,
            dates: date ? getUtcTime(date) : null,
          });
        } else {
          upsertdata = await this.props.RAD_SAVE({
            list: data,
            dateArr: dateArr?.[0],
            isupdate: isupdate ? true : false,
            childIds: childIds,
            deleteArr,
          });
        }

        if (upsertdata?.payload?.data?.validation_error?.[0]?.Errormsg) {
          this.context.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: upsertdata.payload.data.error,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          this.props.backDrop.setBackDrop({
            ...this.props.backDrop,
            open: false,
            message: "",
          });
          return;
        }

        let Treatmentdata = await this.props.RAD_READ({
          encounter_id: this.props.encounterData?.data?.eId,
          order_id: upsertdata?.payload?.data?.Result?.[0]?.properties?.doc._id,
        });
        if (!editIndex && editIndex !== 0) {
          // return
          if (
            Array.isArray(Treatmentdata?.payload?.data) &&
            Treatmentdata?.payload?.data?.length > 0
          ) {
            Treatmentdata.payload.data.map((val) => {
              let dataList = JSON.parse(
                JSON.stringify({
                  ...{
                    isNew: true,
                  },
                  infoAlertText: list[0]?.infoAlertText ?? "",
                  ...val,
                })
              );
              radiology.list.push(dataList);
            });
          }
        } else {
          let dataList = JSON.parse(
            JSON.stringify({
              ...{
                isNew: true,
              },
              infoAlertText: list[0]?.infoAlertText ?? "",
              ...Treatmentdata?.payload?.data?.[
              Treatmentdata?.payload?.data?.length - 1 ?? 0
              ],
            })
          );
          var dataedit;
          if (!radiology?.child?.index >= 0) {
            dataedit = editIndex >= 0 ? list[radiology.editIndex] : 0;
            radiology.list[radiology.editIndex] = dataList;
          } else {
            dataedit =
              radiology.editIndex >= 0
                ? radiology.child.index >= 0
                  ? radiology.list[radiology.editIndex].childRecurrent[
                  radiology.child.index
                  ]
                  : 0
                : 0;
            radiology.list[radiology.editIndex].childRecurrent[
              radiology.child.index
            ] = dataList;
          }
        }
        radiology.child = {};
        await radiology.setRadiology({
          ...radiology,
        });
      }
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: false,
        message: "",
      });
      resolve(true);
    });
  };

  saveAllergy = async (list, editIndex, data) => {
    return new Promise(async (resolve, reject) => {
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: true,
        message: editIndex === null ? "Adding New Allergy" : "Updating",
      });

      if (data) {
        let saveFun = await this.props.SAVE_ALLERGY({ list: data });

        let allergys = this.props.allergy;

        if (!saveFun.payload.error) {
          if (!editIndex && editIndex !== 0) {
          } else {
            allergys.list[editIndex] = {
              ...list[0],
              isNew: false,
              isModified: true,
            };
          }
        } else {
          this.context.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: saveFun.payload.message,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
        }
        allergys.setAllergys({
          ...allergys,
        });
      }

      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: false,
        message: "",
      });
      resolve(true);
    });
  };

  saveDiagnosis = async (list, editIndex) => {
    return new Promise(async (resolve, reject) => {
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: true,
        message: editIndex === null ? "Adding New Diagnosis" : "Updating",
      });
      let personDetails = this?.props?.searchPractioner?.data?.[0];
      let searchPractioner = personDetails?.practitioner?.[0];

      let data = DIAGNOSIS_Json_Generator(
        list,
        searchPractioner,
        personDetails,
        this.props.encounterData
      );

      if (data?.length > 0) {
        let saveFun = await this.props.DIAGNOSIS_SAVE({ list: data });
        // await this.props.DIAGNOSIS_MASTERS_VALUE({
        //   id: list[0]?.diagnosis?.value,
        // });
        let diagnosis = this.props.Diagnosis;
        let diaList = JSON.parse(JSON.stringify(diagnosis.list));
        if (!saveFun.payload.error) {
          if (!editIndex && editIndex !== 0) {
            let data = await this.props.DIAGNOSIS_READ({
              encounter_id: this.props.encounterData?.data?.eId,
            });

            let dataList = JSON.parse(
              JSON.stringify({
                ...{
                  isNew: true,
                  createdName: list[0].createdName,
                  createdAt: list[0].createdAt,
                  onset: list[0].onset,
                },
                ...data?.payload?.data?.[data.payload?.data?.length - 1 ?? 0],
              })
            );
            //console.log(dataList);
            diaList.push(dataList);
          } else {
            if (diaList[editIndex]) {
              diaList[editIndex].isNew = false;
              diaList[editIndex].isModified = true;
            }
          }
        } else {
          this.context.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: saveFun.payload.message,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
        }
        diagnosis.setDiagnosis({
          ...diagnosis,
          list: diaList,
        });
      }
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: false,
        message: "",
      });
      resolve(true);
    });
  };

  saveDoccumentation = async (list, editIndex) => {
    return new Promise(async (resolve, reject) => {
      let { Doccument, setDoccument } = this.props.Doccument;
      let personDetails = this?.props?.searchPractioner?.data?.[0];

      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: true,
        message: editIndex === "Add" ? "Adding New Documentation" : "Updating",
      });
      const data = await this.props.DOCC_SAVE_SERVICE({
        data: {
          data: list[0],
          encounterData: this.props.encounterData,
          personDetails,
          formID: list?.[0]?.selectDoccument?.formrefid,
          formdata: list[0]?.formData ?? {},
        },
      });
      if (data?.payload?.data?.Code === 201 && editIndex === "Add") {
        let doc_key = data?.payload?.data?.Result[0]?.properties?.doc?._key;
        await this.props.DOCC_SINGLE_READ({ key: doc_key });
      }
      let Readdata = await this.props.DOCC_READ({
        // encounter_id: this.props.encounterData?.data?.eId,
        patient_id: this.props?.location?.state.pId,
      });

      setDoccument({
        ...Doccument,
        list: JSON.parse(JSON.stringify(Readdata?.payload?.data)),
      });
      // if (!editIndex && editIndex !== 0) {
      //   let data = await this.props.DOCC_READ({
      //     encounter_id: this.props.encounterData?.data?.eId,
      //   });

      // // return
      // let dataList = JSON.parse(
      //   JSON.stringify({
      //     ...{
      //       isNew: true,
      //     },
      //     ...data?.payload?.data?.[data.payload?.data?.length - 1 ?? 0],
      //   })
      // );
      // Doccument.editIndex =
      //   data?.payload?.data?.[data.payload?.data?.length - 1]?._key;
      // Doccument.list.push(dataList);
      // } else {
      // Doccument.list[editIndex] = list[0];
      // }
      // Doccument.setDoccument({
      //   ...Doccument,
      // });

      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: false,
        message: "",
      });
      resolve(true);
    });
  };

  async sign_OffFun() {
    this.setState({ signOff: true });
    return;
    if (this.state?.formsList?.includes("follow_up")) {
      if (!this.state?.followup_number) {
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please enter followup after",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      } else if (!this.state?.followup_type?.label) {
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please select followup type",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      } else {
        this.setState({ signOff: true });
      }
    } else {
      this.setState({ signOff: true });
    }
  }
  signOffFunc = async () => {

    this.setState({ signOff: true });

    return;

  };
  set_dialysisData = (data) => {
    this.setState({ footerDialsisData: data });
    return;
  };
  confirmButtonAction = async () => {
    const laborders = this.props.LabOrder;
    const radiologyData = this.props.radiology;
    const treatmentData = this.props.treatment;
    const ordersObj = [];
    let personDetails = this.props?.searchPractioner?.data?.[0];
    let encounterData = this.props.encounterData;
    const { appointmentId, app_id } = this.props?.location?.state;

    // if (this.state?.formsList?.includes("follow_up")) {
    if (this.state?.followup_number || this.state?.followup_type?.label) {
      if (!this.state?.followup_number) {
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please enter followup after",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        return false;
      } else if (!this.state?.followup_type?.label) {
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please select followup type",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        return false;
      } else {
        const encounter_key = encounterData?.data?.eId.split("/");
        //CALL ENCOUNTER UPSERT
        await this.props.ENCOUNTER_UPSERT({
          data: {
            _key: encounter_key[1],
            followup_number: this.state.followup_number
              ? parseInt(this.state.followup_number)
              : 0,
            followup_type: this.state.followup_type?.value ?? "",
            followup_reason: this.state.followup_reason?.value ?? "",
          },
        });
        //CALL FOLLOWUP ADD
        await this.props.SET_FOLLOWUP_SERVICE({
          data: {
            date: moment().unix(),
            status: "pending",
            encounterId: encounterData?.data?.eId ?? "",
            practitionerId: personDetails?.practitioner?.[0]?._id
              ? personDetails?.practitioner?.[0]?._id
              : "",
            orgId: personDetails?.OrgID
              ? JSON.stringify(personDetails?.OrgID)
              : "",
            patientId: encounterData?.data?.pID ?? "",
            editId:
              this.state?.followup?.payload?.data?.length > 0
                ? this.state.followup.payload.data[0]._id
                : "",
            followup_number: this.state.followup_number
              ? parseInt(this.state.followup_number)
              : 0,
            followup_type: this.state.followup_type?.value ?? "",
            followup_reason: this.state.followup_reason?.value ?? "",
            appointmentId:
              // app_id
              JSON.stringify(appointmentId) ?? "",
          },
        });
      }
    }
    if (radiologyData.list.length > 0) {
      ordersObj.push("radiologyData");
    }
    if (treatmentData.list.length > 0) {
      ordersObj.push("treatmentData");
    }
    if (laborders.list.length > 0) {
      ordersObj.push("laborders");
    }
    let status = "completed_nurse";
    let isCompleted = false;
    if (this.props.loggedUserInfo.data.internalRole === "doctor") {
      status = "completed";
      isCompleted = true;
    }
    await this.props.UPDATE_CA_WORKFLOW_STATUS({
      personId: personDetails._id,
      types: ordersObj,
    });
    await this.props.UPDATE_ALL_WORKFLOW_STATUS({
      personId: personDetails._id,
    });
    await this.updateStatus(status, appointmentId, undefined, isCompleted);
    window.location.href = "/";
  };


  render() {
    const { encounterData = {}, permissions = {} } = this.state;
    const { isProfileView } = this.props?.location?.state
    console.log("this.props?.location?.state", this.props?.location?.state?.isProfileView)
    return (
      <div id="patient_detail_parent_div">
        <div>
          <TopHeader
            parent_id={"dashboard"}
            noHome={true}
            middleComponent={<></>}
          />
          {/* Main patient details */}
          <Row
            id="patient_detail_parent_row"
            inLineStyles={{
              // background:
              // 	"transparent linear-gradient(90deg, rgb(251 194 235 / 20%) 0%, hsl(237deg 78% 82% / 20%) 49%, #6bbbff33 100%) 0% 0% no-repeat padding-box",
              while: "white",
              padding: "5px 10px 5px 10px",
            }}
          >
            <Col
              id="patient_detail_parent_col"
              xl={4}
              lg={4}
              md={6}
              sm={12}
              xs={12}
              inLineStyles={{ padding: 5, borderRight: "1px solid #DFE5EB" }}
            >
              <PatientInfoCard
                parent_id={"patient_detail"}
                pic={encounterData?.img}
                name={encounterData?.name}
                age={getCalculatedAge(encounterData?.dob)}
                gender={encounterData?.gender}
                mrn_id={encounterData?.mrn}
                pi_id={"PI " + encounterData?.pidentifier}
              />
            </Col>

            <Col
              id="patient_detail_statuscard_col"
              xl={4}
              lg={4}
              md={6}
              sm={12}
              xs={12}
              inLineStyles={{ padding: 5, borderRight: "1px solid #DFE5EB" }}
            >
              <PatientStatusCard
                parent_id={"patient_detail"}
                pic={
                  "images/Group 90506.svg"
                  // "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                }
                titles={[
                  encounterData?.id,
                  "OP",
                  encounterData?.type,
                  encounterData?.speciality,
                ]}
                date={utcTOLocal(encounterData?.encounterdate, "DD MMM YYYY")}
                languages={encounterData?.lang}
              />
            </Col>
            <Col
              id="patient_detail_care_typecard_col"
              xl={4}
              lg={4}
              md={6}
              sm={12}
              xs={12}
              inLineStyles={{ padding: 5 }}
            >
              <PatientCareTypeCard
                parent_id={"patient_detail"}
                careList={this.state.fin_data}
                selected={this.state.selectOption}
                onChange={(data) => this.changeState("selectOption", data)}
              />
            </Col>
            {/* <Miniwidget
              permissions={permissions}
              parent_id={"patent_details"}
              editWidget={this.onEditWidget}
            /> */}
          </Row>
        </div>
        <Row
          id="patient_detail_footer_row"
          inLineStyles={{ borderTop: "1px solid rgb(224, 224, 224)" }}
        >
          <Col
            id="patient_detail_footer_col"
            xl={this.state.signOff ? 12 : 9}
            lg={this.state.signOff ? 12 : 9}
            md={this.state.signOff ? 12 : 9}
            sm={12}
            xs={12}
            inLineStyles={{ height: "calc( 100vh - 93px )", overflow: "auto" }}
            style={{ ...this.styles.columnStyle, padding: 0 }}
          >
            <div
              id="patient_detail_footer_buttons_div"
              style={{ display: "flex", overflowX: "hidden" }}
            >
              {/* Menus items */}
              {!this.state.signOff && !isProfileView && (
                <Div
                  id="patient_detail_menulist_div"
                  inLineStyles={{
                    backgroundColor: "#fff",
                    //borderRight: "1px solid rgb(224, 224, 224)",
                    padding: "10px",
                    height: "75vh",
                  }}
                >
                  <MenuList
                    permissions={permissions}
                    parent_id={"patient_detail"}
                    selectedFormList={this.changeChartLayer}
                    selectedForm={this.state.formsList ?? []}
                  />
                </Div>
              )}

              {/* Mian Action items and Chart */}

              {this.state.formComponet?.open ? (
                //  Edit the patient_chart forms
                <Div
                  id="patient_detail_formtype_div"
                  inLineStyles={{
                    padding: "10px 10px 0px 10px",
                    width: "100%",
                    background: UIColor.greyBackground.color,
                  }}
                >
                  {this.getFormTypeRender(this.state?.formComponet?.type)}
                </Div>
              ) : (
                <Div
                  id="patient_detail_buttons_div"
                  inLineStyles={{
                    width: "100%",
                    background: !this.state.signOff
                      ? UIColor.greyBackground.color
                      : "transparent",
                    height: !this.state.signOff ? "auto" : "65vh",
                  }}
                >
                  <div
                    id="patient_charts_div"
                    style={{
                      maxWidth: this.state.signOff ? "1140px" : "100%",
                      margin: this.state.signOff ? "auto" : "0",
                    }}
                  >

                    {!this.state.signOff && !isProfileView ? (
                      <>{this.getCharts(this.state.patientChartList)}</>
                    ) : (
                      <div style={{ margin: "20px" }}>
                        <SignOffCard
                          selectedFormList={this.changeChartLayer}
                          selectedForm={this.state.formsList ?? []}
                          permissions={permissions}
                          parent_id={"patient_detail"}
                          followup_number={this.state.followup_number}
                          followup_type={this.state.followup_type}
                          formsList={this.state?.formsList}
                          followupHnadle={this.followupHnadle}
                          handleInputChange={this.handleInputChange}
                          tabHandle={this.tabHandle}
                          handleselectChange={this.handleselectChange}
                          followup={this.state.followup}
                          reasonData_={this.props.reason_.data}
                          followup_reason_={this.state.followup_reason}
                          followupuom={this.props.followupuom.data}
                          dialysisData={
                            this.state.dialysisData?.component?.[0] ?? {}
                          }
                          isProfileView={this.props?.location?.state?.isProfileView}
                          set_dialysisData={this.set_dialysisData}
                        />
                      </div>
                    )}
                  </div>
                  {/* Footer  */}
                  <>
                    <Row
                      id="patient_detail_footer_row"
                      inLineStyles={{
                        position: this.state.signOff ? "relative" : "fixed",
                        bottom: 0,
                        width: this.state.signOff ? "100%" : "71%",
                        backgroundColor: UIColor.greyBackground.color,
                      }}
                    >
                      {this.state?.formsList?.includes("follow_up") &&
                        !this.state.signOff && !isProfileView && (
                          <FollowupFotter
                            followup_number={this.state.followup_number}
                            followup_type={this.state.followup_type}
                            followup_reason_={this.state.followup_reason}
                            followupHnadle={this.followupHnadle}
                            handleInputChange={this.handleInputChange}
                            tabHandle={this.tabHandle}
                            handleselectChange={this.handleselectChange}
                            reasonData_={this.props.reason_.data}
                            followupuom={this.props.followupuom.data}
                          />
                        )}

                      {/* <Divider
												id="patient_detail_bottom_divider"
												variant={"fullWidth"}
												inLineStyles={{ backgroundColor: "#E0E0E0" }}
											/> */}
                      {!this.state.signOff && !isProfileView ? (
                        <Col
                          id="patient_detail_footer_buttons_col"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          inLineStyles={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingBottom: "20px",
                            background: UIColor.greyBackground.color,
                            // paddingTop: "20px",
                            borderRadius: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              background: "#fff",
                              padding: "8px 12px",
                              borderRadius: "0px 0px 10px 10px",
                              width: "100%",
                            }}
                          >
                            <Div id="patient_detail_goback_button_div">
                              <Button
                                id="patient_detail_goback_button"
                                onClick={() => this.goBack()}
                                variant={"text"}
                                inLineStyles={{
                                  backgroundColor: UIColor.lineBorderFill.color,
                                  borderColor: UIColor.lineBorderFill.color,
                                  color: UIColor.primaryColor.color,
                                  borderRadius: "8px",
                                  fontSize: "14px",
                                }}
                              >
                                {"Go back"}
                              </Button>
                            </Div>
                            <Div id="patient_detail_hold-sign_button_div">
                              <Button
                                id="patient_detail_put_hold_button"
                                inLineStyles={{
                                  backgroundColor: "#FFE1DA",
                                  borderColor: "#FFE1DA",
                                  color: UIColor.secondaryColor.color,
                                  marginRight: "14px",
                                  borderRadius: "8px",
                                  fontSize: "14px",
                                }}
                                onClick={async () => {
                                  const { appointmentId } =
                                    this.props?.location?.state;
                                  let status = "onhold_nurse";
                                  let isCompleted = false;
                                  if (
                                    this.props.loggedUserInfo.data
                                      .internalRole === "doctor"
                                  ) {
                                    status = "onhold_doctor";
                                  }
                                  await this.updateStatus(
                                    status,
                                    appointmentId,
                                    undefined,
                                    isCompleted
                                  );
                                  window.location.href = "/";
                                }}
                              >
                                {"Put On-Hold"}
                              </Button>
                              <Button
                                id="patient_detail_signoff_button"
                                onClick={() => this.signOffFunc()}
                                inLineStyles={{
                                  backgroundColor: UIColor.primaryColor.color,
                                  borderColor: UIColor.primaryColor.color,
                                  borderRadius: "8px",
                                  fontSize: "14px",
                                }}
                              >
                                {"Sign Off"}
                              </Button>
                            </Div>
                          </div>
                        </Col>

                      ) : (
                        <Footer
                          confirmButtonAction={this.confirmButtonAction}
                          parent_id={"patient_detail"}
                          goBackButtonAction={() =>
                            this.goBackInsignOff("signOff", false)
                          }
                          isProfileView={isProfileView}
                          ecounterData={encounterData}
                          footerData={this.state.footerDialsisData}
                        />
                      )}
                    </Row>
                  </>
                </Div>
              )}
              {/* END Mian Action items and Chart */}
            </div>
          </Col>
          {!this.state.signOff && !isProfileView && (
            <Col
              id="patient_detail_info_widget_col"
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              inLineStyles={this.styles.rightSideCol}
            >
              <Row id="patient_detail_info_widget_row">
                <Col
                  id="patient_detail_infoHeader_col"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  inLineStyles={this.styles.infoHeader}
                >
                  <Div id="patient_detail_info_div">
                    <Text
                      id="patient_detail_info_text"
                      inLineStyles={{
                        fontWeight: 600,
                        fontSize: "16px",
                        color: UIColor.primaryText.color,
                      }}
                    >
                      {"Info"}
                    </Text>
                  </Div>
                  <Div id="patient_detail_Add_Widget_div">
                    <Button
                      id="patient_detail_Add_Widget_button"
                      variant={"text"}
                      inLineStyles={{
                        color: UIColor.secondaryColor.color,
                        padding: "5px",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      {"+ Add Widget"}
                    </Button>
                  </Div>
                </Col>

                {/* Widgets list  */}
                <Col
                  id="patient_detail_WidgetList_col"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  inLineStyles={this.styles.background}
                >
                  <WidgetsList
                    permissions={permissions}
                    parent_id={"patent_details"}
                    editWidget={this.onEditWidget}
                  />
                </Col>
                {/* END Widgets list */}
              </Row>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}
PatientDetail.contextType = AlertContext;

const mapStateToProps = (state) => ({
  searchPractioner: state?.personApiSlice?.searchPractioner,
  readPerson: state?.personApiSlice?.readPerson,
  encounterData: state?.chiefCompilenceMastersSlice?.encounter_Details,
  teartment_ordertype: state?.TreatmentSlice?.teartment_ordertype,
  teartment_order_catalog: state?.TreatmentSlice?.teartment_order_catalog,
  teartment_priority: state?.TreatmentSlice?.teartment_priority,
  teartment_nature: state?.TreatmentSlice?.teartment_nature,
  teartment_mode: state?.TreatmentSlice?.teartment_mode,
  teartment_diagnosis: state?.TreatmentSlice?.teartment_diagnosis,
  teartment_indicate: state?.TreatmentSlice?.teartment_indicate,
  teartment_instuction: state?.TreatmentSlice?.teartment_instuction,
  treatment_organization: state?.TreatmentSlice?.treatment_organization,
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
  treatment_pre_instrucation: state?.TreatmentSlice?.treatment_pre_instrucation,
  treatment_dep_instrucation: state?.TreatmentSlice?.treatment_dep_instrucation,
  recordStatus: state?.signOffSlice?.getRecordStatus,
  reason_: state?.SigninFollowup?.followup_reason,
  followupuom: state?.SigninFollowup?.followup_uom,
});

export default withStyles(materialStyles)(
  connect(mapStateToProps, actions)(withRouter(withAllContexts(PatientDetail)))
);
