import moment from "moment";
import { getImgUrl, utcTOLocal } from "../../../utils";

const constructAgeType = (date, ageTypeMaster) => {
  let days = moment().diff(moment(date, "YYYY-MM-DD"), "days");
  if (days <= 90) {
    let option = ageTypeMaster.filter((val) => val.label === "Days")[0];
    return option ? option : { value: "Years", label: "Years" };
  } else if (days > 90 && days < 365) {
    let option = ageTypeMaster.filter((val) => val.label === "Months")[0];
    return option ? option : { value: "Years", label: "Years" };
  } else if (days >= 365) {
    let option = ageTypeMaster.filter((val) => val.label === "Years")[0];
    return option ? option : { value: "Years", label: "Years" };
  }
};

export const PatientJSON = async (data, PATIENT_SDI_READ, ageTypeMaster) => {
  const iden = data?.identifier?.map((v) => {
    let extraJson = {};
    if (v?.files?.length > 0) {
      extraJson = {
        doc: v?.files?.[0],
      };
    }
    return {
      ...extraJson,
      id_number: v?.value ?? "",
      id_type: {
        value: v?.user?.display ?? "",
        label: v?.user?.display ?? "",
        _id: v?.user?._id ?? "",
      },
      expiry_date: v?.period?.[0]?.end
        ? utcTOLocal(v?.period?.[0]?.end, "YYYY-MM-DD")
        : "",
      id_source: {
        value: v?.assigner?.display ?? "",
        label: v?.assigner?.display ?? "",
        _id: v?.assigner?._id ?? "",
      },
    };
  });

  const nam = data?.name?.map((v) => {
    return {
      first_name: v?.text ?? "",
      last_name: v?.family ?? "",
      middle_name: v?.given ?? "",
      use: {
        value:
          (v?.use?.display ?? "") === "string"
            ? "usual"
            : v?.use?.display ?? "",
        _id: v?.use?._id ?? "",
      },

      prefix: { value: v?.prefix?.display ?? "", _id: v?.prefix?._id ?? "" },
      suffix: { value: v?.suffix?.display ?? "", _id: v?.suffix?._id ?? "" },
    };
  });

  const lan = data?.communicationLanguage?.map((v) => {
    return {
      value: v?.display ?? "",
      label: v?.display ?? "",
      _id: v?._id ?? "",
    };
  });

  const getGeoInfo = (data) => ({
    _id: data._id,
    label: data._id,
    value: data.geogLevelName,
    code: data.geogLevelCode,
    parentId: data.parentGeogLevelCode,
  });

  const add = data?.address?.map((v) => {
    return {
      use: {
        value: v?.use?.display ?? "",
        label: v?.use?.display ?? "",
        _id: v?.use?._id ?? "",
      },
      addressLine1: v?.text ?? "",
      addressLine2: v?.line?.split("/")[0] ?? "",
      addressLine3: v?.line?.split("/")[1] ?? "",
      city: getGeoInfo(v?.city || {}),
      state: getGeoInfo(v?.state || {}),
      district: getGeoInfo(v?.district || {}),
      country: getGeoInfo(v?.country || {}),
      type: {
        value: v?.Type?.display ?? "",
        label: v?.Type?.display ?? "",
        _id: v?.Type?._id ?? "",
      },
      pincode: getGeoInfo(v?.postalCode || {}),
    };
  });
  const dataTel = await PATIENT_SDI_READ();
  console.log("dataTel", dataTel);
  const isMobile = !!data?.telecom?.find(
    (val) => val.system?.display?.toLowerCase?.() === "phone"
  )?.value;
  console.log("isMobile", isMobile);
  console.log("data", data);

  const mbl = isMobile
    ? data?.telecom?.find(
        (val) => val.system?.display === "Phone" || val.use === "Mobile"
      )
    : {};
  console.log("mbl", mbl);
  const getTel = isMobile
    ? dataTel.payload.data.filter(
        (val, i) =>
          val.value === mbl?.value?.slice?.(0, mbl?.valueprefix?.length || 3)
      )
    : [];
  console.log("getTel", getTel);
  const attachments = data?.attachment?.map((a) => {
    return {
      name: a.fileName,
      type: a.filetype,
      fileUrl: getImgUrl(a.fileid),
      size: 0,
      originalData: a,
    };
  });
  return {
    identification: iden?.length > 0 ? iden : [{}],
    names: nam?.length > 0 ? nam : [{}],
    details: {
      image: {
        url: getImgUrl(data?.photo?.[0]?.fileid) ?? "",
        oldData: data?.photo || [],
      },
      dob: data?.birthDate
        ? moment(data?.birthDate, "DD/MM/YYYY").format("YYYY-MM-DD")
        : "",
      gender: {
        value: data?.gender?.display ?? "",
        label: data?.gender?.display ?? "",
        _id: data?.gender?._id ?? "",
      },
      age: JSON.parse(data?.age) ?? "",
      ageType: data?.birthDate
        ? constructAgeType(
            moment(data?.birthDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
            ageTypeMaster
          )
        : {
            label: "",
            value: "",
            _id: "",
          },
      // ageType: {
      // 	label: data?.age_type?.display ?? "",
      // 	value: data?.age_type?.display ?? "",
      // 	_id: data?.age_type?._id ?? "",
      // },
      nationality: {
        value: data?.nationality?.geogLevelName ?? "",
        label: data?.nationality?.geogLevelName ?? "",
        _id: data?.nationality?._id ?? "",
      },
      entered_age: data?.approxAgeEntered,
      marital_status: {
        value: data?.maritalstatus?.display ?? "",
        label: data?.maritalstatus?.display ?? "",
        _id: data?.maritalstatus?._id ?? "",
      },
    },
    communication: {
      clanguage: [...(lan || [])],
      planguage: {
        value: data?.preferredLanguage ?? "",
        label: data?.preferredLanguage ?? "",
        _id: data?._id ?? "",
      },
      isinterpreter: data?.interpRequired,
    },
    contact: [
      {
        mobile: isMobile
          ? data?.telecom?.[0]?.value?.slice(getTel?.[0]?.value?.length)
          : data?.telecom?.find(
              (val) =>
                val.system?.display?.toLowerCase?.() === "email" ||
                val.use === "email"
            )?.value || "",
        mobile_code: isMobile ? getTel[0] : "",
        mode: {
          _id:
            data?.telecom?.find((val) => val.system?.display)?.system?._id ??
            "",
          value:
            data?.telecom?.find((val) => val.system?.display)?.system
              ?.display ?? "",
          label:
            data?.telecom?.find((val) => val.system?.display)?.system
              ?.display ?? "",
        },
        use: {
          _id: data?.telecom?.find((val) => val.use?.display)?.use?._id ?? "",
          value:
            data?.telecom?.find((val) => val.use?.display)?.use?.display ?? "",
          label:
            data?.telecom?.find((val) => val.use?.display)?.use?.display ?? "",
        },
        priority: {
          _id: data?.telecom?.find((val) => val.use?.display)?.rank?._id ?? "",
          value:
            data?.telecom?.find((val) => val.use?.display)?.rank?.display ?? "",
          label:
            data?.telecom?.find((val) => val.use?.display)?.rank?.display ?? "",
        },
      },
      {
        mobile: isMobile
          ? data?.telecom?.[1]?.value?.slice(getTel?.[0]?.value?.length)
          : data?.telecom?.find(
              (val) =>
                val.system?.display?.toLowerCase?.() === "email" ||
                val.use === "email"
            )?.value || "",

        mode: {
          _id: data?.telecom?.[1]?.system?._id ?? "",
          value: data?.telecom?.[1]?.system?.display ?? "",
          label: data?.telecom?.[1]?.system?.display ?? "",
        },
        use: {
          _id: data?.telecom?.[1]?.use?._id ?? "",
          value: data?.telecom?.[1]?.use?.display ?? "",
          label: data?.telecom?.[1]?.use?.display ?? "",
        },
        priority: {
          _id: data?.telecom?.[1]?.rank?._id ?? "",
          value: data?.telecom?.[1]?.rank?.display ?? "",
          label: data?.telecom?.[1]?.rank?.display ?? "",
        },
      },
    ],
    address: [...(add || [])],
    statusDetails: {
      status: data?.isdeceased?.display ?? "",
      reason: {
        value: data?.deceasedreason?.display ?? "",
        label: data?.deceasedreason?.display ?? "",
        _id: data?.deceasedreason?._id ?? "",
      },
      // (data?.deceasedreason ?? ''),
      time: data?.deceaseddate
        ? utcTOLocal(data?.deceaseddate, "YYYY-MM-DD")
        : "",
      status1: data?.active ? "Active" : "Inactive",
      reason1: {
        value: data?.activereason?.display ?? "",
        label: data?.activereason?.display ?? "",
        _id: data?.activereason?._id ?? "",
      },
      date: data?.deceaseddate ? true : false,
    },
    alias: data?.alias ?? "",
    uploadFiles: attachments,
  };
};

export const PatientNEXTDataJSON = (data, ageTypeMaster) => {
  debugger;
  const iden = data?.identifier?.map((v) => {
    let extraJson = {};
    if (v?.files?.length > 0) {
      extraJson = {
        doc: v?.files?.[0],
      };
    }
    return {
      ...extraJson,
      id_number: v?.value ?? "",
      id_type: {
        value: v?.user?.display ?? "",
        label: v?.user?.display ?? "",
        _id: v?.user?._id ?? "",
      },
      expiry_date: v?.period?.[0]?.end
        ? utcTOLocal(v?.period?.[0]?.end, "YYYY-MM-DD")
        : "",
      id_source: {
        value: v?.assigner?.display ?? "",
        label: v?.assigner?.display ?? "",
        _id: v?.assigner?._id ?? "",
      },
    };
  });

  const nam = data?.name?.map((v) => {
    return {
      first_name: v?.text ?? "",
      last_name: v?.family ?? "",
      middle_name: v?.given ?? "",
      use: {
        value:
          (v?.use?.display ?? "") === "string"
            ? "usual"
            : v?.use?.display ?? "",
        _id: v?.use?._id ?? "",
      },

      prefix: { value: v?.prefix?.display ?? "", _id: v?.prefix?._id ?? "" },
      suffix: { value: v?.suffix?.display ?? "", _id: v?.suffix?._id ?? "" },
    };
  });

  const lan = data?.communicationLanguage?.map((v) => {
    return {
      value: v?.display ?? "",
      label: v?.display ?? "",
      _id: v?._id ?? "",
    };
  });

  const add = data?.address?.map((v) => {
    return {
      use: {
        value: v?.use?.display ?? "",
        label: v?.use?.display ?? "",
        _id: v?.use?._id ?? "",
      },
      addressLine1: v?.text ?? "",
      addressLine2: v?.line?.split("/")[0] ?? "",
      addressLine3: v?.line?.split("/")[1] ?? "",
      city: {
        value: v?.city?.geogLevelName ?? "",
        label: v?.city?.geogLevelName ?? "",
        _id: v?.city?._id ?? "",
      },
      state: {
        value: v?.state?.geogLevelName ?? "",
        label: v?.state?.geogLevelName ?? "",
        _id: v?.state?._id ?? "",
      },
      district: {
        value: v?.district?.geogLevelName ?? "",
        label: v?.district?.geogLevelName ?? "",
        _id: v?.district?._id ?? "",
      },
      country: {
        value: v?.country?.geogLevelName ?? "",
        label: v?.country?.geogLevelName ?? "",
        _id: v?.country?._id ?? "",
      },
      type: {
        value: v?.Type?.display ?? "",
        label: v?.Type?.display ?? "",
        _id: v?.Type?._id ?? "",
      },
      pincode: {
        value: v?.postalCode?.geogLevelName ?? "",
        label: v?.postalCode?.geogLevelName ?? "",
        _id: v?.postalCode?._id ?? "",
      },
    };
  });

  return {
    _id: data?._id,
    _key: data?._key,
    identification: iden?.length > 0 ? iden : [{}],
    names: nam?.length > 0 ? nam : [{}],
    details: {
      image: {
        url: getImgUrl(data?.photo?.[0]?.fileid) ?? "",
        oldData: data?.photo || [],
      },
      dob: data?.birthDate
        ? moment(data?.birthDate, "DD/MM/YYYY").format("YYYY-MM-DD")
        : "",
      gender: {
        value: data?.gender?.display ?? "",
        label: data?.gender?.display ?? "",
        _id: data?.gender?._id ?? "",
      },
      age: data?.age ? JSON.parse(data?.age) : "",
      ageType: data?.birthDate
        ? constructAgeType(
            moment(data?.birthDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
            ageTypeMaster
          )
        : {
            label: "",
            value: "",
            _id: "",
          },
      // ageType: {
      // 	label: data?.age_type?.display ?? "",
      // 	value: data?.age_type?.display ?? "",
      // 	_id: data?.age_type?._id ?? "",
      // },
      nationality: {
        value: data?.nationality?.geogLevelName ?? "",
        label: data?.nationality?.geogLevelName ?? "",
        _id: data?.nationality?._id ?? "",
      },
      entered_age: data?.approxAgeEntered,
      marital_status: {
        value: data?.maritalstatus?.display ?? "",
        label: data?.maritalstatus?.display ?? "",
        _id: data?.maritalstatus?._id ?? "",
      },
    },
    communication: {
      clanguage: [...(lan || [])],
      planguage: {
        value: data?.preferredLanguage ?? "",
        label: data?.preferredLanguage ?? "",
        _id: data?._id ?? "",
      },
      isinterpreter: data?.interpRequired,
    },
    contact: [
      {
        mobile: data?.telecom?.[0]?.value,
        mobile_code: {
          label: data?.telecom?.[0]?.valueprefix?.geogLevelName,
          value: data?.telecom?.[0]?.valueprefix?.GeoLocationISDCode,
          _id: data?.telecom?.[0]?.valueprefix?._id,
        },
        mode: {
          _id: data?.telecom?.[0]?.system?._id ?? "",
          value: data?.telecom?.[0]?.system?.display ?? "",
          label: data?.telecom?.[0]?.system?.display ?? "",
        },
        use: {
          _id: data?.telecom?.[0]?.use?._id ?? "",
          value: data?.telecom?.[0]?.use?.display ?? "",
          label: data?.telecom?.[0]?.use?.display ?? "",
        },
        priority: {
          _id: data?.telecom?.[0]?.rank?._id ?? "",
          value: data?.telecom?.[0]?.rank?.display ?? "",
          label: data?.telecom?.[0]?.rank?.display ?? "",
        },
      },
      {
        mobile: data?.telecom?.[1]?.value,
        // mobile_code: data?.telecom?.[1]?.valueprefix,
        mode: {
          _id: data?.telecom?.[1]?.system?._id ?? "",
          value: data?.telecom?.[1]?.system?.display ?? "",
          label: data?.telecom?.[1]?.system?.display ?? "",
        },
        use: {
          _id: data?.telecom?.[1]?.use?._id ?? "",
          value: data?.telecom?.[1]?.use?.display ?? "",
          label: data?.telecom?.[1]?.use?.display ?? "",
        },
        priority: {
          _id: data?.telecom?.[1]?.rank?._id ?? "",
          value: data?.telecom?.[1]?.rank?.display ?? "",
          label: data?.telecom?.[1]?.rank?.display ?? "",
        },
      },
    ],
    address: [...(add || [])],
    statusDetails: {
      status: data?.isdeceased?.display ?? "",
      reason: {
        value: data?.deceasedreason?.display ?? "",
        label: data?.deceasedreason?.display ?? "",
        _id: data?.deceasedreason?._id ?? "",
      },
      // (data?.deceasedreason ?? ''),
      time: data?.deceaseddate
        ? utcTOLocal(data?.deceaseddate, "YYYY-MM-DD")
        : "",

      status1: data?.active ? "Active" : "Inactive",
      reason1: {
        value: data?.activereason?.display ?? "",
        label: data?.activereason?.display ?? "",
        _id: data?.activereason?._id ?? "",
      },
      date: data?.deceaseddate ? true : false,
    },
    alias: data?.alias ?? "",
  };
};

export const PatientNextJSON = (data, options) => {
  return {
    additnaldata: {
      patient_type: {
        label: data?.patientTypecode?.display ?? "",
        value: data?.patientTypecode?.display ?? "",
        _id: data?.patientTypecode?._id ?? "",
      },
      patient_importance: JSON.parse(
        JSON.stringify(
          options?.patient_importance?.find?.(
            (d) => d?._id === data?.patientImp_Code
          ) || {}
        )
      ),
      // special_courtesy: data?.specialCourtesy_code ?? "",
      // special_arrangement: data?.specialArng_code ?? "",
      special_courtesy: {
        label: data?.specialCourtesy_code?.display ?? "",
        value: data?.specialCourtesy_code?.display ?? "",
        _id: data?.specialCourtesy_code?._id ?? "",
      },
      special_arrangement: {
        label: data?.specialArng_code?.display ?? "",
        value: data?.specialArng_code?.display ?? "",
        _id: data?.specialArng_code?._id ?? "",
      },
      race: {
        label: data?.raceCode?.display ?? "",
        value: data?.raceCode?.display ?? "",
        _id: data?.raceCode?._id ?? "",
      },
      ethnicity: {
        label: data?.ethinicityCode?.display ?? "",
        value: data?.ethinicityCode?.display ?? "",
        _id: data?.ethinicityCode?._id ?? "",
      },
      religion: {
        label: data?.religionCode?.display ?? "",
        value: data?.religionCode?.display ?? "",
        _id: data?.religionCode?._id ?? "",
      },
      blood_group: {
        label: data?.bloodGroup?.display ?? "",
        value: data?.bloodGroup?.display ?? "",
        _id: data?.bloodGroup?._id ?? "",
      },
    },
    birthdetails: {
      birth_place: data?.birthPlace ?? "",
      birth_time: data?.birthTime
        ? moment.unix(data?.birthTime)
        : //? moment.unix(data?.birthTime).format("YYYY-MM-DD")
          undefined,
      birth_gender: {
        value: data?.birthGendercode?.display ?? "",
        label: data?.birthGendercode?.display ?? "",
        _id: data?.birthGendercode?._id ?? "",
      },
      gender_identify: {
        value: data?.genderIdentitycode?.display ?? "",
        label: data?.genderIdentitycode?.display ?? "",
        _id: data?.genderIdentitycode?._id ?? "",
      },
      primarycare_practitioner: {
        value: data?.primarycare_Prac?.given ?? "",
        label: data?.primarycare_Prac?.given ?? "",
        _id: data?.primarycare_Prac?._id ?? "",
      },
      disability_code: {
        value: data?.disabilityCode?.display ?? "",
        label: data?.disabilityCode?.display ?? "",
        _id: data?.disabilityCode?._id ?? "",
      },
      cadaveric_donar: data?.cadavericDonor,
      citizenshio_code: {
        value: data?.citizenshipCode?.geogLevelName ?? "",
        label: data?.citizenshipCode?.geogLevelName ?? "",
        _id: data?.citizenshipCode?._id ?? "",
      },
      residency_status: data?.residencyStatus
        ? data?.residencyStatus === true
          ? "legal"
          : "illegal"
        : "illegal",
    },
  };
};
