import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import { Routes } from "../../router/routes";
import {
  Container,
  Div,
  Icons,
  SideMenu,
  Text,
  Image,
  Divider,
  Button,
  H6,
} from "qdm-component-library";
import CancelBookingPage from "../cancelBooking/index";
import ReactToPrint from "react-to-print";
import { Routes } from "../../router/routes";
import {
  convertTime,
  getImgUrl,
  makeName,
  updateStatus,
  utcTOLocal,
  ValidateEmail,
  UIColor,
} from "../../utils";
import { PatientDetails } from "./patientDetails";
import { AppointmentTable } from "./appointmentTable";
import { AppointmentSummaryPDF, LoadingComp } from "../../components";
import EmailDialog from "./dialog";
import videoOutline from "./../../assets/img/videoOutline.svg";
import videoFilled from "./../../assets/img/videoFilled.svg";
import payment from "./../../assets/img/payment.svg";
import { checkWithCasbin } from "../../utils/permissonHandling";
const casbinjs = require("casbin.js");
// import { Gustuserconfirm } from "../../assets/img/gustuserconfirm.js";
class ConfirmationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelbooking: false,
      cancelbookingreason: {},
      cancelbookings: 0,
      appointmentInfo: {},
      appArr: [],
      modal: false,
      err: false,
      emailId: "",
      isLoading: false,
      loading: false,
      pemissions: [],
      permission__: [],
      genInst: {},
      loader: true,
    };
    this.updateStatus = updateStatus.bind(this);
  }
  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  async componentDidMount() {
    let permiss_ = await checkWithCasbin(["appointmentconfirmation"]);
    let permission__ = await checkWithCasbin(["dashboard"]);
    debugger;
    this.setState({
      ...this.state,
      pemissions: permiss_,
      permission__: permission__,
    });

    if (!this.props.location?.state?.isMulti) {
      this.fetchInfo();
    } else {
      let info = {};
      const data = this.props.location?.state?.appointmentInfo?.map(
        (app, i) => {
          if (!!app?.payload?.error) {
            return undefined;
          } else {
            info =
              app?.payload?.data?.appointment_response?.appointment?.Result?.[0]
                ?.properties?.doc || {};
            return {
              index: i,
              appointmentNumber: `${app?.payload?.data?.appointment_response?.appointment?.Result?.[0]?.properties?.doc?.appno}`,
              dateTime:
                app?.payload?.data?.appointment_response?.appointment
                  ?.Result?.[0]?.properties?.doc?.start,
            };
          }
        }
      );
      this.fetchInfo(info.id, data);
    }
    this.setState({ ...this.state, loader: false });
  }

  async fetchInfo(id, data) {
    debugger;
    if (!id) {
      const data = await this.props.APPOINTMENT_READ({
        appointmentId: Number(
          this.props.location?.state?.appointmentId ||
            this.props.appointmentId ||
            this.props.appointmentId ||
            0
        ),
      });
      this.changeState("appointmentInfo", data?.payload?.data || {});
    } else {
      const info = await this.props.APPOINTMENT_READ({
        appointmentId: Number(id || 0),
      });
      this.setState({
        ...this.state,
        appointmentInfo: info?.payload?.data || {},
        appArr: data.filter((d) => !!d),
      });
    }
    const genInst = await this.props.GENERAL_INSTRUCTION({
      org_id: this.state.appointmentInfo?.orgid?._id || "",
    });
    this.setState({
      ...this.state,
      genInst: genInst?.payload?.data,
    });
  }

  shouldComponentUpdate(props, state) {
    return true;
  }
  styles = {
    videoIcon: {
      width: 80,
      height: 80,
      background: "#73B9FF",
      marginBottom: 10,
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    container: {
      background: "#FFFFFF",
      boxShadow: "0px 4px 12px #0000001A",
      padding: 30,
      minWidth: "600px",
      borderRadius: 16,
      // height: "100vh"
    },
    tickSvg: {
      width: 32,
      height: 32,
      margin: "auto auto 10px",
    },
    appointmentBooking: {
      color: UIColor.success.color,
      fontSize: 18,
      marginBottom: 10,
      textAlign: "center",
    },
    confirmation: {
      color: UIColor.tertiaryText.color,
      fontSize: 12,
      textAlign: "center",
    },
    wrapper: {
      border: "2px dashed rgb(239 239 239)",
      borderRadius: 8,
      display: "flex",
      alignItems: "center",
      margin: "20px 0px",
    },
    h6: {
      color: "#101010",
      fontSize: 14,
      margin: 0,
    },
    span: {
      color: UIColor.tertiaryText.color,
      fontSize: 12,
    },
    barCode: {
      width: 100,
      height: 100,
      border: "2px dashed rgb(239 239 239)",
      padding: 5,
      marginBottom: 10,
      borderRadius: 8,
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
      padding: 10,
    },
    flexRow: {
      display: "flex",
      alignItems: "center",
    },
    divider: {
      margin: "10px",
      opacity: 0.5,
    },
    iconSpan: {
      color: UIColor.secondaryButtonColor.color,
      marginLeft: "10px",
      fontSize: 12,
    },
    outlinedButton: {
      borderColor: UIColor.secondaryButtonColor.color,
      color: UIColor.secondaryButtonColor.color,
      backgroundColor: "#fff",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 12,
      marginRight: 10,
    },
    containedButton: {
      backgroundColor: UIColor.secondaryButtonColor.color,
      color: "#fff",
      borderColor: UIColor.secondaryButtonColor.color,
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 12,
    },
    clickHere: {
      margin: 0,
      marginLeft: 10,
      color: UIColor.secondaryButtonColor.color,
      cursor: "pointer",
      fontSize: 12,
    },
    gustuser: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "1px solid #38C20A",
      width: "80%",
      padding: "6px 8px",
      backgroundColor: "#F0FCEF",
      borderRadius: "8px",
      fontSize: 12,
      color: "#258007",
      marginTop: "4%",
    },
    multipleAppointmentsWrapper: {
      border: "1px solid #E0E0E0",
      borderRadius: 8,
      display: "flex",
      padding: "5px 0px",
      margin: "20px 0px",
    },
    veticalDiv: {
      width: 0.5,
      backgroundColor: "#E0E0E0",
    },
  };
  handlesidebarCancelbooking = () => {
    this.setState({
      cancelbooking: !this.state.cancelbooking,
    });
  };
  handleSelectCancelbooking = (v) => {
    this.setState({
      cancelbookingreason: v,
    });
  };
  handlecahngeCancelbooking = (v) => {
    if (v === "back") {
      this.setState({
        cancelbookings: 0,
      });
      return;
    }
    if (!v) {
      this.handlesidebarCancelbooking();
      window.location.href = "/profile";
      // this.setState({
      //   cancelbookings: 0,
      // });
      return;
    }
    this.setState({
      cancelbookings: v,
    });
  };

  getPractionerName(val) {
    if ((val?.resourcetype || "")?.toLowerCase() === "practitioner") {
      return makeName(val?.resourcecode?.name?.[0] ?? {});
    }
    return val?.resourcecode?.shortdesc;
  }

  tableHead = ["S.no", "appointment number", "Date & Time"];

  appointmentSummaryPDF = async () => {
    await this.downloadPDF("pdf");
  };

  downloadPDF = (divName) => {
    return new Promise(async (resolve, reject) => {
      const html2pdf = require("html2pdf.js");
      var dateObj = new Date();
      var month = dateObj.getUTCMonth() + 1; //months from 1-12
      var day = dateObj.getUTCDate();
      var year = dateObj.getUTCFullYear();
      var newdate = year + "_" + month + "_" + day;
      var printContents = document.getElementById(divName).innerHTML;
      var opt = {
        filename: `appointment_booking_${newdate}.pdf`,
        // image: { type: "jpg", quality: 1 },
        html2canvas: {
          dpi: 72,
          letterRendering: true,
          useCORS: true,
          proxy: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        // pdfCallback: pdfCallback
      };
      html2pdf().set(opt).from(printContents).save();
    });
  };

  appointmentDetails = () => {
    if (this.props?.location?.state?.isMulti) {
      return this.state?.appArr?.map((l) => {
        return {
          ...l,
          dateTimeConverted: utcTOLocal(l?.dateTime, "DD MMM YYYY | hh:mm a"),
          appointmentId: l?.appointmentNumber,
        };
      });
    } else {
      return [
        {
          dateTimeConverted:
            this.state?.appointmentInfo?.resourcetype?.toLowerCase() ===
            "practitioner"
              ? utcTOLocal(
                  this.state.appointmentInfo?.start || 0,
                  "DD MMM YYYY | hh:mm a"
                )
              : `${utcTOLocal(
                  this.state.appointmentInfo?.start || 0,
                  "DD MMM YYYY"
                )} | ${utcTOLocal(
                  this.state.appointmentInfo?.start || 0,
                  "hh:mm a"
                )} - ${utcTOLocal(
                  this.state.appointmentInfo?.end || 0,
                  "hh:mm a"
                )}`,
          // time:'09:00 am',
          appointmentId: `${this.state.appointmentInfo?.appno || "---"}`,
        },
      ];
    }
  };

  appointmentDownloadPDFData = () => {
    const data = {
      personDetails: {
        phoneNo:
          this.state?.appointmentInfo?.AppointmentNotification?.[0]?.mobile ??
          "",
        emailId:
          this.state.appointmentInfo?.AppointmentNotification?.[0]
            ?.patientEmail ?? "",
        patientName: makeName(
          this.state.appointmentInfo?.Person?.[0]?.name?.[0]
        ),
        age: `${convertTime(
          this.state.appointmentInfo?.Person?.[0]?.birthDay,
          "DD/MM/YYYY",
          "years",
          "diff"
        )} Yrs`,
        gender: this.state.appointmentInfo?.Person?.[0]?.gender,
        profile_pic:
          getImgUrl(
            this.state.appointmentInfo?.Person?.[0]?.photo?.[0]?.fileid
          ) || undefined,
      },
      doctorDetails: {
        doctorName: this.getPractionerName(this.state.appointmentInfo),
        speciality:
          this.state.appointmentInfo?.Speciality?.[0]?.coding?.[0]?.display ||
          " ",
        address: this.state.appointmentInfo?.orgid?.name || "",
        profile_pic:
          getImgUrl(
            this.state.appointmentInfo?.resourcecode?.photo?.[0]?.fileid
          ) || undefined,
      },
      hospitalDetails: {
        hospitalName: this.state.appointmentInfo?.orgid?.name ?? "",
        hospitalAddress: {
          street: this.state.appointmentInfo?.orgid?.address?.[0]?.line ?? "",
          city:
            this.state.appointmentInfo?.orgid?.address?.[0]?.city
              ?.geogLevelName ?? "",
          state:
            this.state.appointmentInfo?.orgid?.address?.[0]?.state
              ?.geogLevelName ?? "",
          pincode:
            this.state.appointmentInfo?.orgid?.address?.[0]?.postalCode
              ?.geogLevelName ?? "",
          phoneNo:
            this.state.genInst?.orgContact ??
            this.state.appointmentInfo?.orgid?.contact?.[0]?.telecom?.[0]
              ?.value ??
            "",
        },
      },
      appointmentDetails: this.appointmentDetails(),
      generalInstructions: this.state.genInst?.generalInstruction || [],
      // appointmentDetails:{
      //   date:'07 Apr 2021',
      //   time:'09:00 am',
      //   appointmentId:'AP-123456',
      // }
    };
    return data;
  };

  getBase64Url = (divName) => {
    return new Promise(async (resolve, reject) => {
      const html2pdf = require("html2pdf.js");
      var dateObj = new Date();
      var month = dateObj.getUTCMonth() + 1; //months from 1-12
      var day = dateObj.getUTCDate();
      var year = dateObj.getUTCFullYear();
      var newdate = year + "_" + month + "_" + day;
      var printContents = document.getElementById(divName).innerHTML;
      var opt = {
        filename: `appointment_booking_${newdate}.pdf`,
        //image: { type: "jpeg", quality: 1 },
        html2canvas: {
          dpi: 72,
          letterRendering: true,
          useCORS: true,
          proxy: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        // pdfCallback: pdfCallback
      };
      await html2pdf()
        .from(printContents)
        .set(opt)
        .toPdf()
        .output("datauristring")
        .then((val) => {
          resolve(val);

          this.props.SHAREEMAIL({
            emails: this.state.emailId,
            file_url: val,
          });
          this.setState({ loading: false, emailId: "" });
          this.handleClose();
        })
        .catch((err) => {
          resolve(null);
        });
    });
  };

  handleClickOpen = () => {
    this.setState({
      modal: true,
    });
  };

  handleClose = () => {
    this.setState({
      modal: false,
    });
  };

  onChange = (v) => {
    this.setState({
      emailId: v,
      err: false,
    });
  };
  handleSend = () => {
    if (ValidateEmail(this.state.emailId) && this.state.emailId) {
      this.setState({ loading: true });
      this.getBase64Url("pdf");
    } else {
      this.setState({
        err: true,
      });
    }
  };

  handleOnBeforeGetContent = () => {
    this.setState({ isLoading: true });
  };

  handleAfterPrint = () => {
    this.setState({ isLoading: false });
  };
  render() {
    const {
      container,
      tickSvg,
      appointmentBooking,
      confirmation,
      wrapper,
      h6,
      span,
      barCode,
      flexColumn,
      flexRow,
      divider,
      iconSpan,
      containedButton,
      clickHere,
      multipleAppointmentsWrapper,
      veticalDiv,
      videoIcon,
      // gustuser,
    } = this.styles;
    const { cancelbooking, cancelbookingreason, cancelbookings } = this.state;
    const { parent_id } = this.props;
    const Parent_id = parent_id ? parent_id : "";

    const isVideo =
      this.state.appointmentInfo?.consultationMode?.toLowerCase?.() ===
      "video consultation";

    const nurseTriagerequired = localStorage.getItem("nurseTriagerequired");

    return (
      <LoadingComp loading={this.state.loader}>
        <Container
          id={`${Parent_id}confirmbooking_parent_container`}
          name="container"
          fluid={false}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height:
              this.props.location?.state?.modfieded ||
              this.props.location?.state?.cancel
                ? "100vh"
                : "auto",
          }}
        >
          <Div
            id={`${Parent_id}confirmbooking_parent_div`}
            inLineStyles={container}
          >
            <Div
              id={`${Parent_id}confirmbooking_instagram_div`}
              style={tickSvg}
            >
              <Image
                id={`${Parent_id}confirmbooking_instagram_image`}
                src="images/icons8-instagram-verification-badge.svg"
                alt="tick"
              />
            </Div>
            <H6
              id={`${Parent_id}confirmbooking_your_visit_label_h6`}
              className="pc_semibold"
              style={appointmentBooking}
            >
              {this.props.appoinmentBooking ||
              this.props?.location?.state?.fromListing ? (
                "Your Appointment Has Been Booked"
              ) : (
                <>
                  Your{" "}
                  {!this.props.location?.state?.visit
                    ? // && !this.state.pemissions?.write?.indexOf("downloadAppointment") > -1
                      "appointment"
                    : "visit"}{" "}
                  has been{" "}
                  {this.props.location?.state?.cancel
                    ? "cancelled"
                    : this.props.location?.state?.modfied ||
                      this.props.location?.state?.modfieded
                    ? "modified"
                    : !this.props.location?.state?.from
                    ? // && !this.state.pemissions?.write?.indexOf("downloadAppointment") > -1
                      "booked"
                    : "registered"}{" "}
                  !
                </>
              )}
            </H6>
            <H6
              id={`${Parent_id}confirmbooking_confrimation_label_h6`}
              className="pc_regular"
              style={confirmation}
            >
              Confirmation has been sent to your{" "}
              {
                this.state?.appointmentInfo?.AppointmentNotification?.[0]
                  ?.mobile
              }{" "}
              {this.state.appointmentInfo?.AppointmentNotification?.[0]
                ?.patientEmail && "&"}
              <br />
              {
                this.state.appointmentInfo?.AppointmentNotification?.[0]
                  ?.patientEmail
              }
            </H6>
            {/* <H6 className="pc_regular" style={gustuser}>
            <span style={{ marginRight: "2%" }}>
              <Gustuserconfirm />
            </span>{" "}
            You're a registered user now, you can log in using
            <br /> your mobile number (9894541371).
          </H6> */}

            {this.props?.location?.state?.isMulti ? (
              <Div id={`${Parent_id}confirmbooking_patientdetails_div`}>
                <Div
                  id={`${Parent_id}confirmbooking_patientdetails_sub_div`}
                  style={multipleAppointmentsWrapper}
                >
                  <PatientDetails
                    parent_id={"patient_dashboard_confirmbooking"}
                    // url="https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                    name={this.getPractionerName(this.state.appointmentInfo)}
                    title={
                      this.state.appointmentInfo?.Speciality?.[0]?.coding?.[0]
                        ?.display || " "
                    }
                    url={getImgUrl(
                      this.state.appointmentInfo?.resourcecode?.photo?.[0]
                        ?.fileid
                    )}
                    subTitle={this.state.appointmentInfo?.orgid?.name || ""}
                  />
                  <Div style={veticalDiv} />
                  <PatientDetails
                    parent_id={"patient_dashboard_confirmbooking"}
                    // url="https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                    name={makeName(
                      this.state.appointmentInfo?.Person?.[0]?.name?.[0]
                    )}
                    url={getImgUrl(
                      this.state.appointmentInfo?.Person?.[0]?.photo?.[0]
                        ?.fileid
                    )}
                    title={`${convertTime(
                      this.state.appointmentInfo?.Person?.[0]?.birthDay,
                      "DD/MM/YYYY",
                      "years",
                      "diff"
                    )} Yrs | ${
                      this.state.appointmentInfo?.Person?.[0]?.gender
                    }`}
                    subTitle={
                      this.state.appointmentInfo?.AppointmentNotification?.[0]
                        ?.mobile
                    }
                  />
                </Div>
                <Div style={{ marginBottom: 20 }}>
                  <AppointmentTable
                    tableHead={this.tableHead}
                    tableData={this.state.appArr}
                    parent_id={"patient_dashboard_confirmbooking"}
                  />
                </Div>
              </Div>
            ) : (
              <Div
                id={`${Parent_id}confirmbooking_converttime_div`}
                style={wrapper}
              >
                <Div
                  id={`${Parent_id}confirmbooking_converttime_sub_div`}
                  style={{ ...flexColumn, alignItems: "center" }}
                >
                  {!isVideo ? (
                    <Div
                      id={`${Parent_id}confirmbooking_upload_image_div`}
                      style={barCode}
                    >
                      <Image
                        id={`${Parent_id}confirmbooking_upload_image`}
                        alt="Image"
                        // src="https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg"
                        src={`https://api.qrserver.com/v1/create-qr-code/?size=170x170&data=${this.state.appointmentInfo?.id}`}
                      />
                    </Div>
                  ) : (
                    <Div id="confirmbook_video_div" style={videoIcon}>
                      <Image
                        id="confirmbook_video_image"
                        alt="Image"
                        inLineStyles={{
                          height: "30px",
                          width: "30px",
                        }}
                        // src="https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg"
                        src={videoOutline}
                      />
                    </Div>
                  )}
                  <H6
                    id={`${Parent_id}confirmbooking_datetime_format_h6`}
                    className="pc_medium"
                    style={h6}
                  >
                    {
                      // convertTime(this.state.appointmentInfo?.start || 0, "DD/MM/YYYY HH:mm:ss", "DD MMM YYYY | hh:mm a")

                      this.state?.appointmentInfo?.resourcetype?.toLowerCase() ===
                      "practitioner"
                        ? utcTOLocal(
                            this.state.appointmentInfo?.start || 0,
                            "DD MMM YYYY | hh:mm a"
                          )
                        : `${utcTOLocal(
                            this.state.appointmentInfo?.start || 0,
                            "DD MMM YYYY"
                          )} | ${utcTOLocal(
                            this.state.appointmentInfo?.start || 0,
                            "hh:mm a"
                          )} - ${utcTOLocal(
                            this.state.appointmentInfo?.end || 0,
                            "hh:mm a"
                          )}`
                    }
                  </H6>
                  <H6
                    id={`${Parent_id}confirmbooking_AP_h6`}
                    className="pc_regular"
                    style={{ ...span, margin: "5px 0px" }}
                  >
                    {this.state.appointmentInfo?.appno || "---"}
                    {!this.props.hidden && isVideo && (
                      <span
                        id="confirmbook_appointmnet_info_span"
                        className="pc_regular"
                        style={{ ...span, color: "#C44846" }}
                      >
                        {", Not-Paid"}
                      </span>
                    )}
                  </H6>
                  {/* Data For Price */}
                  {isVideo && (
                    <Div
                      style={{
                        background: "#FFF9F2 0% 0% no-repeat padding-box",
                        borderRadius: "20px",
                        padding: "3px 12px",
                      }}
                    >
                      <H6
                        id="confirmbook_appointmnet_info_video_h6"
                        className="pc_regular"
                        style={{
                          ...span,
                          margin: "5px 0px",
                          color: "#F58B00",
                        }}
                      >
                        Passcode :{" "}
                        {this.state.appointmentInfo?.avc?.passCode || "------"}
                      </H6>
                    </Div>
                  )}
                </Div>
                <Div
                  id={`${Parent_id}confirmbooking_details_div`}
                  style={{
                    borderLeft: "1px dashed #E0E0E0",
                    flex: 1,
                  }}
                >
                  <PatientDetails
                    url={getImgUrl(
                      this.state.appointmentInfo?.resourcecode?.photo?.[0]
                        ?.fileid
                    )}
                    parent_id={"patient_dashboard_confirmbooking"}
                    // url="https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                    name={this.getPractionerName(this.state.appointmentInfo)}
                    title={
                      this.state.appointmentInfo?.Speciality?.[0]?.coding?.[0]
                        ?.display || " "
                    }
                    subTitle={this.state.appointmentInfo?.orgid?.name || ""}
                  />
                  <Divider
                    id={`${Parent_id}confirmbooking_bottom_divider`}
                    style={divider}
                  />
                  <PatientDetails
                    parent_id={"patient_dashboard_confirmbooking"}
                    url={getImgUrl(
                      this.state.appointmentInfo?.Person?.[0]?.photo?.[0]
                        ?.fileid
                    )}
                    // url="https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                    name={makeName(
                      this.state.appointmentInfo?.Person?.[0]?.name?.[0]
                    )}
                    title={`${convertTime(
                      this.state.appointmentInfo?.Person?.[0]?.birthDay,
                      "DD/MM/YYYY",
                      "years",
                      "diff"
                    )} Yrs | ${
                      this.state.appointmentInfo?.Person?.[0]?.gender
                    }`}
                    subTitle={
                      this.state.appointmentInfo?.AppointmentNotification?.[0]
                        ?.mobile
                    }
                  />
                </Div>
              </Div>
            )}
            {this.props.location?.state?.reason && (
              <Div>
                <Div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "15px 0px 6px",
                  }}
                >
                  <Div
                    style={{ flex: 1, borderBottom: "1.9px solid #E0E0E0" }}
                  ></Div>
                  <H6
                    style={{
                      padding: "0px 10px",
                      fontSize: 14,
                      fontFamily: "pc_medium !important",
                    }}
                  >
                    Reason for{" "}
                    {this.props.location?.state?.cancel
                      ? "cancellation"
                      : "Revision"}
                  </H6>
                  <Div
                    style={{ flex: 1, borderBottom: "1.9px solid #E0E0E0" }}
                  ></Div>
                </Div>
                <Div
                  style={{
                    border: "1.9px solid #e0e0e0",
                    padding: 10,
                    borderRadius: 8,
                    margin: "14px 0px",
                    textAlign: "center",
                  }}
                >
                  {this.props.location?.state?.reason}
                </Div>
              </Div>
            )}
            <Div
              id={`${Parent_id}confirmbooking_bottom_sub_title_div`}
              style={{
                ...flexRow,
                justifyContent:
                  this.props.location?.state?.unregister ||
                  !this.state.pemissions?.write?.indexOf(
                    "downloadAppointment"
                  ) > -1
                    ? "space-between"
                    : "space-between",
              }}
            >
              {!this.props.location?.state?.unregister && (
                <>
                  {this.state.pemissions?.write?.indexOf(
                    "downloadAppointment"
                  ) > -1 && (
                    <Div
                      id={`${Parent_id}confirmbooking_title_div`}
                      style={{ ...flexRow, cursor: "pointer" }}
                    >
                      <Icons
                        id={`${Parent_id}confirmbooking_download_image`}
                        fontIcon="download"
                        ariaHidden="true"
                        size="small"
                        style={{ color: UIColor.secondaryButtonColor.color }}
                      />
                      <Text
                        id={`${Parent_id}confirmbooking_summary_text`}
                        className="pc_regular"
                        style={iconSpan}
                        onClick={() => this.appointmentSummaryPDF()}
                      >
                        Appointment Summary
                      </Text>
                    </Div>
                  )}
                  {this.state.pemissions?.write?.indexOf("downloadInvoice") >
                    -1 && (
                    <Div
                      id={`${Parent_id}confirmbooking_invoice_div`}
                      style={{ ...flexRow, cursor: "pointer" }}
                    >
                      <Icons
                        id={`${Parent_id}confirmbooking_invoice_download_image`}
                        fontIcon="download"
                        ariaHidden="true"
                        size="small"
                        style={{ color: UIColor.secondaryButtonColor.color }}
                      />
                      <Text
                        id={`${Parent_id}confirmbooking_invoice_text`}
                        className="pc_regular"
                        style={iconSpan}
                      >
                        Invoice
                      </Text>
                    </Div>
                  )}
                </>
              )}
              {this.state.pemissions?.write?.indexOf("print") > -1 && (
                <ReactToPrint
                  onBeforeGetContent={this.handleOnBeforeGetContent}
                  onAfterPrint={this.handleAfterPrint}
                  trigger={() => (
                    <Div
                      id={`${Parent_id}confirmbooking_print_div`}
                      style={{
                        ...flexRow,
                        cursor: "pointer",
                        padding:
                          this.props.location?.state?.unregister ||
                          !this.state.pemissions?.write?.indexOf(
                            "downloadAppointment"
                          ) > -1
                            ? "0px 30px"
                            : "0px 0px",
                      }}
                      onClick={() => this.handlePrint()}
                    >
                      <Icons
                        id={`${Parent_id}confirmbooking_print_image`}
                        fontIcon="print"
                        ariaHidden="true"
                        size="small"
                        style={{ color: UIColor.secondaryButtonColor.color }}
                      />
                      <Text
                        id={`${Parent_id}confirmbooking_print_text`}
                        className="pc_regular"
                        style={iconSpan}
                      >
                        {this.state.isLoading ? "Enabling Print..." : "Print"}
                      </Text>
                    </Div>
                  )}
                  content={() => this.componentRef}
                />
              )}
              {this.state.pemissions?.write?.indexOf("share") > -1 && (
                <Div
                  id={`${Parent_id}confirmbooking_share_div`}
                  style={{ ...flexRow, cursor: "pointer" }}
                  onClick={() => this.handleClickOpen()}
                >
                  <Icons
                    id={`${Parent_id}confirmbooking_share_image`}
                    fontIcon="share"
                    ariaHidden="true"
                    size="small"
                    style={{ color: UIColor.secondaryButtonColor.color }}
                  />
                  <Text
                    id={`${Parent_id}confirmbooking_share_text`}
                    className="pc_regular"
                    style={iconSpan}
                  >
                    Share
                  </Text>
                </Div>
              )}
            </Div>
            {this.props.hidden && isVideo && (
              <>
                <Divider
                  id={`video_app_payment_divder`}
                  key={"1"}
                  textWidth={"50"}
                  textColor={"#000"}
                  borderColor={"#E0E0E0"}
                  borderWidth={"1"}
                  orientation={"horizontal"}
                  variant={"middle"}
                />
                <Div
                  id={`video_app_payment_div`}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Div
                    id={`video_app_payment_sub_div`}
                    style={{
                      display: "flex",
                    }}
                  >
                    <Image
                      id="confirmbook_payment_image"
                      alt="Image"
                      inLineStyles={{
                        height: "30px",
                        width: "30px",
                      }}
                      // src="https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg"
                      src={payment}
                    />
                    <H6
                      id="confirmbook_video_payemtn_msg"
                      className="pc_regular"
                      style={{
                        color: "#101010",
                        fontSize: "12px",
                        width: "50%",
                        marginLeft: "15px",
                        fontWeight: 600,
                      }}
                    >
                      Would you like to pay for the appointment now?
                    </H6>
                  </Div>
                  <Button
                    id="confirmbook_video_payment_pay_now"
                    className="pc_medium"
                    inLineStyles={{
                      ...containedButton,
                    }}
                  >
                    Pay Now
                  </Button>
                </Div>
              </>
            )}
            {/* {
            !this.props.hidden
            && isVideo
            && !this.props.multipleAppointments
            && (
              this.props.location?.state?.fromListing
            )
            && !this.props.location?.state?.reason
            && (
            <>
              <Divider
                id={`video_app_divder`}
                key={"1"}
                textWidth={"50"}
                textColor={"#000"}
                borderColor={"#E0E0E0"}
                borderWidth={"1"}
                orientation={"horizontal"}
                variant={"middle"}
              />
              <Div
                id="confirmbook_video_div"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  id="confirmbook_video_div_icon"
                  alt="Image"
                  inLineStyles={{
                    height: "30px",
                    width: "30px",
                    marginBottom: "10px",
                  }}
                  // src="https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg"
                  src={videoFilled}
                />
                <H6
                  id="confirmbook_video_message"
                  className="pc_regular"
                  style={{ ...span, ...flexRow }}
                >
                  Do you want to join consultation meeting link now?
                </H6>
                <Div
                  id="confirmbook_video_link"
                  className="pc_regular"
                  style={{
                    ...span,
                    marginTop: "10px",
                    color: UIColor.secondaryButtonColor.color,
                    fontStyle: "italic",
                    width: "60%",
                    wordWrap: "break-word",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(this.state.appointmentInfo?.avc?.url, "_blank");
                  }}
                >
                  {this.state.appointmentInfo?.avc?.url || ""}
                </Div>
                <Div
                  id="confirmbook_video_password"
                  className="pc_regular"
                  style={{
                    ...span,
                    marginTop: "10px",
                    color: "#F58B00",
                  }}
                >
                  Passcode : ------
                </Div>
                <Button
                  id="confirmbook_video_join+now"
                  className="pc_medium"
                  onClick={() => {
                    window.open(this.state.appointmentInfo?.avc?.url, "_blank");
                  }}
                  inLineStyles={{
                    ...containedButton,
                    marginTop: "10px",
                  }}
                >
                  Join Now
                </Button>
              </Div>
              <Divider
                id={`video_app_divder`}
                key={"1"}
                textWidth={"50"}
                textColor={"#000"}
                borderColor={"#E0E0E0"}
                borderWidth={"1"}
                orientation={"horizontal"}
                variant={"middle"}
              />
            </>
          )} */}

            {!this.props.hidden && (
              <Div
                id={`${Parent_id}confirmbooking_button_div`}
                style={{
                  ...flexRow,
                  justifyContent: "center",
                  margin: "30px 0px",
                }}
              >
                {!this.props?.location?.state?.unregister && (
                  <Button
                    id={`${Parent_id}confirmbooking_go_profile_buttom`}
                    className="pc_medium"
                    inLineStyles={containedButton}
                    onClick={() => {
                      this.props.history.push({
                        pathname: Routes.PatientDashBoard,
                        state: {
                          userId:
                            this.state.appointmentInfo?.Person?.[0]?.Id ||
                            this.props.location?.state?.personId ||
                            0,
                          mobileNumber:
                            this.props.location?.state?.userNo ||
                            this.state.appointmentInfo?.Person?.[0]?.telecom?.find(
                              (val) =>
                                val.system === "Phone" && val.use === "Mobile"
                            )?.value,
                          unregister:
                            this.props.location?.state?.unregister ||
                            !this.state.pemissions?.write?.indexOf(
                              "downloadAppointment"
                            ) > -1,
                        },
                      });
                    }}
                  >
                    Go To Profile
                  </Button>
                )}
                &nbsp;&nbsp;
                {!this.props?.location?.state?.fromListing && (
                  <Button
                    id={`${Parent_id}confirmbooking_mark_buttom`}
                    className="pc_medium"
                    onClick={
                      this.props.location?.state?.modfieded ||
                      this.props.location?.state?.cancel ||
                      this.props.location?.state?.unregister
                        ? () => (window.location.href = Routes.dashboard)
                        : async () => {
                            await this.updateStatus(
                              nurseTriagerequired === "true" ||
                                this.state.permission__?.write?.indexOf(
                                  "doctorQueue"
                                ) > -1
                                ? "completed_assemble"
                                : "completed_nurse",
                              this.props.location?.state?.appointmentId ||
                                this.props.appointmentId ||
                                this.props.appointmentId
                            );
                            window.location.href = Routes.dashboard;
                          }
                    }
                    inLineStyles={containedButton}
                  >
                    {this.props.location?.state?.modfieded ||
                    this.props.location?.state?.cancel ||
                    this.props.location?.state?.unregister
                      ? "Back To Home"
                      : "Mark As Completed"}
                  </Button>
                )}
              </Div>
            )}
            {/* {!this.props.hidden && (
            <Div id={`${Parent_id}confirmbooking_click_div`}>
              <H6
                id={`${Parent_id}confirmbooking_want_cancel_h6`}
                className="pc_regular"
                style={{ ...span, ...flexRow, justifyContent: "center" }}
              >
                Want to cancel appointment ?
                <H6
                  id={`${Parent_id}confirmbooking_click_h6`}
                  style={clickHere}
                  onClick={() => this.handlesidebarCancelbooking()}
                >
                  Click here
                </H6>
              </H6>
            </Div>
          )} */}
          </Div>
          <SideMenu
            id={`${Parent_id}confirmbooking_sidemenu`}
            open={cancelbooking}
            direction="right"
            width={435}
            color="default"
            onClose={() => this.handlesidebarCancelbooking()}
          >
            <CancelBookingPage
              parent_id={"patient_dashboard"}
              cancelbookingreason={cancelbookingreason}
              appointmentId={
                this.props.location?.state?.appointmentId ||
                this.props.appointmentId
              }
              cancelbookings={cancelbookings}
              handleSelectCancelbooking={this.handleSelectCancelbooking}
              handlecahngeCancelbooking={this.handlecahngeCancelbooking}
            />
          </SideMenu>
          <AppointmentSummaryPDF
            data={this.appointmentDownloadPDFData()}
            ref={(el) => (this.componentRef = el)}
          />
          <EmailDialog
            value={this.state.emailId}
            onChange={this.onChange}
            handleClose={this.handleClose}
            open={this.state.modal}
            handleSend={this.handleSend}
            error={this.state.err}
            loading={this.state.loading}
            helperText={"Please Enter Vaild Email ID"}
          />
        </Container>
      </LoadingComp>
    );
  }
}

const mapStateToProps = (state) => ({
  appointmentInfo: state?.appointmentReducer?.readAppointment,
});

export default connect(mapStateToProps, actions)(withRouter(ConfirmationPage));
