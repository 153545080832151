import React, { useState } from "react";
import { predialysis } from "../vitalsJson";
import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { TextInput } from "qdm-component-library";
import { UIColor } from "../../../utils";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Autocomplete } from "@material-ui/lab";

const styles = {
  labelStyle: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: UIColor.lineBorderFill.color,
  },
  errorborderStyle: {
    borderRadius: 8,
    borderColor: "red",
  },
  inputStyle: {
    padding: 5,
    marginBottom: 20,
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
  },
  inputFiledStyle: {
    borderColor: UIColor.lineBorderFill.color,
    borderRadius: "8px",
  },
  actionButton: {
    backgroundColor: "#FFFFFF",
    border: "solid 1px #DEDEDE",
    borderRadius: 6,
    color: "black",
    marginBottom: 8,
    boxShadow: "none",
    "&:hover": {},
    "&:first-child": {
      backgroundColor: "#FF4D4A",
      borderColor: "#FF4D4A",
      color: "#ffffff",
      fontWeight: 600,
    },
  },
  autocomplete: {
    "& .MuiInputBase-input": {
      color: UIColor.secondaryText.color,
      fontSize: "12px !important",
      fontFamily: "pc_regular!important",
    },
    "& .MuiInputBase-root": {
      //height: 40,
      color: UIColor.secondaryText.color,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
        borderRadius: 8,
      },
      "&:hover fieldset": {
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 1,
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
      },
    },
    "& .MuiAutocomplete-popupIndicator": {
      transform: "none !important",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    "& .MuiInputBase-input": {
      color: UIColor.secondaryText.color,
      fontSize: "12px !important",
      fontFamily: "pc_regular!important",
    },
    "& .MuiInputBase-root": {
      //height: 40,
      color: UIColor.secondaryText.color,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
        borderRadius: 8,
      },
      "&:hover fieldset": {
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 1,
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
      },
    },
    "& .MuiAutocomplete-popupIndicator": {
      transform: "none !important",
    },
  },
}));
function Adddialysistype({
  data = [],
  handlesave = () => false,
  statekey = {},
  handlecancel = () => false,
  handleEdit = () => false,
  edit = false,
  editstate = {},
}) {
  const [state, setState] = useState({});
  const classes = useStyles();
  React.useEffect(() => {
    if (edit) {
      setState(editstate);
    } else {
      setState(data);
    }
  }, [edit]);
  const handlechange = (statekey, key, value) => {
    try {
      let obj = JSON.parse(JSON.stringify(state));
      obj[key].obsvalue = value;
      setState(obj);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div style={{ margin: "10px", padding: "10px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5" style={{ textTransform: "capitalize" }}>
          {statekey}
        </Typography>
        <IconButton onClick={handlecancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <Grid container alignItems="center" spacing={2}>
        {Object.keys(data)?.map((v, i) => {
            const groupname = data[v]?.groupname;
            const isFirstInGroup =
              i === 0 ||
              data?.[Object.keys(data)[i - 1]]?.groupname !== groupname;
        if (groupname === "Weight") {
          return (
            <>
               {isFirstInGroup && (
                  <Grid item xs={12}>
                    <Typography
                      style={{ fontWeight: "bold", fontSize: "12px" }}
                    >
                      {groupname}
                    </Typography>
                  </Grid>
                )}
            <Grid item key={`${v}-${i}`}>
              {data?.[v]?.type === "text" && (
                <TextInput
                  label={data?.[v]?.shortdesc}
                  size={"small"}
                  type={"text"}
                  onChange={(e) => {
                    handlechange("predialysis", v, e.target.value);
                  }}
                  value={state?.[v]?.obsvalue ?? ""}
                  labelStyle={styles.labelStyle}
                  placeholder={""}
                  style={styles.inputFiledStyle}
                  //hoverColor="#0071F2"
                  hoverColor={UIColor.lineBorderFill.color}
                />
              )}
              {data?.[v]?.type === "autocomplete" && (
                <>
                  <p
                    style={{
                      fontSize: "14px",
                      fontSWeight: "500",
                      margin: "0",
                      color: "#6A7888",
                      fontSize: "12px",
                      marginBottom: "6px",
                      fontFamily: "pc_regular !important",
                    }}
                  >
                    {data?.[v]?.shortdesc}
                  </p>
                  <Autocomplete
                    style={{ width: 190 }}
                    //className={"type"}
                    className={classes.autocomplete}
                    id="size-small-standard"
                    size="small"
                    options={data?.[v]?.dropdownvalues ?? []}
                    popupIcon={
                      <ExpandMoreIcon
                        id={`${v}_allergy_type_expandmoreicon`}
                        fontSize="small"
                        style={{ color: "#b4aeae" }}
                      />
                    }
                    disableClearable
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        id={`${v}_allergy_type_textfield`}
                        {...params}
                        variant="outlined"
                        placeholder=""
                        //label={data?.[v]?.shortdesc}
                        // inputProps={{
                        //   ...params.inputProps,
                        //   style: { fontSize: "14px" },
                        // }}
                      />
                    )}
                    renderOption={(option) => (
                      <Typography
                        id={`${v}_allergy_${option.label}_typography`}
                        style={{ width: "100%" }}
                        noWrap
                      >
                        {option}
                      </Typography>
                    )}
                    classes={{ popper: "muiPopperDropdown" }}
                    value={state?.[v]?.obsvalue ?? ""}
                    onChange={(e, newValue) => {
                      handlechange("predialysis", v, newValue);
                    }}
                  />
                </>
              )}
            </Grid>
            </>
          );
        } else if (groupname === "Heparin") {
          return (
            <>
              {isFirstInGroup && (
                <Grid item xs={12}>
                  <Typography
                    style={{ fontWeight: "bold", fontSize: "12px" }}
                  >
                    {groupname}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                {data?.[v]?.type === "text" && (
                  <TextInput
                    label={data?.[v]?.shortdesc}
                    size={"small"}
                    type={"text"}
                    onChange={(e) => {
                      handlechange("predialysis", v, e.target.value);
                    }}
                    value={state?.[v]?.obsvalue ?? ""}
                    labelStyle={styles.labelStyle}
                    placeholder={""}
                    style={styles.inputFiledStyle}
                    //hoverColor="#0071F2"
                    hoverColor={UIColor.lineBorderFill.color}
                  />
                )}
                {data?.[v]?.type === "autocomplete" && (
                  <>
                    <p
                      style={{
                        fontSize: "14px",
                        fontSWeight: "500",
                        margin: "0",
                        color: "#6A7888",
                        fontSize: "12px",
                        marginBottom: "6px",
                        fontFamily: "pc_regular !important",
                      }}
                    >
                      {data?.[v]?.shortdesc}
                    </p>
                    <Autocomplete
                      style={{ width: 190 }}
                      //className={"type"}
                      className={classes.autocomplete}
                      id="size-small-standard"
                      size="small"
                      options={data?.[v]?.dropdownvalues ?? []}
                      popupIcon={
                        <ExpandMoreIcon
                          id={`${v}_allergy_type_expandmoreicon`}
                          fontSize="small"
                          style={{ color: "#b4aeae" }}
                        />
                      }
                      disableClearable
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          id={`${v}_allergy_type_textfield`}
                          {...params}
                          variant="outlined"
                          placeholder=""
                          //label={data?.[v]?.shortdesc}
                          // inputProps={{
                          //   ...params.inputProps,
                          //   style: { fontSize: "14px" },
                          // }}
                        />
                      )}
                      renderOption={(option) => (
                        <Typography
                          id={`${v}_allergy_${option.label}_typography`}
                          style={{ width: "100%" }}
                          noWrap
                        >
                          {option}
                        </Typography>
                      )}
                      classes={{ popper: "muiPopperDropdown" }}
                      value={state?.[v]?.obsvalue ?? ""}
                      onChange={(e, newValue) => {
                        handlechange("predialysis", v, newValue);
                      }}
                    />
                  </>
                )}
              </Grid>
            </>
          );
        } else if (groupname === "Hd Duration") {
          return (
            <>
              {isFirstInGroup && (
                <Grid item xs={12}>
                  <Typography
                    style={{ fontWeight: "bold", fontSize: "12px" }}
                  >
                    {groupname}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                {data?.[v]?.type === "text" && (
                  <TextInput
                    label={data?.[v]?.shortdesc}
                    size={"small"}
                    type={"text"}
                    onChange={(e) => {
                      handlechange("predialysis", v, e.target.value);
                    }}
                    value={state?.[v]?.obsvalue ?? ""}
                    labelStyle={styles.labelStyle}
                    placeholder={""}
                    style={styles.inputFiledStyle}
                    //hoverColor="#0071F2"
                    hoverColor={UIColor.lineBorderFill.color}
                  />
                )}
                {data?.[v]?.type === "autocomplete" && (
                  <>
                    <p
                      style={{
                        fontSize: "14px",
                        fontSWeight: "500",
                        margin: "0",
                        color: "#6A7888",
                        fontSize: "12px",
                        marginBottom: "6px",
                        fontFamily: "pc_regular !important",
                      }}
                    >
                      {data?.[v]?.shortdesc}
                    </p>
                    <Autocomplete
                      style={{ width: 190 }}
                      //className={"type"}
                      className={classes.autocomplete}
                      id="size-small-standard"
                      size="small"
                      options={data?.[v]?.dropdownvalues ?? []}
                      popupIcon={
                        <ExpandMoreIcon
                          id={`${v}_allergy_type_expandmoreicon`}
                          fontSize="small"
                          style={{ color: "#b4aeae" }}
                        />
                      }
                      disableClearable
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          id={`${v}_allergy_type_textfield`}
                          {...params}
                          variant="outlined"
                          placeholder=""
                          //label={data?.[v]?.shortdesc}
                          // inputProps={{
                          //   ...params.inputProps,
                          //   style: { fontSize: "14px" },
                          // }}
                        />
                      )}
                      renderOption={(option) => (
                        <Typography
                          id={`${v}_allergy_${option.label}_typography`}
                          style={{ width: "100%" }}
                          noWrap
                        >
                          {option}
                        </Typography>
                      )}
                      classes={{ popper: "muiPopperDropdown" }}
                      value={state?.[v]?.obsvalue ?? ""}
                      onChange={(e, newValue) => {
                        handlechange("predialysis", v, newValue);
                      }}
                    />
                  </>
                )}
              </Grid>
            </>
          );
        } else if (groupname === "Flow Rate") {
          return (
            <>
              {isFirstInGroup && (
                <Grid item xs={12}>
                  <Typography
                    style={{ fontWeight: "bold", fontSize: "12px" }}
                  >
                    {groupname}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                {data?.[v]?.type === "text" && (
                  <TextInput
                    label={data?.[v]?.shortdesc}
                    size={"small"}
                    type={"text"}
                    onChange={(e) => {
                      handlechange("predialysis", v, e.target.value);
                    }}
                    value={state?.[v]?.obsvalue ?? ""}
                    labelStyle={styles.labelStyle}
                    placeholder={""}
                    style={styles.inputFiledStyle}
                    //hoverColor="#0071F2"
                    hoverColor={UIColor.lineBorderFill.color}
                  />
                )}
                {data?.[v]?.type === "autocomplete" && (
                  <>
                    <p
                      style={{
                        fontSize: "14px",
                        fontSWeight: "500",
                        margin: "0",
                        color: "#6A7888",
                        fontSize: "12px",
                        marginBottom: "6px",
                        fontFamily: "pc_regular !important",
                      }}
                    >
                      {data?.[v]?.shortdesc}
                    </p>
                    <Autocomplete
                      style={{ width: 190 }}
                      //className={"type"}
                      className={classes.autocomplete}
                      id="size-small-standard"
                      size="small"
                      options={data?.[v]?.dropdownvalues ?? []}
                      popupIcon={
                        <ExpandMoreIcon
                          id={`${v}_allergy_type_expandmoreicon`}
                          fontSize="small"
                          style={{ color: "#b4aeae" }}
                        />
                      }
                      disableClearable
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          id={`${v}_allergy_type_textfield`}
                          {...params}
                          variant="outlined"
                          placeholder=""
                          //label={data?.[v]?.shortdesc}
                          // inputProps={{
                          //   ...params.inputProps,
                          //   style: { fontSize: "14px" },
                          // }}
                        />
                      )}
                      renderOption={(option) => (
                        <Typography
                          id={`${v}_allergy_${option.label}_typography`}
                          style={{ width: "100%" }}
                          noWrap
                        >
                          {option}
                        </Typography>
                      )}
                      classes={{ popper: "muiPopperDropdown" }}
                      value={state?.[v]?.obsvalue ?? ""}
                      onChange={(e, newValue) => {
                        handlechange("predialysis", v, newValue);
                      }}
                    />
                  </>
                )}
              </Grid>
            </>
          );
        } else if (groupname === "Dialyzer") {
          return (
            <>
              {isFirstInGroup && (
                <Grid item xs={12}>
                  <Typography
                    style={{ fontWeight: "bold", fontSize: "12px" }}
                  >
                    {groupname}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                {data?.[v]?.type === "text" && (
                  <TextInput
                    label={data?.[v]?.shortdesc}
                    size={"small"}
                    type={"text"}
                    onChange={(e) => {
                      handlechange("predialysis", v, e.target.value);
                    }}
                    value={state?.[v]?.obsvalue ?? ""}
                    labelStyle={styles.labelStyle}
                    placeholder={""}
                    style={styles.inputFiledStyle}
                    //hoverColor="#0071F2"
                    hoverColor={UIColor.lineBorderFill.color}
                  />
                )}
                {data?.[v]?.type === "autocomplete" && (
                  <>
                    <p
                      style={{
                        fontSize: "14px",
                        fontSWeight: "500",
                        margin: "0",
                        color: "#6A7888",
                        fontSize: "12px",
                        marginBottom: "6px",
                        fontFamily: "pc_regular !important",
                      }}
                    >
                      {data?.[v]?.shortdesc}
                    </p>
                    <Autocomplete
                      style={{ width: 190 }}
                      //className={"type"}
                      className={classes.autocomplete}
                      id="size-small-standard"
                      size="small"
                      options={data?.[v]?.dropdownvalues ?? []}
                      popupIcon={
                        <ExpandMoreIcon
                          id={`${v}_allergy_type_expandmoreicon`}
                          fontSize="small"
                          style={{ color: "#b4aeae" }}
                        />
                      }
                      disableClearable
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          id={`${v}_allergy_type_textfield`}
                          {...params}
                          variant="outlined"
                          placeholder=""
                          //label={data?.[v]?.shortdesc}
                          // inputProps={{
                          //   ...params.inputProps,
                          //   style: { fontSize: "14px" },
                          // }}
                        />
                      )}
                      renderOption={(option) => (
                        <Typography
                          id={`${v}_allergy_${option.label}_typography`}
                          style={{ width: "100%" }}
                          noWrap
                        >
                          {option}
                        </Typography>
                      )}
                      classes={{ popper: "muiPopperDropdown" }}
                      value={state?.[v]?.obsvalue ?? ""}
                      onChange={(e, newValue) => {
                        handlechange("predialysis", v, newValue);
                      }}
                    />
                  </>
                )}
              </Grid>
            </>
          );
        }  else if (groupname === "Hourly Monitoring") {
          return (
            <>
              {isFirstInGroup && (
                <Grid item xs={12}>
                  <Typography
                    style={{ fontWeight: "bold", fontSize: "12px" }}
                  >
                    {groupname}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                {data?.[v]?.type === "text" && (
                  <TextInput
                    label={data?.[v]?.shortdesc}
                    size={"small"}
                    type={"text"}
                    onChange={(e) => {
                      handlechange("predialysis", v, e.target.value);
                    }}
                    value={state?.[v]?.obsvalue ?? ""}
                    labelStyle={styles.labelStyle}
                    placeholder={""}
                    style={styles.inputFiledStyle}
                    //hoverColor="#0071F2"
                    hoverColor={UIColor.lineBorderFill.color}
                  />
                )}
                {data?.[v]?.type === "autocomplete" && (
                  <>
                    <p
                      style={{
                        fontSize: "14px",
                        fontSWeight: "500",
                        margin: "0",
                        color: "#6A7888",
                        fontSize: "12px",
                        marginBottom: "6px",
                        fontFamily: "pc_regular !important",
                      }}
                    >
                      {data?.[v]?.shortdesc}
                    </p>
                    <Autocomplete
                      style={{ width: 190 }}
                      //className={"type"}
                      className={classes.autocomplete}
                      id="size-small-standard"
                      size="small"
                      options={data?.[v]?.dropdownvalues ?? []}
                      popupIcon={
                        <ExpandMoreIcon
                          id={`${v}_allergy_type_expandmoreicon`}
                          fontSize="small"
                          style={{ color: "#b4aeae" }}
                        />
                      }
                      disableClearable
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          id={`${v}_allergy_type_textfield`}
                          {...params}
                          variant="outlined"
                          placeholder=""
                          //label={data?.[v]?.shortdesc}
                          // inputProps={{
                          //   ...params.inputProps,
                          //   style: { fontSize: "14px" },
                          // }}
                        />
                      )}
                      renderOption={(option) => (
                        <Typography
                          id={`${v}_allergy_${option.label}_typography`}
                          style={{ width: "100%" }}
                          noWrap
                        >
                          {option}
                        </Typography>
                      )}
                      classes={{ popper: "muiPopperDropdown" }}
                      value={state?.[v]?.obsvalue ?? ""}
                      onChange={(e, newValue) => {
                        handlechange("predialysis", v, newValue);
                      }}
                    />
                  </>
                )}
              </Grid>
            </>
          );
        } else if (groupname === "Complication") {
          return (
            <>
              {isFirstInGroup && (
                <Grid item xs={12}>
                  <Typography
                    style={{ fontWeight: "bold", fontSize: "12px" }}
                  >
                    {groupname}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                {data?.[v]?.type === "text" && (
                  <TextInput
                    label={data?.[v]?.shortdesc}
                    size={"small"}
                    type={"text"}
                    onChange={(e) => {
                      handlechange("predialysis", v, e.target.value);
                    }}
                    value={state?.[v]?.obsvalue ?? ""}
                    labelStyle={styles.labelStyle}
                    placeholder={""}
                    style={styles.inputFiledStyle}
                    //hoverColor="#0071F2"
                    hoverColor={UIColor.lineBorderFill.color}
                  />
                )}
                {data?.[v]?.type === "autocomplete" && (
                  <>
                    <p
                      style={{
                        fontSize: "14px",
                        fontSWeight: "500",
                        margin: "0",
                        color: "#6A7888",
                        fontSize: "12px",
                        marginBottom: "6px",
                        fontFamily: "pc_regular !important",
                      }}
                    >
                      {data?.[v]?.shortdesc}
                    </p>
                    <Autocomplete
                      style={{ width: 190 }}
                      //className={"type"}
                      className={classes.autocomplete}
                      id="size-small-standard"
                      size="small"
                      options={data?.[v]?.dropdownvalues ?? []}
                      popupIcon={
                        <ExpandMoreIcon
                          id={`${v}_allergy_type_expandmoreicon`}
                          fontSize="small"
                          style={{ color: "#b4aeae" }}
                        />
                      }
                      disableClearable
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          id={`${v}_allergy_type_textfield`}
                          {...params}
                          variant="outlined"
                          placeholder=""
                          //label={data?.[v]?.shortdesc}
                          // inputProps={{
                          //   ...params.inputProps,
                          //   style: { fontSize: "14px" },
                          // }}
                        />
                      )}
                      renderOption={(option) => (
                        <Typography
                          id={`${v}_allergy_${option.label}_typography`}
                          style={{ width: "100%" }}
                          noWrap
                        >
                          {option}
                        </Typography>
                      )}
                      classes={{ popper: "muiPopperDropdown" }}
                      value={state?.[v]?.obsvalue ?? ""}
                      onChange={(e, newValue) => {
                        handlechange("predialysis", v, newValue);
                      }}
                    />
                  </>
                )}
              </Grid>
            </>
          );
        } else if (groupname === "Nursing Notes") {
          return (
            <>
              {isFirstInGroup && (
                <Grid item xs={12}>
                  <Typography
                    style={{ fontWeight: "bold", fontSize: "12px" }}
                  >
                    {groupname}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                {data?.[v]?.type === "text" && (
                  <TextInput
                    label={data?.[v]?.shortdesc}
                    size={"small"}
                    type={"text"}
                    onChange={(e) => {
                      handlechange("predialysis", v, e.target.value);
                    }}
                    value={state?.[v]?.obsvalue ?? ""}
                    labelStyle={styles.labelStyle}
                    placeholder={""}
                    style={styles.inputFiledStyle}
                    //hoverColor="#0071F2"
                    hoverColor={UIColor.lineBorderFill.color}
                  />
                )}
                {data?.[v]?.type === "autocomplete" && (
                  <>
                    <p
                      style={{
                        fontSize: "14px",
                        fontSWeight: "500",
                        margin: "0",
                        color: "#6A7888",
                        fontSize: "12px",
                        marginBottom: "6px",
                        fontFamily: "pc_regular !important",
                      }}
                    >
                      {data?.[v]?.shortdesc}
                    </p>
                    <Autocomplete
                      style={{ width: 190 }}
                      //className={"type"}
                      className={classes.autocomplete}
                      id="size-small-standard"
                      size="small"
                      options={data?.[v]?.dropdownvalues ?? []}
                      popupIcon={
                        <ExpandMoreIcon
                          id={`${v}_allergy_type_expandmoreicon`}
                          fontSize="small"
                          style={{ color: "#b4aeae" }}
                        />
                      }
                      disableClearable
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          id={`${v}_allergy_type_textfield`}
                          {...params}
                          variant="outlined"
                          placeholder=""
                          //label={data?.[v]?.shortdesc}
                          // inputProps={{
                          //   ...params.inputProps,
                          //   style: { fontSize: "14px" },
                          // }}
                        />
                      )}
                      renderOption={(option) => (
                        <Typography
                          id={`${v}_allergy_${option.label}_typography`}
                          style={{ width: "100%" }}
                          noWrap
                        >
                          {option}
                        </Typography>
                      )}
                      classes={{ popper: "muiPopperDropdown" }}
                      value={state?.[v]?.obsvalue ?? ""}
                      onChange={(e, newValue) => {
                        handlechange("predialysis", v, newValue);
                      }}
                    />
                  </>
                )}
              </Grid>
            </>
          );
        } 
        else {
          return (
            <>
              <Grid item>
                {data?.[v]?.type === "text" && (
                  <TextInput
                    label={data?.[v]?.shortdesc}
                    size={"small"}
                    type={"text"}
                    onChange={(e) => {
                      handlechange("predialysis", v, e.target.value);
                    }}
                    value={state?.[v]?.obsvalue ?? ""}
                    labelStyle={styles.labelStyle}
                    placeholder={""}
                    style={styles.inputFiledStyle}
                    //hoverColor="#0071F2"
                    hoverColor={UIColor.lineBorderFill.color}
                  />
                )}
                {data?.[v]?.type === "autocomplete" && (
                  <>
                    <p
                      style={{
                        fontSize: "14px",
                        fontSWeight: "500",
                        margin: "0",
                        color: "#6A7888",
                        fontSize: "12px",
                        marginBottom: "6px",
                        fontFamily: "pc_regular !important",
                      }}
                    >
                      {data?.[v]?.shortdesc}
                    </p>
                    <Autocomplete
                      style={{ width: 190 }}
                      //className={"type"}
                      className={classes.autocomplete}
                      id="size-small-standard"
                      size="small"
                      options={data?.[v]?.dropdownvalues ?? []}
                      popupIcon={
                        <ExpandMoreIcon
                          id={`${v}_allergy_type_expandmoreicon`}
                          fontSize="small"
                          style={{ color: "#b4aeae" }}
                        />
                      }
                      disableClearable
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          id={`${v}_allergy_type_textfield`}
                          {...params}
                          variant="outlined"
                          placeholder=""
                          //label={data?.[v]?.shortdesc}
                          // inputProps={{
                          //   ...params.inputProps,
                          //   style: { fontSize: "14px" },
                          // }}
                        />
                      )}
                      renderOption={(option) => (
                        <Typography
                          id={`${v}_allergy_${option.label}_typography`}
                          style={{ width: "100%" }}
                          noWrap
                        >
                          {option}
                        </Typography>
                      )}
                      classes={{ popper: "muiPopperDropdown" }}
                      value={state?.[v]?.obsvalue ?? ""}
                      onChange={(e, newValue) => {
                        handlechange("predialysis", v, newValue);
                      }}
                    />
                  </>
                )}
              </Grid>
            </>
          );
        }
        })}
      
      </Grid>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <span style={{ display: "flex" }}>
          <Button
            onClick={handlecancel}
            color="primary"
            fullWidth
            className={styles.actionButton}
          >
            cancel
          </Button>{" "}
          <Button
            onClick={() => {
              edit ? handleEdit(statekey, state) : handlesave(statekey, state);
            }}
            color="primary"
            fullWidth
            className={styles.actionButton}
          >
            save
          </Button>
        </span>
      </div>
    </div>
  );
}

export default Adddialysistype;
