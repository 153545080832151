import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Webcam from "react-webcam";
import { LeftJoinDiv } from "./leftJoinDiv";
import { actions } from "primarycare-binder";
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Routes } from "../../../router/routes";
import { DrawerMeetingContext,VisitDetails } from '../../../contexts';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#FFF",
		height: "100vh",
	},
	cameraPreview: {
		backgroundColor: "#424447",
		height: "100vh",
		width: "auto",
		borderTopLeftRadius: 30,
		borderBottomLeftRadius: 30,
		"& video": {
			width: "100%",
		},
	},
	joinTitle: {
		fontSize: 16,
		textAlign: "center",
		fontWeight: 700,
		padding: 0,
		margin: 0,
	},
	TicketCompDiv: {
		backgroundColor: "#FAFCFF",
		height: "100vh",
		padding: "0px 28px",
		display: "grid",
		justifyContent: "center",
		alignItems: "center",
		overflowY: "auto",
		paddingTop: "6%",
	},
	image: { textAlign: "end", paddingTop: 8 },
	p_1: {
		margin: 0,
		fontSize: 15,
		fontWeight: 600,
	},
	p_2: {
		margin: 0,
		fontSize: 14,
		fontWeight: 400,
		color: "#B6B6B6",
	},
	p_3: {
		margin: 0,
		color: "#B6B6B6",
		fontSize: 12,
		color: "#B6B6B6",
	},
	div: {
		display: "flex",
		justifyContent: "center",
	},
	ControlBtn: {
		boxShadow: "0px 3px 8px #0000001A",
		border: "1px solid #F0F0F0",
		borderRadius: 8,
		padding: 10,
		"& svg": {
			fontSize: 20,
		},
	},
	joinBtn: {
		fontSize: 13,
		backgroundColor: "#0071F2",
		padding: "6px 20px",
		color: "white",
		textTransform: "capitalize",
		"&:hover": {
			backgroundColor: "#0071F2",
		},
	},
	large: {
		width: theme.spacing(20),
		height: theme.spacing(20),
	},
	contentDiv: {
		height: "100vh",
		justifyContent: "center",
	},
	contentPersonName: {
		fontSize: 17,
		fontWeight: 600,
		color: "#fff",
	},
}));

export function VideoCall(props) {
	const classes = useStyles();
	const [mic, setMic] = React.useState(false);
	const [video, setVideo] = React.useState(true);
	const [setting, setSetting] = React.useState(false);

	const drawerMeetingContext = React.useContext(DrawerMeetingContext);
	const { setVisitDetails } = React.useContext(VisitDetails);

	const [state,setState] = React.useState({
		info:{}
	})
		
	const dispatch = useDispatch();
	const history = useHistory();

	const ControlBtnClick = (param) => {
		if (param === "mic") {
			setMic(!mic);
		} else if (param === "video") {
			setVideo(!video);
		} else if (param === "setting") {
			setSetting(!setting);
		} else if (param === "meeting") {
			// setMeeting(!stateMeeting);
			videoCall()
		}
	};
	const videoConstraints = {
		width: 1280,
		height: 720,
		facingMode: "user",
	};

	const videoCall = () =>{
		debugger
		drawerMeetingContext.setMeetingDrawer({
			...drawerMeetingContext,
			open: true,
			RoomName: state?.info?.dataJson?.avc?.name,
			jwt: state?.info?.dataJson?.avc?.moderatorUrl?.split("jwt=")?.[1],
			widthAdjState: 'side',
			user_name: props?.location?.state?.name,
			domain: state?.info?.dataJson?.avc?.moderatorUrl?.split("/")?.[2]
		})
		
		if (props?.location?.state?.data?.type === "nurse" || props?.location?.state?.data?.type === "doctor") {
			attendPatient(
			props?.location?.state?.data?.personId,
			props?.location?.state?.data?.patientId,
			props?.location?.state?.data?.token,
			props?.location?.state?.data?.encounterId,
			props?.location?.state?.data?.type,
			props?.location?.state?.data?.pId,
			props?.location?.state?.data?.encounter_id,
			props?.location?.state?.data
			);
		} else {
			registerPatient(
			props?.location?.state?.data?.personId,
			props?.location?.state?.data?.patientId,
			props?.location?.state?.data?.token,
			props?.location?.state?.data?.isPatient
			);
		}

	}

	const attendPatient = (
		personId,
		patientId,
		appointmentId,
		encounterId,
		type,
		pId,
		encounter_id,
		app
	  ) => {
		props.history.push({
		  pathname: Routes.patientDetail,
		  state: {
			encounter_id,
			personId,
			patientId,
			appointmentId,
			encounterId,
			type,
			pId,
			app_id: app?.[0]?.info?._id,
			isProfileView : false
		  },
		});
	  };

	  const registerPatient = (personId, patientId, appointmentId, isPatient) => {
		setVisitDetails({
		  encounter: {
			mode: "video",
		  },
		  practitioner: {},
		  device: {},
		  helthcare: {},
		  accompained: {},
		});
		if (isPatient) {
		  props.history.push({
			pathname: Routes.patientVisitDetails,
			state: {
			  personId,
			  patientId,
			  appointmentId,
			  Ispatient: true,
			  patientData: props?.location?.state?.data?.[0]?.mobileNo,
			},
		  });
		} else {
		  props.history.push({
			pathname: Routes.assemblePatioentRegister,
			state: {
			  nextRoute: Routes.patientVisitDetails,
			  personId,
			  patientId,
			  appointmentId,
			  patientData: props?.location?.state?.data?.[0]?.mobileNo,
			  Ispatient: false,
			  idedit: false,
			  fromQueue: true,
			},
		  });
		}
	  };

	React.useEffect(()=>{
		if(props?.location?.state?.appointmentId){
			if(drawerMeetingContext?.open){
				history.goBack()
			}
			else{
				fetchInfo(props?.location?.state?.appointmentId)
			}
		}
		else{
			history.goBack()
		}
	},[])


	const fetchInfo = async (id) =>{
		const info = await dispatch(actions.AVC_LOGIN({id})); //12481
		if(!info?.payload?.error){
			setState({
				...state,
				info:info?.payload?.data
			})
		}
	}

	return (
		<div className={classes.root}>
				<Grid container>
					<Grid item xs={3}>
						<LeftJoinDiv
							micControl={mic}
							videoControl={video}
							ControlBtnClick={(param) => ControlBtnClick(param)}
							info={state?.info}
						/>
					</Grid>
					<Grid item xs={9}>
						<div className={classes.cameraPreview}>
							{video ? (
								<Grid
									container
									direction="column"
									alignItems="center"
									className={classes.contentDiv}
								>
									<Avatar alt="Remy Sharp" className={classes.large} />
									<p className={classes.contentPersonName}>
										{props?.location?.state?.name ?? 'You'}
									</p>
								</Grid>
							) : (
								<Webcam
									audio={false}
									height={100 + "%"}
									width={100 + "%"}
									videoConstraints={videoConstraints}
								/>
							)}
						</div>
					</Grid>
				</Grid>
		</div>
	);
}
