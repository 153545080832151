import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
// import { NamespacesConsumer} from 'react-i18next';
import qdmstyles from "./styles.json";
import { withRouter } from "react-router-dom";
import { CollapseAppoinmentCard } from "../";
import { withStyles } from "@material-ui/core/styles";
import Tapcomp from "../doctorCardDetail/TapSelect";
import { Direct } from "../../assets/img/directconsultation.js";
import { Suggested } from "../../assets/img/suggested.js";
import { Videoconsultation } from "../../assets/img/videoconsultation.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Container,
  H6,
  Divider,
  Text,
  Div,
  // Icons,
  WeekCalendar,
  Tab,
  Tabpanel,
  Button,
} from "qdm-component-library";
import moment from "moment";
import {
  AlertProps,
  getReccuranceDates,
  getTimeSlot,
  getTimeString,
  utcTOLocal,
  UIColor,
} from "../../utils";
import { AlertContext } from "../../contexts";

const styles = (theme) => ({
  weekcalendar: {
    "& span": {
      fontSize: 14,
      "& i": {
        fontSize: "24px !important",
      },
    },
  },
  TapSelect: {
    "& div": {
      padding: "2px 6px",
      "& span": {
        fontSize: 13,
        fontWeight: "600",
        letterSpacing: "0.9px",
      },
    },
  },
  icons: {
    display: "flex",
    alignItems: "center",
    margin: "4% 0px",
    justifyContent: "space-around",
  },
  iconText: {
    marginLeft: 8,
    color: UIColor.tertiaryText.color,
    fontSize: 12,
    fontFamily: "pc_regular !important",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
});
class BookMultipleAppointmentsLists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      info: this.props.info,
      healthCareId: this.props.info.practData?.healthCareId || [],
      slotObj: this.props?.multiApppointmentInfo?.slotObj || {},
    };
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleDateChange = (newDate, oldInfo) => {
    const dates = [...this.state.info.dates];
    const index = dates.indexOf(oldInfo.time);
    if (index >= 0) {
      const { appointments } = this.state.info;
      const slotInfo = { ...this.state.slotObj };
      const datesToBeRemoved = dates.splice(index);
      datesToBeRemoved.forEach((date) => {
        delete slotInfo[date];
      });
      getReccuranceDates(
        moment(newDate, "MM-DD-YYYY").format("DD-MM-YYYY"),
        "NoOfDays",
        Number(appointments.occurence.occurrenceValue),
        appointments.reccurance.reccuranceType.value,
        appointments.reccurance.reccuranceValue,
        appointments.preferrence.preferredDays,
        appointments.preferrence.preferredWeeks,
        dates
      );
      this.setState(
        {
          ...this.state,
          slotObj: slotInfo,
          info: {
            ...this.state.info,
            dates,
          },
        },
        () => {
          this.fetchData();
        }
      );
    }
  };

  componentDidMount() {
    this.gettingDate();
    if (!(this.state?.slotObj && Object.keys(this.state.slotObj).length > 0)) {
      this.fetchData();
    }
  }

  gettingDate() {
    const { practData, dates } = this.state.info;
    debugger;
    let start = dates;
    console.log("allDate", start);
    const date = start.map((d) => {
      debugger;
      let startDate = new Date();
      let endDate = new Date();
      startDate = new Date(d * 1000);
      debugger;
      endDate = new Date(d * 1000);
      endDate.setHours(23, 59, 59, 999);

      const getUtcTime = (date) => {
        if (date) {
          return moment.utc(date).unix();
        } else {
          return moment.utc().unix();
        }
      };

      let resultDate = {
        start: getUtcTime(startDate),
        end: getUtcTime(endDate),
      };
      console.log("resultDate", resultDate);
      return resultDate;
    });
  }

  async fetchData() {
    const { practData, dates } = this.state.info;
    const data = await this.props.RECCURANCE_APPOINTMENT({
      [practData?.isClinic ? "clinicid" : "practionerid"]: practData?.isClinic
        ? practData.clinicId
        : practData.id,
      start: dates,
    });
    const slotObj = {};
    if (data?.payload?.data && Array.isArray(data.payload.data)) {
      data.payload.data.forEach((schedule) => {
        const slot = {
          morning: [],
          afternoone: [],
          evening: [],
          night: [],
          allSession: [],
          isClinic: !!practData?.isClinic,
          healthCareId: this.state.healthCareId,
          isSlotAvailable: false,
          isSlotUpdated: false,
          selectedSlotId: 0,
          selectedSlotInfo: {},
          isEmpty: false,
          time: schedule.start,
        };
        this.filterSlots(
          {
            resourcecode: schedule.resourceCode,
            resourcerole: schedule.resourceRole,
            resourcetype: schedule.resourceType,
          },
          schedule.slots,
          slot.morning,
          slot.afternoone,
          slot.evening,
          slot.night,
          slot.allSession,
          slot.isClinic,
          slot.healthCareId,
          slot
        );
        slotObj[schedule.start] = slot;
      });
    }
    this.state.info.dates.forEach((date) => {
      if (!(date in slotObj)) {
        const slot = {
          morning: [],
          afternoone: [],
          evening: [],
          night: [],
          allSession: [],
          isClinic: !!practData?.isClinic,
          healthCareId: this.state.healthCareId,
          isSlotAvailable: false,
          isSlotUpdated: false,
          selectedSlotId: 0,
          selectedSlotInfo: {},
          isEmpty: true,
          time: date,
        };
        slotObj[date] = slot;
      }
    });
    this.setState({
      ...this.state,
      slotObj,
    });
  }

  filterSlots = (
    resourceInfo,
    slots,
    morning,
    afternoone,
    evening,
    night,
    allSession,
    isClinic,
    healthCareId,
    slotInfo
  ) => {
    slots.forEach((slot) => {
      if (slot) {
        const {
          start,
          id: slotId,
          status,
          end,
          isdirect = false,
          issuggest = false,
          isvideo = false,
          Maxbooking = 0,
          Maxwaiting = 0,
          booked = 0,
          overbooked,
        } = slot;
        if (!(status === "closed" || status === "booked" || overbooked)) {
          //const slotTime = new Date(start * 1000);
          const slotTime = utcTOLocal(start).toDate();
          const selectSlot =
            this.state?.info?.appointments?.startsOn?.slotSelect?.label;
          const hours = slotTime.getHours();
          let label = getTimeString(hours, slotTime.getMinutes());
          if (isClinic) {
            const endTime = new Date(end * 1000);
            const endHours = endTime.getHours();
            const endTimeLabel = getTimeString(endHours, endTime.getMinutes());
            label = `${label} - ${endTimeLabel}`;
          }
          const iconsArr = [];
          const slotData = {
            value: slotId,
            label,
            date: slotTime.getTime(),
            dateEnd: end,
            dateStart: start,
            status,
            isdirect,
            issuggest,
            isvideo,
            booked: booked,
            maxBooking: Maxbooking,
            maxWaiting: Maxwaiting,
            waiting: booked > Maxbooking ? booked - Maxbooking : Maxwaiting,
            icon: iconsArr,
            healthCareId,
            disabled: status === "closed" || status === "booked" || overbooked,
            resourceInfo,
          };
          if (label === selectSlot) {
            slotInfo.isSlotAvailable = true;
            slotInfo.selectedSlotId = slotId;
            slotInfo.selectedSlotInfo = slotData;
          }
          if (isClinic) {
            allSession.push(slotData);
          } else {
            if (slot?.DayType?.display?.toLowerCase() === "morning") {
              morning.push(slotData);
            } else if (slot?.DayType?.display?.toLowerCase() === "afternoon") {
              afternoone.push(slotData);
            } else if (slot?.DayType?.display?.toLowerCase() === "evening") {
              evening.push(slotData);
            } else if (slot?.DayType?.display?.toLowerCase() === "night") {
              night.push(slotData);
            } else {
              morning.push(slotData);
            }
          }
        }
      }
    });
  };

  shouldComponentUpdate(props, state) {
    return true;
  }

  confirmAppointment = () => {
    const returnData = [];
    const isSuccess = Object.keys(this.state.slotObj).every((key) => {
      const obj = {
        id: this.state.slotObj[key].selectedSlotId,
        start: this.state.slotObj[key].selectedSlotInfo.dateStart,
        end: this.state.slotObj[key].selectedSlotInfo.dateEnd,
        resourceInfo: this.state.slotObj[key].selectedSlotInfo.resourceInfo,
      };
      returnData.push(obj);
      return (
        !this.state.slotObj[key].isEmpty &&
        this.state.slotObj[key].selectedSlotId !== 0 &&
        Object.keys(this.state.slotObj[key].selectedSlotInfo).length > 0
      );
    });
    if (
      this.props.fromListing ||
      (isSuccess && returnData.length === this.state.info?.dates?.length)
    ) {
      this.props.handleMultiAppointmentChange(returnData, "data");
      this.props.handleMultiAppointmentChange(
        { ...this.state.slotObj },
        "slotObj"
      );
      this.props.confirmAppoinment();
    } else {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please Choose Slot For All Days",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
  };

  updateSelectedSlot(date, val) {
    const state = { ...this.state };
    if (state.slotObj[date]) {
      state.slotObj[date].selectedSlotId = val.value;
      state.slotObj[date].selectedSlotInfo = val;
      if (
        val.label === this.state.info?.appointments?.startsOn?.slotSelect?.label
      ) {
        state.slotObj[date].isSlotUpdated = false;
      } else {
        state.slotObj[date].isSlotUpdated = true;
      }
      this.setState({
        ...state,
      });
    }
  }

  render() {
    const { classes, parent_id } = this.props;
    const { appointments, dates } = this.state.info;
    const { slotObj } = this.state;
    // const data = [
    //   {
    //     value: 348,
    //     label: "10:30 PM",
    //     date: 1624035600000,
    //     dateEnd: 1624037400000,
    //     dateStart: 1624035600000,
    //     status: "true",
    //     isdirect: false,
    //     issuggest: false,
    //     isvideo: false,
    //     icon: [],
    //   },
    //   {
    //     value: 349,
    //     label: "11:00 PM",
    //     date: 1624037400000,
    //     dateEnd: 1624039200000,
    //     dateStart: 1624037400000,
    //     status: "true",
    //     isdirect: false,
    //     issuggest: false,
    //     isvideo: false,
    //     icon: [],
    //   },
    //   {
    //     value: 350,
    //     label: "11:30 PM",
    //     date: 1624039200000,
    //     dateEnd: 1624041000000,
    //     dateStart: 1624039200000,
    //     status: "true",
    //     isdirect: false,
    //     issuggest: false,
    //     isvideo: false,
    //     icon: [],
    //   },
    // ];
    return (
      <Container
        id={`${parent_id}_Multiple_Appointments_parent_container`}
        key={"0"}
        name={"container"}
        fluid={true}
      >
        <H6
          id={`${parent_id}_book_Multiple_Appointments_h6`}
          key={"0"}
          name={"Book Multiple Appointments"}
          style={qdmstyles.agCQ}
          className={"pc_semibold"}
        ></H6>

        <Divider
          id={`${parent_id}_book_Multiple_Appointments_divider`}
          key={"1"}
          inLineStyles={{ margin: "14px 0px", opacity: "0.5" }}
        ></Divider>

        <Text
          id={`${parent_id}_book_Multiple_Appointments_noofdays_text`}
          key={"2"}
          name={`Every ${appointments.reccurance.reccuranceValue} ${
            appointments.reccurance.reccuranceType.value
          }, starting on 
              ${`${moment(appointments.startsOn.date, "DD-MM-YYYY").format(
                "Do MMM 'YY"
              )} and `}
              ${
                appointments.occurence.occurrenceHandle === "NoOfDays"
                  ? "ends after "
                  : "ends by "
              }
              ${
                appointments.occurence.occurrenceHandle === "NoOfDays"
                  ? appointments.occurence.occurrenceValue
                  : moment(
                      appointments.occurence.occurrenceValue,
                      "YYYY-MM-DD"
                    ).format("Do MMM 'YY")
              }
              ${
                appointments.occurence.occurrenceHandle === "NoOfDays"
                  ? " occurrences"
                  : ""
              }.`}
          style={qdmstyles.B}
          className={"pc_regular"}
        ></Text>

        <Div
          id={`${parent_id}_book_Multiple_Appointments_dates_div`}
          key={"3"}
          className={"qdm"}
          style={qdmstyles.qGGRc}
        >
          <Div key={"0"}>
            <H6
              id={`${parent_id}_book_Multiple_Appointments_Occurrences_h6`}
              key={"0"}
              name={`${dates.length} Occurrences`}
              style={{
                color: UIColor.secondaryText.color,
                fontSize: "14px",
                fontWeight: "600",
              }}
              className={"pc_regular"}
            ></H6>
          </Div>

          <Div
            id={`${parent_id}_book_Multiple_Appointments_Change_div`}
            key={"1"}
          >
            <H6
              id={`${parent_id}_book_Multiple_Appointments_Change_h6`}
              key={"0"}
              onClick={this.props?.onChange}
              name={"Change"}
              style={{
                color: UIColor.secondaryColor.color,
                fontSize: "14px",
                fontWeight: "600",
                cursor: "pointer",
              }}
              className={"pc_regular"}
            ></H6>
          </Div>
        </Div>

        <Divider
          id={`${parent_id}_book_Multiple_Appointments_Change_divider`}
          inLineStyles={{ margin: "14px 0px", opacity: "0.5" }}
        />
        {this.props.reccurantApp.loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          Object.keys(this.state.slotObj).map((date, i) => {
            return (
              (this.props.fromListing ? date in this.state.slotObj : true) && (
                <CollapseAppoinmentCard
                  parent_id={"book_appointment_" + i}
                  key={i}
                  index={i + 1}
                  date={date}
                  time={
                    slotObj[date]
                      ? slotObj[date].isSlotUpdated
                        ? slotObj[date].selectedSlotInfo?.label
                        : appointments?.startsOn?.slotSelect?.label
                      : appointments?.startsOn?.slotSelect?.label
                  }
                  slot={
                    slotObj[date]
                      ? slotObj[date].isSlotUpdated
                        ? "Slot Updated"
                        : slotObj[date].isSlotAvailable
                        ? "Slot Available"
                        : slotObj[date].isEmpty
                        ? "Schedule Unavailable"
                        : "Slot Unavailable"
                      : "Schedule Unavailable"
                  }
                  // showEdit
                  showDelete
                  deleteFunction={() => {
                    const allDates = [...this.state.info.dates];
                    if (allDates.length <= 1) {
                      this.context.setSnack({
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Atleast one slot should be selected.",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                        tone: true,
                      });
                      return;
                    }
                    const data = allDates.splice(i, 1);
                    const slotObj = JSON.parse(
                      JSON.stringify(this.state.slotObj)
                    );
                    delete slotObj[data];
                    this.setState({
                      ...this.state,
                      slotObj,
                      info: {
                        ...this.state.info,
                        dates: allDates,
                      },
                    });
                  }}
                  open={false}
                  isError={
                    !(
                      slotObj[date] &&
                      (slotObj[date].isSlotAvailable ||
                        slotObj[date].isSlotUpdated)
                    )
                  }
                  showEdit
                  body={
                    slotObj[date] ? (
                      <div
                        id={`${parent_id}_${i}_book_Multiple_Appointments_week_div`}
                      >
                        <WeekCalendar
                          id={`${parent_id}_${i}_book_Multiple_Appointments_weekcalendar`}
                          activeColor={UIColor.secondaryButtonColor.color}
                          key={"0"}
                          currentDate={moment(new Date(date * 1000)).toDate()}
                          weekStartDate={
                            i === 0
                              ? moment(new Date()).toDate()
                              : moment(new Date(dates[i - 1] * 1000))
                                  .add(1, "day")
                                  .toDate()
                          }
                          style={{
                            borderWidth: "0px",
                            fontSize: 14,
                            marginBottom: "4%",
                          }}
                          className={classes.weekcalendar}
                          onChange={(dateVal) =>
                            this.handleDateChange(dateVal, slotObj[date])
                          }
                        />
                        {this.state.info?.practData?.isClinic ? (
                          <Tab
                            id={`${parent_id}_book_appointment_list_appointment_tab`}
                            key={"0"}
                            align="center"
                            activetabs={0}
                            name=""
                            label=""
                            inLineTabTitleStyles=""
                            fullWidth={true}
                            size="small"
                            backgroundColor="#3f51b5"
                            elevation="0"
                            color=""
                            border={false}
                            variant="withoutBordered"
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                            className={"pc_regular"}
                          >
                            <Tabpanel
                              id={`${parent_id}_book_appointment_list_label_morning_tabpanel`}
                              key={"0"}
                              label="ALL SESSION"
                              title="ALL SESSION"
                              subTitle={`${
                                slotObj[date]?.allSession?.length || "No"
                              } slots`}
                              color={
                                slotObj[date]?.allSession?.length
                                  ? "#38C20A"
                                  : "#EA1601"
                              }
                              style={{
                                maxHeight: "26vh",
                                overflow: "auto",
                                marginBottom: "5%",
                                paddingLeft: 0,
                                paddingRight: 0,
                              }}
                              className={"pc_regular"}
                            >
                              {slotObj[date]?.allSession?.map((val, i) => (
                                <Tapcomp
                                  id={`${parent_id}_book_appointment_list_morning_tapcomp`}
                                  val={val}
                                  disabled={val.disabled}
                                  stateVal={slotObj[date]?.selectedSlotId}
                                  onChange={() =>
                                    this.updateSelectedSlot(date, val)
                                  }
                                />
                              ))}
                            </Tabpanel>
                          </Tab>
                        ) : (
                          <Tab
                            id={`${parent_id}_book_appointment_list_appointment_tab`}
                            key={"0"}
                            align="center"
                            name=""
                            label=""
                            inLineTabTitleStyles=""
                            fullWidth={true}
                            size="small"
                            backgroundColor="#3f51b5"
                            elevation="0"
                            color=""
                            border={false}
                            activetabs={
                              slotObj[date].isEmpty
                                ? 0
                                : getTimeSlot(
                                    appointments?.startsOn?.slotSelect
                                      ?.dateStart,
                                    "unix",
                                    true
                                  )
                            }
                            variant="withoutBordered"
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                            className={"pc_regular"}
                            activeColor={"#2A60BC"}
                          >
                            <Tabpanel
                              id={`${parent_id}_book_appointment_list_label_morning_tabpanel`}
                              key={"0"}
                              label="MORNING"
                              title="MORNING"
                              subTitle={`${
                                slotObj[date]?.morning?.length || "No"
                              } slots`}
                              color={
                                slotObj[date]?.morning?.length
                                  ? "#38C20A"
                                  : "#EA1601"
                              }
                              style={{
                                maxHeight: "26vh",
                                overflow: "auto",
                                marginBottom: "5%",
                                paddingLeft: 0,
                                paddingRight: 0,
                              }}
                              className={"pc_regular"}
                            >
                              {slotObj[date]?.morning?.map((val, i) => (
                                <Tapcomp
                                  id={`${parent_id}_book_appointment_list_morning_tapcomp`}
                                  val={val}
                                  disabled={val.disabled}
                                  stateVal={slotObj[date]?.selectedSlotId}
                                  onChange={() =>
                                    this.updateSelectedSlot(date, val)
                                  }
                                />
                              ))}
                            </Tabpanel>

                            <Tabpanel
                              id={`${parent_id}_book_appointment_list_label_afternoon_tabpanel`}
                              key={"1"}
                              label="AFTERNOON"
                              title="AFTERNOON"
                              subTitle={`${
                                slotObj[date]?.afternoone?.length || "No"
                              } slots`}
                              color={
                                slotObj[date]?.afternoone?.length
                                  ? "#38C20A"
                                  : "#EA1601"
                              }
                              style={{
                                maxHeight: "26vh",
                                overflow: "auto",
                                marginBottom: "5%",
                                paddingLeft: 0,
                                paddingRight: 0,
                              }}
                              className={"pc_regular"}
                            >
                              {slotObj[date]?.afternoone?.map((val, i) => (
                                <Tapcomp
                                  id={`${parent_id}_book_appointment_list_afternoon_tapcomp`}
                                  val={val}
                                  disabled={val.disabled}
                                  stateVal={slotObj[date]?.selectedSlotId}
                                  onChange={() =>
                                    this.updateSelectedSlot(date, val)
                                  }
                                />
                              ))}
                            </Tabpanel>

                            <Tabpanel
                              id={`${parent_id}_book_appointment_list_label_evening_tabpanel`}
                              key={"2"}
                              label="EVENING"
                              title="EVENING"
                              subTitle={`${
                                slotObj[date]?.evening?.length || "No"
                              } slots`}
                              color={
                                slotObj[date]?.evening?.length
                                  ? "#38C20A"
                                  : "#EA1601"
                              }
                              style={{
                                maxHeight: "26vh",
                                overflow: "auto",
                                marginBottom: "5%",
                                paddingLeft: 0,
                                paddingRight: 0,
                              }}
                              className={"pc_regular"}
                            >
                              {slotObj[date]?.evening?.map((val, i) => (
                                <Tapcomp
                                  id={`${parent_id}_book_appointment_list_evening_tapcomp`}
                                  val={val}
                                  disabled={val.disabled}
                                  stateVal={slotObj[date]?.selectedSlotId}
                                  onChange={() =>
                                    this.updateSelectedSlot(date, val)
                                  }
                                />
                              ))}
                            </Tabpanel>

                            <Tabpanel
                              id={`${parent_id}_book_appointment_list_label_night_tabpanel`}
                              key={"3"}
                              label="NIGHT"
                              title="NIGHT"
                              subTitle={`${
                                slotObj[date]?.night?.length || "No"
                              } slots`}
                              color={
                                slotObj[date]?.night?.length
                                  ? "#38C20A"
                                  : "#EA1601"
                              }
                              style={{
                                maxHeight: "26vh",
                                overflow: "auto",
                                marginBottom: "5%",
                                paddingLeft: 0,
                                paddingRight: 0,
                              }}
                              className={"pc_regular"}
                            >
                              {slotObj[date]?.night?.map((val, i) => (
                                <Tapcomp
                                  id={`${parent_id}_book_appointment_list_night_tapcomp`}
                                  val={val}
                                  disabled={val.disabled}
                                  stateVal={slotObj[date]?.selectedSlotId}
                                  onChange={() =>
                                    this.updateSelectedSlot(date, val)
                                  }
                                />
                              ))}
                            </Tabpanel>
                          </Tab>
                        )}
                        <span
                          id={`${parent_id}_${i}_book_Multiple_Appointments_span`}
                          className={classes.icons}
                        >
                          {/* <span
                            id={`${parent_id}_${i}_book_Multiple_Appointments_Suggested_span`}
                            className={classes.flexCenter}>
                            <Suggested
                              id={`${parent_id}_${i}_book_Multiple_Appointments_Suggested`}
                              color={"#B6B6B6"} />
                            <Text
                              id={`${parent_id}_${i}_book_Multiple_Appointments_Suggested_text`}
                              className={classes.iconText}>Suggested Slot</Text>
                          </span> */}
                          <span
                            id={`${parent_id}_${i}_book_Multiple_Appointments_Videoconsultation_span`}
                            className={classes.flexCenter}
                          >
                            <Videoconsultation
                              id={`${parent_id}_${i}_book_Multiple_Appointments_Videoconsultation`}
                              color={UIColor.tertiaryText.color}
                              size={15}
                            />
                            <Text
                              id={`${parent_id}_${i}_book_Multiple_Appointments_Videoconsultation_text`}
                              className={classes.iconText}
                            >
                              Video Consultation
                            </Text>
                          </span>
                          <span
                            id={`${parent_id}_${i}_book_Multiple_Appointments_Videoconsultation_span`}
                            className={classes.flexCenter}
                          >
                            <Direct
                              id={`${parent_id}_${i}_book_Multiple_Appointments_Videoconsultation`}
                              color={UIColor.tertiaryText.color}
                              size={15}
                            />
                            <Text
                              id={`${parent_id}_${i}_book_Multiple_Appointments_Videoconsultation_text`}
                              className={classes.iconText}
                            >
                              Direct Consultation
                            </Text>
                          </span>
                        </span>
                      </div>
                    ) : null
                  }
                />
              )
            );
          })
        )}

        <Button
          id={`${parent_id}_book_Multiple_Confirm_Appointments_button`}
          key={"6"}
          type={"button"}
          variant={"outlined"}
          name={"Confirm Appointment"}
          onClick={this.confirmAppointment}
          disabled={this.props.reccurantApp.loading}
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "auto",
            marginTop: "20px",
            marginBottom: "10px",
            backgroundColor: UIColor.secondaryButtonColor.color,
            borderColor: UIColor.secondaryButtonColor.color,
            borderRadius: "10px",
            fontSize: "14px",
            padding: "10px",
          }}
          className={"pc_regular"}
        ></Button>
      </Container>
    );
  }
}

BookMultipleAppointmentsLists.contextType = AlertContext;

const mapStateToProps = (state) => ({
  reccurantApp: state.appointmentReducer.reccuranceAppointMent,
});

export default withStyles(styles)(
  connect(mapStateToProps, actions)(withRouter(BookMultipleAppointmentsLists))
);
// export default connect(
//   mapStateToProps,
//   actions
// )(withRouter(BookMultipleAppointmentsLists));
