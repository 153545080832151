import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import qdmstyles from "./styles.json";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Tooltip from "@material-ui/core/Tooltip";
import { Calendar } from "../../assets/img/calendar.js";
import { CalenderEdit } from "../../assets/img/calenderEdit";
import expandarrow from "../../assets/img/expandarrow.svg";
import Popup from "@material-ui/core/Popover";
import moment from "moment";
import Tapcomp from "../doctorCardDetail/TapSelect";
import {
  Container,
  H6,
  Divider,
  Div,
  Text,
  Dropdown,
  TapSelect,
  Button,
  Tab,
  Tabpanel,
  Row,
  Col,
} from "qdm-component-library";
import {
  AlertProps,
  getReccuranceDates,
  getTimeSlot,
  getTimeString,
  getUtcTime,
  utcTOLocal,
  UIColor,
} from "../../utils";
import "react-calendar/dist/Calendar.css";
import Calendars from "react-calendar";
import { AlertContext } from "../../contexts";
import CustomDatePicker from "../customDatePicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const styles = (theme) => ({
  selectedTab: {
    backgroundColor: `${UIColor.primaryColor.color} !important`,
    color: "#fff !important",
    fontSize: 14,
    fontWeight: 600,
    marginTop: "8%",
    textTransform: "none",
  },
  selecteTab: {
    marginTop: "8%",
    fontSize: 14,
    fontWeight: 600,
    color: UIColor.primaryText.color,

    textTransform: "none",
  },
  typography: {
    fontSize: "14px !important",
    fontWeight: "bold !important",
    display: "flex",
    cursor: "pointer",
  },
  endafterdiv: {
    padding: 8,
    backgroundColor: UIColor.greyBackground.color,
    border: `1px solid ${UIColor.lineBorderFill.color}`,
  },
  endafterdiv1: {
    display: "flex",
    justifyContent: "space-between",
    padding: "6px 6px",
    cursor: "pointer",
  },
  Popover1: {
    "& .MuiPopover-paper": {
      width: "20%",
    },
  },
  tapselectcss: {
    "& span": {
      fontSize: 14,
      // color: "#101010",
    },
  },
  monthCalendar: {
    padding: 5,
    margin: 5,
    // width:300,
    "& strong": {
      fontSize: 13,
    },
    "& span": {
      fontSize: 13,
    },
    backgroundColor: "#fff",
  },
  tapSelectStyle: {
    //padding: "4px 8px",
    //borderColor: "#E0E0E0",
    // margin: "4px 10px 6px 0px",
    borderRadius: "8px",
    // minWidth: "auto",
    margin: "0px 6px 6px 10px",
    minWidth: 30,
    padding: "5px 15px",
  },
});
class BookMultipleAppointmentsDrawer extends React.Component {
  constructor(props) {
    super(props);
    const time = moment(
      `${props.date}, ${props.time.label}`,
      "Do MMM YYYY, hh:mm a"
    )
      .toDate()
      .getTime();
    const activeTab = getTimeSlot(time / 1000, "unix", true);
    this.state = {
      activeTab,
      anchorEl: null,
      openCloseCalendar: false,
      hospitalInfo: this.props.hospitalInfo,
      endbycal: false,
      data: {
        morning: [],
        afternoone: [],
        evening: [],
        night: [],
        allSession: [],
        isClinic: false,
      },
    };
  }

  async updateSlot(date) {
    let startDate = new Date(date);
    let endDate = new Date(date + 86399999);
    const data = this.state?.hospitalInfo || {};
    const slotInfo = await this.props.READ_SLOT({
      id: data?.isClinic ? data.clinicId : data.id,
      startdate: getUtcTime(startDate), //Math.floor(date / 1000),
      enddate: getUtcTime(endDate), //Math.floor((date + 86399999) / 1000),
      type: data?.isClinic ? "clinicid" : "practionerid",
    });
    const slotInfoData = JSON.parse(JSON.stringify(slotInfo?.payload?.data));
    this.filterSlots(
      {
        resourcecode: slotInfoData.resourceCode,
        resourcerole: slotInfoData.resourceRole,
        resourcetype: slotInfoData.resourceType,
      },
      slotInfoData.slots,
      slotInfoData.morning,
      slotInfoData.afternoone,
      slotInfoData.evening,
      slotInfoData.night,
      slotInfoData.allSession,
      slotInfoData.isClinic,
      slotInfoData.healthCareId
    );
    const state = { ...this.state };
    state.data.morning = slotInfoData.morning;
    state.data.afternoone = slotInfoData.afternoone;
    state.data.evening = slotInfoData.evening;
    state.data.night = slotInfoData.night;
    state.data.allSession = slotInfoData.allSession;
    state.data.isClinic = slotInfoData.isClinic;
    this.setState(state);
  }

  componentDidMount() {
    this.updateSlot(
      this.props.multiApppointmentInfo.appointments.startsOn.milli
    );
  }

  filterSlots = (
    resourceInfo,
    slots,
    morning,
    afternoone,
    evening,
    night,
    allSession,
    isClinic,
    healthCareId
  ) => {
    slots.forEach((slot) => {
      if (slot) {
        const {
          start,
          id: slotId,
          status,
          end,
          isdirect = false,
          issuggest = false,
          isvideo = false,
          Maxbooking = 0,
          Maxwaiting = 0,
          booked = 0,
        } = slot;
        if (status !== "closed" && status !== "booked") {
          //const slotTime = new Date(start * 1000);
          const slotTime = utcTOLocal(start).toDate();
          const hours = slotTime.getHours();
          let label = getTimeString(hours, slotTime.getMinutes());
          if (isClinic) {
            const endTime = new Date(end * 1000);
            const endHours = endTime.getHours();
            const endTimeLabel = getTimeString(endHours, endTime.getMinutes());
            label = `${label} - ${endTimeLabel}`;
          }
          const iconsArr = [];
          const slotData = {
            value: slotId,
            label,
            date: slotTime.getTime(),
            dateEnd: end,
            dateStart: start,
            status,
            isdirect,
            issuggest,
            isvideo,
            booked: booked,
            maxBooking: Maxbooking,
            maxWaiting: Maxwaiting,
            waiting: booked > Maxbooking ? booked - Maxbooking : 0,
            icon: iconsArr,
            healthCareId,
            resourceInfo,
          };
          if (isClinic) {
            allSession.push(slotData);
          } else {
            if (slot?.DayType?.display?.toLowerCase() === "morning") {
              morning.push(slotData);
            } else if (slot?.DayType?.display?.toLowerCase() === "afternoon") {
              afternoone.push(slotData);
            } else if (slot?.DayType?.display?.toLowerCase() === "evening") {
              evening.push(slotData);
            } else if (slot?.DayType?.display?.toLowerCase() === "night") {
              night.push(slotData);
            } else {
              morning.push(slotData);
            }
          }
        }
      }
    });
  };

  checkValidation = (appointment) => {
    if (appointment.reccurance.reccuranceValue < 1) {
      return [
        true,
        `Choose a reccurant value of atleast 1 ${appointment.reccurance.reccuranceType.label}`,
      ];
    }
    if (
      appointment.occurence.occurrenceValue < 1 &&
      appointment.occurence.occurrenceHandle === "NoOfDays"
    ) {
      return [true, `Ends After must be atleast after 1 day`];
    }
    if (appointment.occurence.occurrenceHandle === "Calendar") {
      const currDate = moment(
        appointment.occurence.occurrenceValue,
        "YYYY-MM-DD"
      )
        .startOf("day")
        .unix();
      const startdate = moment(appointment.startsOn.date, "DD-MM-YYYY")
        .startOf("day")
        .unix();
      if (currDate <= startdate) {
        return [true, `Ends By must be greater than Starts On`];
      }
    }
    return [false, ""];
  };

  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  // changeAppointment = (parentKey,key,value) =>{
  //   const newState = {...this.state.appointments};
  //   newState[parentKey][key]=value;
  //   this.setState({...this.state,appointments:newState})
  // }

  // clearPreference = () =>{
  //   const newState = {...this.state.appointments};
  //   newState['preferrence']={
  //     preferredDays:[],
  //     preferredWeeks:[],
  //   }
  //   this.setState({...this.state,appointments:newState})
  // }

  handlepapover = (e) => {
    if (this.state.openCloseCalendar) {
      this.handlepapoverclose();
    } else {
      this.setState({ openCloseCalendar: e.currentTarget });
    }
  };
  handlepapoverclose = (val) => {
    if (val) {
      this.setState({ openCloseCalendar: null });
    }
  };

  handleCloseendbycal = (e) => {
    if (this.state.endbycal) {
      this.handleCloseendbycalClose();
    } else {
      this.setState({ endbycal: e.currentTarget });
    }
  };
  handleCloseendbycalClose = (val) => {
    if (val) {
      this.setState({ endbycal: null });
    }
  };

  render() {
    const { classes, parent_id } = this.props;
    const {
      activeTab,
      data: { morning, afternoone, evening, night },
    } = this.state;
    const { appointments } = this.props.multiApppointmentInfo;
    const openCalendar = Boolean(this.state.openCloseCalendar);
    const calenderId = openCalendar ? "simple-popover" : undefined;
    const handleClick = (event) => {
      this.setState({
        anchorEl: event.currentTarget,
      });
    };

    const handleClose = () => {
      this.setState({
        anchorEl: null,
      });
    };

    const open = Boolean(this.state.anchorEl);
    const endbycal = Boolean(this.state.endbycal);
    const id = open ? "simple-popover" : undefined;

    // const handleOccurrence = (value) => {
    //   const val = {...this.state};
    //   val.appointments.occurence.occurrenceHandle = value;
    //   val.appointments.occurence.occurrenceValue = value === "NoOfDays" ? 0 : moment().format("YYYY-MM-DD");
    //   this.setState(val);
    // };

    return (
      <Container
        id={`${parent_id}_bookmultiple_parent_container`}
        key={"0"}
        name={"container"}
        fluid={true}
        style={qdmstyles.thEz}
      >
        <H6
          id={`${parent_id}_bookmultiple_appointment_h6`}
          key={"0"}
          name={"Book Multiple Appointments"}
          style={qdmstyles.rgo}
          className={"pc_semibold"}
        ></H6>

        <Divider
          id={`${parent_id}_bookmultiple_bottom_divider`}
          key={"1"}
          style={{ backgroundColor: UIColor.lineBorderFill.color }}
        ></Divider>

        <Div
          id={`${parent_id}_bookmultiple_parent_div`}
          key={"2"}
          alignItems={"stretch"}
          direction={"row"}
          justify={"flex-start"}
          spacing={"2"}
          style={qdmstyles.iCwhZ}
        >
          <Div
            id={`${parent_id}_bookmultiple_parent_sub_div`}
            key={"0"}
            className={"qdm"}
            style={qdmstyles.c_d_a_a_cbafafe}
          >
            <Text
              id={`${parent_id}_bookmultiple_text`}
              key={"0"}
              style={qdmstyles.AhyN}
            >
              <Text
                id={`${parent_id}_bookmultiple_star_text`}
                style={{ color: UIColor.error.color }}
              >
                *
              </Text>
              <Text
                id={`${parent_id}_bookmultiple_starton_text`}
                className={"pc_regular"}
                style={{
                  color: UIColor.primaryText.color,
                  fontWeight: "600",
                }}
              >
                Starts on
              </Text>
            </Text>

            <span
              id={`${parent_id}_bookmultiple_calender_span`}
              style={{
                color: UIColor.primaryText.color,
                padding: "6px 8px",
                borderRadius: "6px",
                backgroundColor: UIColor.greyBackground.color,
                border: `1px solid ${UIColor.lineBorderFill.color}`,
                display: "flex",
                alignItems: "center",
              }}
            >
              <text
                id={`${parent_id}_bookmultiple_calender_text`}
                style={qdmstyles.timeanddate}
              >
                {appointments?.startsOn?.date ?? ""}
                {`${
                  appointments?.startsOn?.slotSelect?.label
                    ? ` , ${appointments?.startsOn?.slotSelect?.label}`
                    : ""
                }`}{" "}
                &nbsp;&nbsp;
              </text>
              <div
                id={`${parent_id}_bookmultiple_calender_div`}
                style={{ cursor: "pointer" }}
                onClick={(e) => this.handlepapover(e)}
              >
                <Calendar />
              </div>
            </span>
          </Div>

          <Popup
            id={calenderId}
            open={openCalendar}
            anchorEl={this.state.openCloseCalendar}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            // style={{ marginTop: '1%', marginLeft: '2%' }}
            onClose={this.handlepapoverclose}
          >
            <Row
              id={`${parent_id}_bookmultiple_slot_row`}
              style={{
                backgroundColor: UIColor.greyBackground.color,
                padding: "5px",
                // display:'flex',
                width: 700,
              }}
            >
              <Col
                id={`${parent_id}_bookmultiple_slot_col`}
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={5}
                inLineStyles={{ padding: 0 }}
              >
                {/* <Calendars
                  id={`${parent_id}_bookmultiple_slot_calendars`}
                  className={classes.monthCalendar}
                  // minDate={new Date(appointments.startsOn.milli)}
                  minDate={new Date()}
                  value={
                    new Date(
                      moment(
                        this.props?.multiApppointmentInfo?.appointments
                          ?.startsOn?.date,
                        "DD-MM-YYYY"
                      ).format("MM-DD-YYYY")
                    )
                  }
                  onChange={(value, event) => {
                    const milli = value.getTime();
                    const date = {
                      val: moment(value).format("DD-MM-YYYY"),
                      milli,
                    };
                    this.props.handleMultiAppointmentChange(
                      {
                        date: date.val,
                        milli: date.milli,
                        slotSelect: this.props.info,
                      },
                      "appointments",
                      "startsOn"
                    );
                    // this.changeAppointment('startsOn','date', date.val);
                    // this.changeAppointment('startsOn','milli', date.milli);
                    this.updateSlot(date.milli);
                  }}
                /> */}

                <DatePicker
                  minDate={new Date()}
                  inline
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  calendarStartDay={1}
                  selected={
                    new Date(
                      moment(
                        this.props?.multiApppointmentInfo?.appointments
                          ?.startsOn?.date,
                        "DD-MM-YYYY"
                      ).format("MM-DD-YYYY")
                    )
                  }
                  onChange={(value) => {
                    const milli = value.getTime();
                    const date = {
                      val: moment(value).format("DD-MM-YYYY"),
                      milli,
                    };
                    this.props.handleMultiAppointmentChange(
                      {
                        date: date.val,
                        milli: date.milli,
                        slotSelect: this.props.info,
                      },
                      "appointments",
                      "startsOn"
                    );
                    // this.changeAppointment('startsOn','date', date.val);
                    // this.changeAppointment('startsOn','milli', date.milli);
                    this.updateSlot(date.milli);
                  }}
                />
              </Col>
              <Col
                id={`${parent_id}_bookmultiple_timeslot_slot`}
                xs={12}
                sm={12}
                md={7}
                lg={7}
                xl={7}
                inLineStyles={{ padding: 5 }}
              >
                <Div
                  id={`${parent_id}_bookmultiple_timeslot_div`}
                  style={{
                    padding: 10,
                    border: "1px solid #0000000d",
                    boxShadow: "0px 0px 23px -17px #0000004d",
                    backgroundColor: "#fff",
                    borderRadius: 5,
                    height: "100%",
                    // width:400,
                  }}
                >
                  <Text
                    id={`${parent_id}_bookmultiple_timeslot_text`}
                    style={{
                      color: UIColor.secondaryText.color,
                      fontSize: 12,
                      fontWeight: "bold",
                    }}
                  >
                    TIME SLOT
                  </Text>
                  <Tab
                    id={`${parent_id}_bookmultiple_timeslot_tap`}
                    activetabs={activeTab}
                    fullWidth={true}
                    size="small"
                    backgroundColor="#3f51b5"
                    elevation="0"
                    border={false}
                    variant="withoutBordered"
                    style={{ marginTop: 5 }}
                  >
                    <Tabpanel
                      id={`${parent_id}_bookmultiple_MORNING_tappanel`}
                      key={"0"}
                      label="MORNING"
                      title="MORNING"
                      subTitle=""
                      color={
                        morning?.length
                          ? UIColor.success.color
                          : UIColor.error.color
                      }
                      style={{
                        maxHeight: "26vh",
                        overflow: "auto",
                        marginBottom: "5%",
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                    >
                      {morning?.map((val, i) => (
                        <Tapcomp
                          parent_id={"book_multiple" + i}
                          val={val}
                          stateVal={appointments.startsOn.slotSelect.value}
                          onChange={() => {
                            this.props.handleMultiAppointmentChange(
                              val,
                              "appointments",
                              "startsOn",
                              "slotSelect"
                            );
                            // this.changeAppointment('startsOn','slotSelect',val);
                            this.handlepapoverclose();
                          }}
                          // cardStyle={{ width: 100, height: 40 }}
                        />
                      ))}
                    </Tabpanel>

                    <Tabpanel
                      id={`${parent_id}_bookmultiple_AFTERNOON_tappanel`}
                      key={"1"}
                      label="AFTERNOON"
                      title="AFTERNOON"
                      subTitle=""
                      color={
                        afternoone?.length
                          ? UIColor.success.color
                          : UIColor.error.color
                      }
                      style={{
                        maxHeight: "26vh",
                        overflow: "auto",
                        marginBottom: "5%",
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                    >
                      {afternoone?.map((val, i) => (
                        <Tapcomp
                          parent_id={"book_multiple" + i}
                          val={val}
                          stateVal={appointments.startsOn.slotSelect.value}
                          onChange={() => {
                            this.props.handleMultiAppointmentChange(
                              val,
                              "appointments",
                              "startsOn",
                              "slotSelect"
                            );
                            this.handlepapoverclose();
                          }}
                          // cardStyle={{ width: 80, height: 40 }}
                        />
                      ))}
                    </Tabpanel>

                    <Tabpanel
                      id={`${parent_id}_bookmultiple_EVENING_tappanel`}
                      key={"2"}
                      label="EVENING"
                      title="EVENING"
                      subTitle=""
                      color={
                        evening?.length
                          ? UIColor.success.color
                          : UIColor.error.color
                      }
                      style={{
                        maxHeight: "26vh",
                        overflow: "auto",
                        marginBottom: "5%",
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                    >
                      {evening?.map((val, i) => (
                        <Tapcomp
                          parent_id={"book_multiple" + i}
                          val={val}
                          stateVal={appointments.startsOn.slotSelect.value}
                          onChange={() => {
                            this.props.handleMultiAppointmentChange(
                              val,
                              "appointments",
                              "startsOn",
                              "slotSelect"
                            );
                            this.handlepapoverclose();
                          }}
                          // cardStyle={{ width: 80, height: 40 }}
                        />
                      ))}
                    </Tabpanel>

                    <Tabpanel
                      id={`${parent_id}_bookmultiple_NIGHT_tappanel`}
                      key={"3"}
                      label="NIGHT"
                      subTitle=""
                      title="NIGHT"
                      color={
                        night?.length
                          ? UIColor.success.color
                          : UIColor.error.color
                      }
                      style={{
                        maxHeight: "26vh",
                        overflow: "auto",
                        marginBottom: "5%",
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                    >
                      {night?.map((val, i) => (
                        <Tapcomp
                          parent_id={"book_multiple" + i}
                          val={val}
                          stateVal={appointments.startsOn.slotSelect.value}
                          onChange={() => {
                            this.props.handleMultiAppointmentChange(
                              val,
                              "appointments",
                              "startsOn",
                              "slotSelect"
                            );
                            this.handlepapoverclose();
                          }}
                          // cardStyle={{ width: 80, height: 40 }}
                        />
                      ))}
                    </Tabpanel>
                  </Tab>
                </Div>
              </Col>
            </Row>
          </Popup>

          <Div
            id={`${parent_id}_bookmultiple_ends_div`}
            key={"0"}
            className={"qdm"}
            style={qdmstyles.wqL}
          >
            <Text
              id={`${parent_id}_bookmultiple_ends_text`}
              aria-describedby={id}
              color="primary"
              onClick={handleClick}
              className={classes.typography}
            >
              <span
                id={`${parent_id}_bookmultiple_end_star_span`}
                style={{ color: UIColor.error.color }}
              >
                *
              </span>{" "}
              <span
                id={`${parent_id}_bookmultiple_end_after_span`}
                className={"pc_regular"}
                style={{ minWidth: 75 }}
              >
                {appointments.occurence.occurrenceHandle === "NoOfDays"
                  ? "Ends after "
                  : "Ends By"}
              </span>
              <img
                id={`${parent_id}_bookmultiple_expandarrow_img`}
                alt="img"
                src={expandarrow}
                style={qdmstyles.downIcon}
              />
            </Text>
            <Popover
              id={id}
              open={open}
              anchorEl={this.state.anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              elevation={0}
              className={classes.Popover1}
              style={{ width: "80%" }}
            >
              <Div
                id={`${parent_id}_bookmultiple_calender_div`}
                className={classes.endafterdiv}
              >
                <Div
                  id={`${parent_id}_bookmultiple_appointments_div`}
                  onClick={() => {
                    // val.appointments.occurence.occurrenceHandle = value;
                    //   val.appointments.occurence.occurrenceValue = value === "NoOfDays" ? 0 : moment().format("YYYY-MM-DD");
                    this.props.handleMultiAppointmentChange(
                      {
                        occurrenceHandle: "NoOfDays",
                        occurrenceValue: 0,
                      },
                      "appointments",
                      "occurence"
                    );
                    handleClose();
                  }}
                  className={classes.endafterdiv1}
                >
                  <Text
                    id={`${parent_id}_bookmultiple_Ends_after_text`}
                    className={"pc_regular"}
                  >
                    Ends after
                  </Text>{" "}
                  <div
                    id={`${parent_id}_bookmultiple_occurrence_div`}
                    style={{
                      padding: "0px 6px",
                      backgroundColor: "white",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      id={`${parent_id}_bookmultiple_occurrence_sub_text`}
                      key={"0"}
                      name={"01"}
                      style={{
                        fontSize: "14px",
                        color: UIColor.secondaryText.color,
                        marginRight: "6px",
                      }}
                    ></Text>
                    <div
                      id={`${parent_id}_bookmultiple_occurrence_divider_div`}
                      style={{
                        width: "2px",
                        height: "14px",
                        backgroundColor:
                          UIColor.differentiationBackground.color,
                        marginRight: "10px",
                      }}
                    />
                    <Text
                      id={`${parent_id}_bookmultiple_occurrence_text`}
                      key={"1"}
                      name={"occurrence"}
                      style={{
                        fontSize: "14px",
                        color: UIColor.secondaryText.color,
                        marginRight: "6px",
                      }}
                    ></Text>
                  </div>
                </Div>
                <Divider
                  id={`${parent_id}_bookmultiple_occurrence_divider_div`}
                  key={"1"}
                  style={{
                    backgroundColor: UIColor.lineBorderFill.color,
                    margin: "6px 0px 6px 0px",
                  }}
                ></Divider>
                <Div
                  id={`${parent_id}_bookmultiple_ends_by_div`}
                  onClick={() => {
                    this.props.handleMultiAppointmentChange(
                      {
                        occurrenceHandle: "Calendar",
                        occurrenceValue: moment(
                          this.props.multiApppointmentInfo.appointments.startsOn
                            .milli
                        )
                          .add(1, "day")
                          .format("YYYY-MM-DD"),
                      },
                      "appointments",
                      "occurence"
                    );
                    handleClose();
                  }}
                  className={classes.endafterdiv1}
                >
                  <Text
                    id={`${parent_id}_bookmultiple_ends_by_text`}
                    className={"pc_regular"}
                  >
                    Ends by
                  </Text>{" "}
                  <div
                    id={`${parent_id}_bookmultiple_ends_by_calender_div`}
                    style={{
                      padding: "0px 6px",
                      backgroundColor: "white",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      id={`${parent_id}_bookmultiple_ends_by_calender_text`}
                      key={"0"}
                      name={"DD-MM-YY"}
                      style={{
                        fontSize: "14px",
                        color: UIColor.secondaryText.color,
                        marginRight: "6px",
                      }}
                      className={"pc_regular"}
                    ></Text>
                    <div
                      style={{
                        width: "2px",
                        height: "14px",
                        backgroundColor:
                          UIColor.differentiationBackground.color,
                        marginRight: "10px",
                      }}
                    />
                    <Calendar
                      id={`${parent_id}_bookmultiple_ends_by_calender`}
                    />
                  </div>
                </Div>
              </Div>
            </Popover>
            {appointments.occurence.occurrenceHandle === "NoOfDays" ? (
              <Div
                id={`${parent_id}_bookmultiple_occurence_div`}
                key={"1"}
                className={"qdm"}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginLeft: "10px",
                  backgroundColor: UIColor.differentiationBackground.color,
                  borderRadius: "4px",
                  padding: "10px",
                  height: "35px",
                  cursor: "pointer",
                }}
              >
                <input
                  id={`${parent_id}_bookmultiple_occurence_input`}
                  defaultValue={"00"}
                  value={appointments.occurence.occurrenceValue}
                  style={{
                    width: 20,
                    backgroundColor: UIColor.differentiationBackground.color,
                    borderRadius: 4,
                    border: "none",
                  }}
                  onChange={(e) => {
                    if (e.target.value.replace(/\D/g, "").length <= 2) {
                      this.props.handleMultiAppointmentChange(
                        e.target.value.replace(/\D/g, ""),
                        "appointments",
                        "occurence",
                        "occurrenceValue"
                      );
                    }
                  }}
                  maxlength={2}
                  className={"pc_regular"}
                />
                &nbsp;&nbsp;
                <Text
                  id={`${parent_id}_bookmultiple_occurence_text`}
                  key={"1"}
                  name={"occurrence"}
                  style={{
                    fontSize: "14px",
                    color: UIColor.secondaryText.color,
                    marginRight: "6px",
                  }}
                  className={"pc_regular"}
                ></Text>
              </Div>
            ) : (
              <Div
                id={`${parent_id}_bookmultiple_calender_pop_div`}
                key={"1"}
                className={"qdm"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                  backgroundColor: UIColor.differentiationBackground.color,
                  borderRadius: "4px",
                  padding: "10px",
                  height: "35px",
                  cursor: "pointer",
                  border: "1px solid rgb(224, 224, 224)",
                }}
              >
                <Div
                  id={`${parent_id}_bookmultiple_calender_img_div`}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text
                    id={`${parent_id}_bookmultiple_calender_format_text`}
                    className={"pc_regular"}
                  >
                    {moment(appointments.occurence.occurrenceValue).format(
                      "DD-MM-YYYY"
                    )}
                  </Text>
                  &nbsp;&nbsp;
                  <img
                    id={`${parent_id}_bookmultiple_calender_img`}
                    alt="loding"
                    src="/images/icons8-calendar.svg"
                    onClick={(e) => this.handleCloseendbycal(e)}
                    style={{
                      backgroundColor: "",
                      color: "",
                      cursor: "pointer",
                      borderLeft: `1px solid ${UIColor.lineBorderFill.color}`,
                      paddingLeft: "10px",
                    }}
                  />
                </Div>
                <Popover
                  id={"endbycal"}
                  open={endbycal}
                  anchorEl={this.state.endbycal}
                  onClose={this.handleCloseendbycalClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  elevation={0}
                  // className={classes.Popover1}
                  style={{ width: "80%" }}
                >
                  {/* <Calendars
                    id={`${parent_id}_bookmultiple_calenders`}
                    className={classes.monthCalendar}
                    value={new Date(appointments.occurence.occurrenceValue)}
                    minDate={
                      moment(
                        this.props.multiApppointmentInfo.appointments.startsOn.milli
                      ).add(1, "day").toDate()
                    }
                    onChange={(value, event) => {
                      this.props.handleMultiAppointmentChange(
                        moment(value).format("YYYY-MM-DD"),
                        "appointments",
                        "occurence",
                        "occurrenceValue"
                      );
                      this.setState({ endbycal: null });
                    }}
                  /> */}
                  {appointments.occurence.occurrenceValue}
                  <DatePicker
                    minDate={moment(
                      this.props.multiApppointmentInfo.appointments.startsOn
                        .milli
                    )
                      .add(1, "day")
                      .toDate()}
                    selected={new Date(appointments.occurence.occurrenceValue)}
                    onChange={(value) => {
                      this.props.handleMultiAppointmentChange(
                        moment(value).format("YYYY-MM-DD"),
                        "appointments",
                        "occurence",
                        "occurrenceValue"
                      );
                      this.setState({ endbycal: null });
                    }}
                    inline
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    calendarStartDay={1}
                  />
                </Popover>
              </Div>
            )}
          </Div>
        </Div>

        <Div
          id={`${parent_id}_bookmultiple_every_div`}
          key={"3"}
          name={"qdm-col-12"}
          alignItems={"stretch"}
          direction={"row"}
          justify={"flex-start"}
          textAlign={"left"}
          lg={"6"}
          xs={"12"}
          md={"12"}
          sm={"12"}
          style={qdmstyles.YHB}
        >
          <Text
            id={`${parent_id}_bookmultiple_everystar_text`}
            key={"0"}
            style={qdmstyles.cbde_ebc_f_ac_adeafb}
          >
            <Text
              id={`${parent_id}_bookmultiple_star_text`}
              className={"pc_regular"}
              style={{ color: UIColor.error.color }}
            >
              *
            </Text>
            <Text
              id={`${parent_id}_bookmultiple_every_text`}
              className={"pc_regular"}
              style={{
                color: UIColor.primaryText.color,
                fontWeight: "600",
              }}
            >
              Every
            </Text>
          </Text>

          <input
            id={`${parent_id}_bookmultiple_value_input`}
            value={appointments.reccurance.reccuranceValue}
            onChange={(e) => {
              if (e.target.value.replace(/\D/g, "").length <= 2) {
                this.props.handleMultiAppointmentChange(
                  e.target.value.replace(/\D/g, ""),
                  "appointments",
                  "reccurance",
                  "reccuranceValue"
                );
              }
            }}
            style={{
              width: 30,
              backgroundColor: UIColor.differentiationBackground.color,
              border: "none",
              marginRight: "2%",
              padding: "6px",
              borderRadius: 4,
            }}
            maxlength={2}
            className={"pc_regular"}
          />

          <Div
            id={`${parent_id}_bookmultiple_dropdown_div`}
            style={{ width: "20%", marginRight: 10 }}
          >
            <Dropdown
              id={`${parent_id}_bookmultiple_dropdown`}
              key={"0"}
              elevation={"1"}
              fullWidth={true}
              dropDownTextSize={"14"}
              dropDownTextColor={"#000"}
              activeTextColor={"#000"}
              dropdownListMargin={"0"}
              dropdownListPadding={"8"}
              value={appointments.reccurance.reccuranceType}
              onChange={(data) => {
                this.props.handleMultiAppointmentChange(
                  data,
                  "appointments",
                  "reccurance",
                  "reccuranceType"
                );
              }}
              options={[
                { label: "Days", value: "days" },
                { label: "Week", value: "weeks" },
                { label: "Month", value: "months" },
              ]}
              // style={qdmstyles.aJ}
              inLineStyles={{
                fontSize: "14px",
                fontWeight: 500,
                backgroundColor: UIColor.differentiationBackground.color,
              }}
              className={"pc_regular"}
            ></Dropdown>
          </Div>

          <Tooltip
            id={`${parent_id}_bookmultiple_title_tooltip`}
            title="Prefeerred you can select specific days/weeks for your appointments"
            arrow
          >
            <div id={`${parent_id}_bookmultiple_calenderedit_div`}>
              <Div
                id={`${parent_id}_bookmultiple_calenderedit_sub_div`}
                key={"0"}
                className={"qdm"}
                style={{
                  backgroundColor: UIColor.differentiationBackground.color,
                  borderRadius: "4px",
                  padding: "10px",
                  height: "35px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <CalenderEdit id={`${parent_id}_bookmultiple_calenderedit`} />
              </Div>
            </div>
          </Tooltip>
        </Div>

        {appointments.reccurance.reccuranceType.value === "weeks" ||
        appointments.reccurance.reccuranceType.value === "months" ? (
          <>
            <Div
              id={`${parent_id}_bookmultiple_description_div`}
              key={"4"}
              className={"qdm"}
              style={{ backgroundColor: UIColor.greyBackground.color }}
            >
              <Div
                id={`${parent_id}_bookmultiple_description_sub_div`}
                key={"0"}
                className={"qdm"}
                style={qdmstyles.aaf_c__aba_bdc}
              >
                <Div id={`${parent_id}_bookmultiple_PREFERENCE_div`} key={"0"}>
                  <Text
                    id={`${parent_id}_bookmultiple_PREFERENCE_text`}
                    key={"0"}
                    name={"PREFERENCE"}
                    style={qdmstyles.PTki}
                    className={"pc_regular"}
                  ></Text>
                </Div>

                <Div
                  id={`${parent_id}_bookmultiple_CLEAR_PREFERENCE_div`}
                  key={"1"}
                  style={{ cursor: "pointer" }}
                  // onClick={this.clearPreference}
                  onClick={() => {
                    this.props.handleMultiAppointmentChange(
                      null,
                      "appointments",
                      "preferrence",
                      "preferredDays",
                      "deleteAllDays"
                    );
                  }}
                >
                  <Text
                    id={`${parent_id}_bookmultiple_CLEAR_PREFERENCE_text`}
                    key={"0"}
                    name={"CLEAR PREFERENCE"}
                    style={{
                      fontSize: "14px",
                      color: UIColor.error.color,
                      fontWeight: "600",
                      letterSpacing: "1px",
                    }}
                    className={"pc_regular"}
                  ></Text>
                </Div>
              </Div>
            </Div>
            <Div
              id={`${parent_id}_bookmultiple_tapselect_div`}
              key={"4"}
              className={"qdm"}
              style={{
                backgroundColor: UIColor.greyBackground.color,
                padding: "20px",
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
            >
              <Text
                id={`${parent_id}_bookmultiple_PREFERRED_DAYS_div`}
                key={"1"}
                name={"CHOOSE YOUR PREFERRED DAYS"}
                style={{
                  fontSize: "12px",
                  color: UIColor.primaryText.color,
                  fontWeight: "600",
                }}
                className={"pc_regular"}
              ></Text>
              <Div
                id={`${parent_id}_bookmultiple_DAYS_div`}
                style={{ paddingBottom: 10 }}
              >
                <TapSelect
                  id={`${parent_id}_bookmultiple_DAYS_tapselect`}
                  key={"2"}
                  inLineStyles={{ minWidth: 30, padding: "5px 15px" }}
                  type={"multiple"}
                  fullWidth={false}
                  selectedBorderColor={UIColor.secondaryButtonColor.color}
                  selectedBackgroundColor={UIColor.secondaryButtonColor.color}
                  textColor={UIColor.secondaryText.color}
                  borderColor={UIColor.differentiationBackground.color}
                  backgroundColor={UIColor.differentiationBackground.color}
                  selectedTextColor="#fff"
                  label=""
                  textSize={"small"}
                  iconHeight={"20"}
                  iconWidth={"20"}
                  options={[
                    { value: 0, label: "Sun" },
                    { value: 1, label: "MON" },
                    { value: 2, label: "TUE" },
                    { value: 3, label: "WED" },
                    { value: 4, label: "Thu" },
                    { value: 5, label: "Fri" },
                    { value: 6, label: "Sat" },
                  ]}
                  defaultSelected={appointments.preferrence.preferredDays}
                  onChange={(data) => {
                    console.log("dataNJK", data);
                    this.props.handleMultiAppointmentChange(
                      data,
                      "appointments",
                      "preferrence",
                      "preferredDays"
                    );
                  }}
                  iconPosition={"left"}
                  // inLineStyles={styles.tapSelectStyle}
                  className={classes.tapselectcss}
                ></TapSelect>
              </Div>
            </Div>
          </>
        ) : appointments.reccurance.reccuranceType.value ===
          "days" ? null : null}

        {appointments.reccurance.reccuranceType.value === "months" ? (
          <Div
            id={`${parent_id}_bookmultiple_months_div`}
            key={"4"}
            className={"qdm"}
            style={{
              backgroundColor: UIColor.greyBackground.color,
              padding: "20px",
              paddingTop: "0px",
            }}
          >
            <Text
              id={`${parent_id}_bookmultiple_PREFERRED_WEEKS_text`}
              key={"3"}
              name={"CHOOSE YOUR PREFERRED WEEKS"}
              style={{
                color: UIColor.primaryText.color,
                fontWeight: "600",
                paddingTop: "30px",
                fontSize: "12px",
              }}
              className={"pc_regular"}
            ></Text>
            <Div
              id={`${parent_id}_bookmultiple_WEEKS_div`}
              style={{ marginBottom: 10 }}
            >
              <TapSelect
                id={`${parent_id}_bookmultiple_WEEKS_tapselect`}
                key={"4"}
                inLineStyles={{ minWidth: 30, padding: "5px 15px" }}
                type={"multiple"}
                fullWidth={false}
                // selectedBorderColor={"#0071F2"}
                label=""
                selectedBorderColor={UIColor.secondaryButtonColor.color}
                selectedBackgroundColor={UIColor.secondaryButtonColor.color}
                textColor={UIColor.secondaryButtonColor.color}
                selectedTextColor="#fff"
                textSize={"small"}
                iconHeight={"20"}
                iconWidth={"20"}
                options={[
                  { value: 1, label: "WEEK 1" },
                  { value: 2, label: "WEEK 2" },
                  { value: 3, label: "WEEK 3" },
                  { value: 4, label: "WEEK 4" },
                  { value: 5, label: "WEEK 5" },
                ]}
                defaultSelected={appointments.preferrence.preferredWeeks}
                onChange={(data) => {
                  this.props.handleMultiAppointmentChange(
                    data,
                    "appointments",
                    "preferrence",
                    "preferredWeeks"
                  );
                }}
                iconPosition={"left"}
                className={classes.tapselectcss}
              ></TapSelect>
            </Div>
          </Div>
        ) : null}
        {/* </Div> */}

        <H6
          id={`${parent_id}_bookmultiple_format_h6`}
          key={"5"}
          name={`Every ${appointments.reccurance.reccuranceValue} ${
            appointments.reccurance.reccuranceType.value
          }, starting on 
              ${`${moment(appointments.startsOn.date, "DD-MM-YYYY").format(
                "Do MMM 'YY"
              )} and `}
              ${
                appointments.occurence.occurrenceHandle === "NoOfDays"
                  ? "ends after "
                  : "ends by "
              }
              ${
                appointments.occurence.occurrenceHandle === "NoOfDays"
                  ? appointments.occurence.occurrenceValue
                  : moment(
                      appointments.occurence.occurrenceValue,
                      "YYYY-MM-DD"
                    ).format("Do MMM 'YY")
              }
              ${
                appointments.occurence.occurrenceHandle === "NoOfDays"
                  ? " occurrences"
                  : ""
              }.`}
          style={qdmstyles.b_f_ce_abd_ce}
          className={"pc_regular"}
        ></H6>

        <Button
          id={`${parent_id}_bookmultiple_Preview_Schedules_button`}
          key={"6"}
          type={"button"}
          variant={"outlined"}
          name={"Preview Schedules"}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: "20px",
            marginBottom: "10px",
            backgroundColor: "#fff",
            color: UIColor.secondaryButtonColor.color,
            borderColor: UIColor.secondaryButtonColor.color,
            borderRadius: "10px",
            fontSize: "14px",
            padding: "14px",
            fontWeight: "600",
            height: "40px",
          }}
          onClick={async () => {
            debugger;
            const [err, msg] = this.checkValidation(appointments);
            if (err) {
              this.context.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
                tone: true,
              });
              return;
            }
            const [dates] = getReccuranceDates(
              appointments.startsOn.date,
              appointments.occurence.occurrenceHandle,
              appointments.occurence.occurrenceHandle === "NoOfDays"
                ? appointments.occurence.occurrenceValue
                : moment(
                    appointments.occurence.occurrenceValue,
                    "YYYY-MM-DD"
                  ).format("DD-MM-YYYY"),
              appointments.reccurance.reccuranceType.value,
              appointments.reccurance.reccuranceValue,
              appointments.preferrence.preferredDays,
              appointments.preferrence.preferredWeeks
            );
            const uniqueDates = [...new Set(dates)].sort((a, b) => a - b);
            const practData = this.state?.hospitalInfo || {};
            const info = {
              dates: uniqueDates,
              appointments,
              practData,
            };
            this.props.handleMultiAppointmentChange(info, "info");
            this.props.previewSchedule(info);
          }}
          className={"pc_regular"}
        ></Button>
      </Container>
    );
  }
}

BookMultipleAppointmentsDrawer.contextType = AlertContext;

const mapStateToProps = (state) => ({});

export default withStyles(styles)(
  connect(mapStateToProps, actions)(withRouter(BookMultipleAppointmentsDrawer))
);
